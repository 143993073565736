import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { capitalizeText } from "../../../../../../utils/Format"
import { Label } from "../../../../../../components/Form/formComponents/Label"

export function BillDueInput(props: DefaultBillField) {
  const { bill, setBill } = props
  const { t } = useTranslation()

  return (
    <Form.Group className="mb-3" controlId={"bill-due-input"}>
      <Label required>
        {`${capitalizeText(
          t("Global.attributes.due"),
        )} (${capitalizeText(t("Global.attributes.deadline"))})`}
      </Label>
      <Form.Control
        type="date"
        value={bill?.dueIn}
        onChange={(e) =>
          setBill((bill) => ({ ...bill, dueIn: e.target.value }))
        }
        required
      />
      <Form.Control.Feedback type="invalid">
        {t("Global.alerts.requiredFieldInput")}
      </Form.Control.Feedback>
    </Form.Group>
  )
}
