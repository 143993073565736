import { formatDocument } from "../Format/formatDocument"
import { getCountryCode } from "../hooks/useCountryConfigs/utils"

export function documentValidation(document?: string) {
  const contryCode = getCountryCode()
  if (!document) return false
  document = formatDocument(document, true)
  switch (contryCode) {
    case "BR":
      return cnpjValidation(document)
    case "CO":
      return coDocumentValidation(document)
    case "MX":
      return rfcValidation(document)
    default:
      return true
  }
}

function cnpjValidation(document: string) {
  if (document.length !== 14) return false

  const invalidCnpjNumbers = [
    "00000000000000",
    "11111111111111",
    "22222222222222",
    "33333333333333",
    "44444444444444",
    "55555555555555",
    "66666666666666",
    "77777777777777",
    "88888888888888",
    "99999999999999",
  ]

  if (invalidCnpjNumbers.includes(document)) return false

  let size: number = document.length - 2
  let numbers: string = document.substring(0, size)
  const digit: string = document.substring(size)
  let sum = 0
  let position = size - 7
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * position--
    if (position < 2) position = 9
  }
  let result: number = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result !== parseInt(digit.charAt(0))) {
    return false
  }

  size = size + 1
  numbers = document.substring(0, size)
  sum = 0
  position = size - 7
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * position--
    if (position < 2) position = 9
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  return result === parseInt(digit.charAt(1))
}

function coDocumentValidation(document: string) {
  const length = document.length
  return length === 9 || length === 10 || length === 11
}

function rfcValidation(document: string) {
  const rfcRegex = /[A-Za-z0-9_]{3,4}\d{6}[A-Za-z0-9_]{3}$/
  return rfcRegex.test(document)
}
