import { useTranslation } from "react-i18next"
import { updatePayment } from "../../../../../services/payments.service"
import UnbindModal from "../../../../../components/Modal/UnbindModal/UnbindModal"
export function BillUnlinkPaymentButton(props: BillUnlinkPaymentButtonProps) {
  const { payment, setSuccess, billId } = props
  const { t } = useTranslation()

  return (
    <div className="d-flex gap-2 justify-content-end">
      <UnbindModal
        title={t("Receipts.actions.unbindPaymentFromBill")}
        unbindMessageKey="Receipts.alerts.warning.unbindPaymentFromBill"
        unbindId={payment.id}
        originId={billId}
        unbindFunction={async () => {
          await updatePayment({
            payment: {
              id: Number(payment.id),
              billId: "",
            },
          })
          setSuccess(true)
        }}
      />
    </div>
  )
}
