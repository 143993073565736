export function LinkToRepurposal({
  repurposalCode,
  className,
}: (Invoice | Payment | { repurposalCode?: string }) & { className?: string }) {
  return (
    <>
      {repurposalCode ? (
        <a
          href={`${
            import.meta.env.VITE_AUTH_REDIRECT
          }/recolocacoes/visualizar/${repurposalCode}`}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
        >
          {repurposalCode}
        </a>
      ) : (
        "-"
      )}
    </>
  )
}
