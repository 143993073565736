import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../../../components/Form/formComponents/Label"
import { DropdownInput } from "../../../../../../../components/DropdownInput"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useCountryConfigs } from "../../../../../../../utils/hooks/useCountryConfigs"
import { useDebounceValue } from "usehooks-ts"
import { CodeAndCustomersOptions } from "../../../../../../../components/Form/formComponents/CodeAndCustomersOptions/CodeAndCustomersOptions"
import { capitalizeText } from "../../../../../../../utils/Format"
import { deliveriesList } from "../../../../../../../services/deliveries.service"
import { setDeliveryOrigin } from "../../../../../../Logistics/Deliveries/utils/setDeliveryOrigin"
import { Info } from "../../../../../../../components/Info"

interface SimpleDeliveryQuery {
  count: number
  pageNumber: number
  deliveries: Array<SimpleDelivery>
}

function getNextPageParam({ pageNumber, count }: SimpleDeliveryQuery) {
  const ITEMS_PER_PAGE = 20
  return pageNumber < Math.ceil(count / ITEMS_PER_PAGE)
    ? pageNumber + 1
    : undefined
}

export default function InvoiceDeliveryInput(props: DefaultInvoiceFormsProps) {
  const { t } = useTranslation()
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()
  const { invoice, setInvoice } = props
  const [search, setSearch] = useDebounceValue("", 500)

  async function fetchDeliveries({
    pageParam,
  }: {
    pageParam: number
  }): Promise<SimpleDeliveryQuery> {
    if (!invoice?.repurposalCode) throw new Error("Repurposal code is required")
    const { data } = await deliveriesList({
      pageNumber: pageParam,
      context: "simple_index",
      filters: {
        deliveryNumber: search,
        repurposalCode: invoice?.repurposalCode,
      },
    })
    return {
      deliveries: data.deliveries,
      count: data.count,
      pageNumber: pageParam,
    }
  }

  const { data, isFetching, fetchNextPage } = useInfiniteQuery({
    queryKey: [
      "deliveries",
      "simple_index",
      countryCode,
      search,
      invoice?.repurposalCode,
    ],
    queryFn: fetchDeliveries,
    getNextPageParam,
    initialPageParam: 1,
    enabled: !!invoice?.repurposalCode,
  })

  return (
    <Form.Group className="mb-3" controlId="invoice-delivery-id">
      <Label className="d-flex gap-1 align-items-center">
        {t("Deliveries.attributes.deliveryNumber")}
        <Info>{t("Invoice.alerts.warning.deliveriesSuggestion")}</Info>
      </Label>
      <DropdownInput<Partial<SimpleDelivery>>
        value={invoice?.deliveryId ? { id: invoice.deliveryId } : undefined}
        onClick={(delivery) =>
          setInvoice({ ...invoice, deliveryId: delivery.id })
        }
        onChange={(event) => setSearch(event.target.value)}
        options={
          (data?.pages && data.pages.length > 1
            ? data?.pages.flatMap((page) => page.deliveries)
            : data?.pages[0].deliveries) ?? []
        }
        renderName={(delivery) => String(delivery.id)}
        renderOption={(delivery) => (
          <CodeAndCustomersOptions
            code={delivery.id}
            firstCustomer={setDeliveryOrigin(delivery as SimpleDelivery)}
            secondCustomer={
              delivery.repurposalCore
                ? {
                    ...delivery.repurposalCore?.buyer,
                    document: delivery.repurposalCore?.buyerDocument,
                  }
                : undefined
            }
            firstCustomerLabel={capitalizeText(
              t("Logistics.attributes.origin"),
            )}
            secondCustomerLabel={capitalizeText(
              t("Logistics.attributes.destination"),
            )}
          />
        )}
        isLoading={isFetching}
        onScroll={() => fetchNextPage()}
        disabled={!invoice?.repurposalCode}
        select
      />
    </Form.Group>
  )
}
