import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { errorExceptionsApi } from "../services/api"
import { useToast } from "../utils/hooks/useToast"
import { useSearchParams } from "react-router-dom"
import { AxiosError } from "axios"

interface ExternalBuyerApprovalContextTypes {
  handleVerificationCode: (code: string) => Promise<unknown>
  messageFeedback: string
  headerRepurposalsBuyerApproval: HeaderRepurposalsBuyerApprovalProps | null
  repurposalItemsBuyerApproval: BuyerApprovalRepurposalResponse | null
  setHashedId: Dispatch<SetStateAction<string>>
  setAccessCode: Dispatch<SetStateAction<string>>
  handleApprove: (
    termOfUse: boolean,
    BillingAndDeliveryDetailsAreCorrect: boolean,
  ) => Promise<void>
  buyerApprovedAt: string
}

interface BuyerProviderTypes {
  children: ReactNode
}

export const ExternalBuyerApprovalContext = createContext(
  {} as ExternalBuyerApprovalContextTypes,
)

interface HeaderRepurposalsBuyerApprovalProps {
  buyerDocument: string
  code: string
  repurposalLogistic: {
    destinationAddress: {
      city: {
        name: string
        state: {
          abbreviation: string
        }
      }
      postalCode: string
    }
  }
  totalPrice: string
}

export interface BuyerApprovalRepurposalItem {
  conversionFactor: number
  quantity: string
  stockItem: {
    expirationAt: string
    item: {
      description: string
      ean: number
      imageUrl: string
      repurposalUnit: {
        abbreviation: string
      }
    }
  }
  totalPrice: string
}

interface BuyerApprovalRepurposalResponse {
  count: number
  itemsPerPage: number
  pageNumber: number
  repurposalItems: BuyerApprovalRepurposalItem[]
}

export default function BuyerApprovalProvider({
  children,
}: BuyerProviderTypes) {
  const [searchParams] = useSearchParams()
  const [pageNumber, setPageNumber] = useState<number>(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1,
  )
  const [messageFeedback, setMessageFeedback] = useState("")
  const [hashedId, setHashedId] = useState("")
  const [accessCode, setAccessCode] = useState("")
  const [isLock, setIsLock] = useState(true)
  const [buyerApprovedAt, setBuyerApprovedAt] = useState("")
  const toast = useToast()
  const [headerRepurposalsBuyerApproval, setHeaderRepurposalsBuyerApproval] =
    useState<HeaderRepurposalsBuyerApprovalProps | null>(null)
  const [repurposalItemsBuyerApproval, setRepurposalItemsBuyerApproval] =
    useState<BuyerApprovalRepurposalResponse | null>(null)

  useEffect(() => {
    const page = searchParams.get("page")
    setPageNumber(page ? Number(page) : 1)
  }, [searchParams])

  useEffect(() => {
    if (!isLock && hashedId && accessCode && pageNumber) {
      handleItemsBuyerApproval(pageNumber)
    }
  }, [pageNumber, hashedId, accessCode, isLock])

  async function handleItemsBuyerApproval(
    page: number,
  ): Promise<BuyerApprovalRepurposalResponse | null> {
    try {
      const { data } = await errorExceptionsApi.get(
        `external/repurposals/repurposal_items/buyer_approval?pageNumber=${page}&itemsPerPage=20`,
        {
          headers: {
            accessCode,
            featureType: "repurposal_buyer_approval",
            hashedId,
          },
        },
      )
      setRepurposalItemsBuyerApproval(data)
      return data
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data?.error) {
        setMessageFeedback(error.response.data.error)
        throw new Error(error.response.data.error)
      } else {
        setMessageFeedback("An unexpected error occurred.")
        throw new Error("An unexpected error occurred.")
      }
    }
  }

  async function handleVerificationCode(code: string): Promise<boolean> {
    try {
      const { data } = await errorExceptionsApi.get(
        "external/repurposals/buyer_approval",
        {
          headers: {
            accessCode: code,
            featureType: "repurposal_buyer_approval",
            hashedId,
          },
        },
      )
      if (data) {
        setHeaderRepurposalsBuyerApproval(data)
        setIsLock(false)

        return true
      }
      return false
    } catch (error) {
      if (error instanceof AxiosError) {
        const response = error.response?.data

        if (response?.error) {
          setMessageFeedback(response.error)
          throw new Error(response.error)
        } else if (response?.messages) {
          setMessageFeedback(response.messages[0])
          throw new Error(response.messages[0])
        } else {
          setMessageFeedback("An unexpected error occurred.")
          throw new Error("An unexpected error occurred.")
        }
      } else {
        setMessageFeedback("An unexpected error occurred.")
        throw new Error("An unexpected error occurred.")
      }
    }
  }
  async function handleApprove(
    termOfUse: boolean,
    BillingAndDeliveryDetailsAreCorrect: boolean,
  ): Promise<void> {
    if (!termOfUse) return toast.danger("Please select a term of use")
    if (!BillingAndDeliveryDetailsAreCorrect)
      return toast.danger("Please select a confirmation that you ")
    try {
      const payload = {
        buyer_approved_at: new Date().toISOString(),
      }

      const { data } = await errorExceptionsApi.patch(
        "external/repurposals/buyer_approval",
        payload,
        {
          headers: {
            accessCode,
            featureType: "repurposal_buyer_approval",
            hashedId,
          },
        },
      )
      setBuyerApprovedAt(data.buyerApprovedAt)
    } catch (error) {
      console.error("Error approving:", error)
    }
  }

  return (
    <ExternalBuyerApprovalContext.Provider
      value={{
        handleVerificationCode,
        setHashedId,
        setAccessCode,
        messageFeedback,
        headerRepurposalsBuyerApproval,
        handleApprove,
        repurposalItemsBuyerApproval,
        buyerApprovedAt,
      }}
    >
      {children}
    </ExternalBuyerApprovalContext.Provider>
  )
}
