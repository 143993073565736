import { Accordion, AccordionButton, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../Label"

export function AdditionalInfoAccordion(
  props: React.PropsWithChildren<AdditionalInfoAccordion>,
) {
  const { additionalInfo, setAdditionalInfo, children, entityName } = props
  const { t } = useTranslation()

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <h2 className="accordion-header">
          <AccordionButton className=" mb-0 alert-danger gap-0">
            {t("Global.attributes.complementaryAttributes")}
          </AccordionButton>
        </h2>
        <Accordion.Body>
          {children}
          <Form.Group className="mb-3" controlId="AdditionalInformat">
            <Label>
              {t("Global.attributes.additionalInformation")}
              <span className="text-muted">
                {" "}
                {t("Global.attributes.optional")}
              </span>
            </Label>
            <Form.Control
              as="textarea"
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              placeholder={String(
                t("Global.actions.addAdditionalInfo", {
                  entity: entityName,
                }),
              )}
            />
          </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
