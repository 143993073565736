import { FormatMoney } from "../../../../../components/Formats/FormatMoney"

const FORMAT_AMOUNT_MAP: Record<BillStatus, string> = {
  canceled: "text-secondary text-decoration-line-through",
  confirmed: "",
  waiting_for_payment: "",
}

export function BillAmountCell(bill: Bill) {
  return (
    <span className={FORMAT_AMOUNT_MAP[bill.status]}>
      <FormatMoney amount={Number(bill.amount)} isNotJSX />
    </span>
  )
}
