import { formatDocument } from "../../../utils/Format/formatDocument"

export function CustomerSummary(props: CustomerSummaryProps) {
  const { id, corporateName, document } = props

  return (
    <div className="d-flex flex-column">
      {id && corporateName ? (
        <a
          target="_blank"
          href={`${import.meta.env.VITE_AUTH_REDIRECT}/customer/view/${id}`}
          rel="noreferrer"
        >
          {corporateName}
        </a>
      ) : (
        <span>-</span>
      )}
      <span>{formatDocument(document)}</span>
    </div>
  )
}
