import { useCountryConfigs } from "../../../utils/hooks/useCountryConfigs"

export const FormatNumber = (props: {
  quantity?: string | number
  options?: Intl.NumberFormatOptions
}): string => {
  const { quantity, options } = props
  const { locale } = useCountryConfigs()
  const parsedNumber = Number(quantity)
  if (isNaN(parsedNumber)) {
    return "-"
  }

  return parsedNumber.toLocaleString(locale, options)
}
