import React from "react"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { ActionButtonProps } from "./ActionButton"

export function ActionButton(
  props: React.PropsWithChildren<ActionButtonProps>,
) {
  const {
    onClick,
    title,
    disabled,
    children,
    placement = "top",
    variant = "outline-primary",
    className = "button-table d-flex py-2",
    size = "sm",
    type = "button",
    ...rest
  } = props

  return title ? (
    <OverlayTrigger placement={placement} overlay={title ? <Tooltip>{title}</Tooltip> : <></>}>
      <Button
        variant={variant}
        className={
          className + (disabled ? " opacity-50 not-hover text-primary " : "")
        }
        size={size}
        onClick={(event) => {
          !disabled ? onClick && onClick(event) : {}
        }}
        data-testid={props["data-testid"]}
        {...rest}
      >
        {children}
      </Button>
    </OverlayTrigger>
  ) : (
    <Button
      disabled={disabled}
      variant={variant}
      className={className}
      size="sm"
      onClick={onClick}
      data-testid={props["data-testid"]}
      type={type}
      {...rest}
    >
      {children}
    </Button>
  )
}
