import { PropsWithChildren } from "react"
import { Accordion } from "react-bootstrap"

export function CustomTableBody(
  props: PropsWithChildren<CustomTableBodyProps>,
) {
  const { accordion, children } = props
  return (
    <>
      {accordion ? (
        <Accordion
          alwaysOpen
          as={"tbody"}
          className="acordion-tbody align-middle"
        >
          {children}
        </Accordion>
      ) : (
        <tbody className="align-middle">{children}</tbody>
      )}
    </>
  )
}
