import { GenericAbortSignal } from "axios"
import { api, errorExceptionsApi } from "./api"

export async function billsList(props: {
  pageNumber: number
  signal?: GenericAbortSignal
  filters?: BillsFilters
  orderBy?: string
  context?: "simple_index"
}) {
  const { signal, filters, ...otherProps } = props
  const { dueIn, ...otherFilters } = filters ?? {}

  return api.get("billing/bills", {
    params: {
      ...otherProps,
      ...otherFilters,
      itemsPerPage: 20,
      dueInStartDate: dueIn?.initial,
      dueInFinalDate: dueIn?.final,
    },
    signal,
  })
}

export async function getBill(id?: number, context?: "external_customer_info") {
  return api.get(`billing/bills/${id}`, {
    params: { context },
  })
}

export function createBill(props: EditableBill) {
  const {
    id,
    paymentType,
    repurposalCore,
    fixedPaymentTypeData,
    tags,
    ...otherFields
  } = props

  return api(`/billing/bills/${id ?? ""}`, {
    method: id ? "PUT" : "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      repurposalCoreId: repurposalCore?.id,
      paymentTypeId: paymentType?.id,
      paymentTypeDataId: fixedPaymentTypeData?.id,
      ...otherFields,
      tagIds: tags?.map((tag) => tag.id),
    },
  })
}

export async function deleteBill(id?: number) {
  return await api.delete(`/billing/bills/${id}`)
}

export async function getBillPart(id?: number) {
  return await api.get(`/billing/bill/${id}`)
}

export async function sendBillMail(props: {
  billId?: number
  sendMailBuyer?: boolean
  sendAdditionalMailAt?: string
}) {
  const { billId, ...data } = props
  return await api.post(`/billing/bills/${billId}/send_mail`, data)
}

export async function accessExternalBill(
  hashedId?: string,
  accessCode?: string,
) {
  return errorExceptionsApi.get("/external/billing/bills", {
    headers: {
      hashedId,
      accessCode,
      featureType: "bill",
    },
  })
}

export async function getExternalBillFile(
  hashedId?: string,
  accessCode?: string,
) {
  return errorExceptionsApi.get("/external/billing/bills/file", {
    headers: {
      hashedId,
      accessCode,
      featureType: "bill",
    },
    responseType: "blob",
  })
}

export async function exportBills(filters: BillsFilters, columns: string[]) {
  const { dueIn, ...otherProps } = filters

  return await api({
    url: "billing/bills/export_xlsx",
    method: "POST",
    data: {
      columns,
      filters: {
        ...otherProps,
        dueInStartDate: dueIn?.initial,
        dueInFinalDate: dueIn?.final,
      },
    },
    responseType: "blob",
  })
}

export function billInvoices(id: number) {
  return api.get(`/billing/bills/${id}/invoices`)
}

export function bindInvoiceInBill(invoiceId: number, billId: number) {
  return api.post(`/billing/bills/${billId}/bound/invoices/${invoiceId}`)
}

export function unbindInvoiceInBill(invoiceId: number, _billId: number) {
  return api.delete(`/billing/invoices/${invoiceId}/bind/bill`)
}
