import { Button, Dropdown } from "react-bootstrap"
import { t } from "i18next"
import { BsCheckCircleFill, BsFileEarmarkArrowDownFill } from "react-icons/bs"
import { StockCheckinFormModal } from "../StockCheckinFormModal/StockCheckinsFormModal"
import { BiDotsVerticalRounded } from "react-icons/bi"
import styled from "styled-components"
import { CustomLink } from "../../../../components/Customs/CustomLink"

interface CheckinTableActionButtonsProps {
  item: StockCheckin
  handleReloadTable: () => void
  handleFeedback: (
    success: boolean,
    message: string | JSX.Element,
    type: string,
  ) => void
  handleSolutions: (solutions: gooxxySolutions[]) => Record<string, string[]>
  loading: boolean
  success: boolean
  originCheckin?: StockCheckin
}

const CustomDropdownToggle = styled(Dropdown.Toggle)`
  &::after {
    content: none !important;
    appearance: none;
  }
`

export default function CheckinTableActionButtons(
  props: CheckinTableActionButtonsProps,
) {
  const {
    item,
    handleReloadTable,
    handleFeedback,
    handleSolutions,
    loading,
    originCheckin,
  } = props

  const isSubcheckin = item.originCheckinId !== null

  const disableValidateButton = (): boolean =>
    (!isSubcheckin && item?.subCheckins?.length > 0) || item?.supplier === null

  const buildStockNewUrl = (): string => {
    const baseUrl = `/stocks/new?checkinId=${item.id}&stockKind=checkin&salesChannel=crossdocking`
    return item.supplier
      ? `${baseUrl}&supplierId=${item.supplier.id}&SupplierName=${item.supplier.name}`
      : baseUrl
  }

  const stockNewUrl = buildStockNewUrl()

  return (
    <div className="d-flex gap-2 align-items-center justify-content-center">
      <Button
        disabled={!item.fileUrl}
        title={t("Global.actions.download") as string}
        onClick={() => window.open(item.fileUrl, "_blank")}
        data-testid={`download-checkin-file-${item.totalValue}`}
        className="d-flex align-items-center justify-content-center p-2"
        variant="outline-primary"
      >
        <BsFileEarmarkArrowDownFill size={15} />
      </Button>

      <Dropdown className="d-flex">
        <CustomDropdownToggle
          variant="outline-primary"
          data-testid={`checkin-row-dropdown-${item.totalValue}`}
          className="d-flex align-items-center justify-content-center p-2"
        >
          <BiDotsVerticalRounded />
        </CustomDropdownToggle>
        <Dropdown.Menu>
          {!isSubcheckin && (
            <Dropdown.Item>
              <StockCheckinFormModal
                handleReloadTable={handleReloadTable}
                handleFeedback={handleFeedback}
                originCheckinId={item}
                addSubcheckin
                testid={item.totalValue}
                loading={loading}
              />
            </Dropdown.Item>
          )}
          <Dropdown.Item>
            <StockCheckinFormModal
              isEdit
              sentMailAt={item.sentMailAt}
              handleFeedback={handleFeedback}
              handleReloadTable={handleReloadTable}
              checkinValue={item.totalValue}
              checkinId={item.id}
              supplier={item.supplier}
              netWeight={String(item.netWeight)}
              grossWeight={String(item.grossWeight)}
              productType={item.kind}
              gooxxySolutions={handleSolutions(item.gooxxySolutions)}
              originCheckinId={originCheckin}
              additionalInformation={item.additionalInformation}
              testid={item.totalValue}
              loading={loading}
            />
          </Dropdown.Item>
          <CustomLink
            disabled={disableValidateButton()}
            href={stockNewUrl}
            className="dropdown-item"
          >
            <div className="d-flex align-items-center justify-content-start gap-2">
              <BsCheckCircleFill size={15} />
              <span>
                {isSubcheckin
                  ? t("StockCheckin.actions.validateSubCheckin")
                  : t("StockCheckin.actions.validateCheckin")}
              </span>
            </div>
          </CustomLink>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
