// TODO: add tests to this file

import * as Sentry from "@sentry/browser"
import {
  addMilliseconds,
  format,
  intlFormat,
  IntlFormatFormatOptions,
  IntlFormatLocaleOptions,
} from "date-fns"

import { getTimezoneOffset } from "date-fns-tz"
import { getTimezoneName } from "../hooks/useCountryConfigs/utils"

const EMPTY_DATE_VALUE = "-"

type AcceptedDatesType = Date | string | null | undefined

const getLocaleOptions = (targetLocale?: string | Intl.Locale) => {
  const isTargetLocaleValid =
    Intl.ListFormat.supportedLocalesOf(targetLocale).length > 0

  const localeOptions: IntlFormatLocaleOptions = {
    locale:
      targetLocale && isTargetLocaleValid
        ? targetLocale?.toString()
        : navigator.language,
  }

  return localeOptions
}

interface CustomFormatterParameters {
  datetime: AcceptedDatesType
  formatOptions: IntlFormatFormatOptions
  targetLocale?: string | Intl.Locale
  showInSelectedCountryTimeZone?: boolean
}

const customFormatter: (params: CustomFormatterParameters) => string = (
  params,
) => {
  const {
    datetime,
    showInSelectedCountryTimeZone: showInSelectedTimeZone,
    targetLocale,
  } = params
  let { formatOptions } = params

  try {
    if (!datetime) return EMPTY_DATE_VALUE

    if (showInSelectedTimeZone) {
      formatOptions = { ...formatOptions, timeZone: getTimezoneName() }
    }

    let formattedDate = intlFormat(
      datetime,
      formatOptions,
      getLocaleOptions(targetLocale),
    )

    formattedDate = formattedDate.replace(",", " -")

    return formattedDate
  } catch (e) {
    console.error(e)

    Sentry.captureException(e)

    return EMPTY_DATE_VALUE
  }
}

export const intlFormatDateTime = (
  datetime: AcceptedDatesType,
  targetLocale?: string | Intl.Locale,
) => {
  if (!datetime) return EMPTY_DATE_VALUE

  const formatOptions: IntlFormatFormatOptions = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }

  return customFormatter({
    datetime,
    formatOptions,
    targetLocale,
    showInSelectedCountryTimeZone: true,
  })
}

export const intlFormatDate = (
  datetime: AcceptedDatesType,
  targetLocale?: string | Intl.Locale,
  formatOptions?: IntlFormatFormatOptions,
) => {
  if (!datetime) return EMPTY_DATE_VALUE

  const selectedFormatOptions: IntlFormatFormatOptions = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    ...formatOptions,
  }

  if (typeof datetime === "string") {
    datetime = toSelectedTimezone(new Date(datetime), true)
  }

  return customFormatter({
    datetime,
    formatOptions: selectedFormatOptions,
    targetLocale,
    showInSelectedCountryTimeZone: false,
  })
}

export const intlFormatMonthYear = (
  datetime: AcceptedDatesType,
  targetLocale?: string | Intl.Locale,
) => {
  if (!datetime) return EMPTY_DATE_VALUE

  const formatOptions: IntlFormatFormatOptions = {
    month: "2-digit",
    year: "2-digit",
  }

  if (typeof datetime === "string") {
    datetime = toSelectedTimezone(new Date(datetime), true)
  }

  return customFormatter({
    datetime,
    formatOptions,
    targetLocale,
    showInSelectedCountryTimeZone: true,
  })
}

export const intlFormatISO8601 = (datetime: Date) => {
  if (!datetime) return EMPTY_DATE_VALUE

  const zonedDateTime = toSelectedTimezone(datetime)

  return zonedDateTime.toISOString()
}

export const toSelectedTimezone = (
  datetime: Date = new Date(),
  ignoreNavigatorTimezone: boolean = false,
) => {
  const navigatorTimezoneOffset = ignoreNavigatorTimezone
    ? 0
    : getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const selectedCountryTimezoneOffset = getTimezoneOffset(getTimezoneName())

  const targetTimezoneOffset =
    navigatorTimezoneOffset - selectedCountryTimezoneOffset

  return addMilliseconds(datetime, targetTimezoneOffset)
}

export const getOffset = (timeZone: string) => {
  return getFormattedElement(timeZone, "timeZoneName", "shortOffset")
}

export const getFormattedElement = (
  timeZone: string,
  name: string,
  value: string,
) => {
  return (
    new Intl.DateTimeFormat("en", {
      [name]: value,
      timeZone,
    })
      .formatToParts()
      .find((el) => el.type === name) || {}
  ).value
}

export const datetimeToDate = (date: Date | string) => {
  if (!isDate(date)) {
    return ""
  }
  return format(date, "yyyy-MM-dd")
}

export const datetimeToTime = (date: Date | string) => {
  if (!isDate(date)) {
    return ""
  }
  return format(date, "HH:mm")
}

export const isDate = (date: Date | string) => {
  return Boolean(date instanceof Date || Date.parse(date))
}
