import { Col, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../../../components/Form/formComponents/Label"

export function DataInvoiceForms({
  invoice,
  setInvoice,
  disableMessage,
}: DisableInvoiceFormsProps) {
  const { t } = useTranslation()

  return (
    <>
      <Form.Group as={Col} className="mb-3" controlId="emitedAt">
        <Label required>{t("Invoice.attributes.emission")}</Label>
        <Form.Control
          required
          disabled={invoice?.automaticRegistration === 1}
          title={invoice?.automaticRegistration === 1 ? disableMessage : ""}
          type={"date"}
          value={invoice?.emmitedAt}
          lang="pt-BR"
          pattern="\d*"
          onChange={(e) => {
            setInvoice({ ...invoice, emmitedAt: e.target.value })
          }}
        />
        <Form.Control.Feedback type="invalid">
          {t("Global.alerts.requiredFieldInput")}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  )
}
