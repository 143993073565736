import { formatDocument } from "../../../../../../utils/Format/formatDocument"

export function ShowSimpleCustomer({
  corporateName,
  document,
}: Customer | { document: string; corporateName: string }) {
  return (
    <>
      {corporateName} <br />
      <small className="text-secondary">{formatDocument(document)}</small>
    </>
  )
}
