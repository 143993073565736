import { isFunction } from "lodash"
import DropdownTagsInputs from "../../../../../components/Form/formComponents/DropdownTagsInput"
import { createTag, getTags } from "../../../../../services/tags.service"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../components/Form/formComponents/Label"

export default function BillTagInput(props: DefaultBillField) {
  const { t } = useTranslation()
  const { bill, setBill } = props

  async function createNewTag(description: string) {
    const { data } = await createTag(description)
    return data
  }

  return (
    <Form.Group className="mb-3">
      <Label>{t("Global.attributes.tag.other")}</Label>
      <DropdownTagsInputs<Tag>
        tags={bill?.tags ?? []}
        setTags={(tags) =>
          setBill((bill) => ({
            ...bill,
            tags: isFunction(tags) ? tags(bill?.tags ?? []) : tags,
          }))
        }
        createNewTag={createNewTag}
        tagValue={(tag) => tag.description}
        tagsRequest={(props: { search: string; pageNumber: number }) =>
          getTags({ ...props, scope: "bills" })
        }
      />
    </Form.Group>
  )
}
