import { t } from "i18next"
import { useCountryConfigs } from "../../../../../../../utils/hooks/useCountryConfigs"
import { CustomModalItemsView } from "../../../../../../../components/Customs/CustomModalItensView"
import { FormatMoney } from "../../../../../../../components/Formats/FormatMoney"
import { FormatWeight } from "../../../../../../../components/Formats/FormatWeight"
import { capitalizeText } from "../../../../../../../utils/Format"
import { formatCEP } from "../../../../../../../utils/Format/FormatCep"
import { formatDocument } from "../../../../../../../utils/Format/formatDocument"
import { eventGa } from "../../../../../../../utils/eventClickGa"
import { InvoicesEmmitedAt } from "../../../InvoicesTable/components/InvoicesEmmitedAt"
import { LinkToRepurposal } from "../LinkToRepurposal"
import { stockKindsMap } from "../../../../../../../utils/stockKindsMap"
import { SALES_CHANNEL_MAP } from "../../../../../../../utils/salesChannelMap"
import { AuditField } from "../../../../../../../components/Summaries/AuditField"
import { intlFormatDate } from "../../../../../../../utils/timezoneHelpers"
import { Info } from "../../../../../../../components/Info"
import { CustomerSummary } from "../../../../../../../components/Summaries/CustomerSummary/CustomerSummary"
import { CustomLink } from "../../../../../../../components/Customs/CustomLink"

interface IHeaderInvoiceView {
  items: Invoice | undefined
  isLoading: boolean
}

export function HeaderInvoiceView({ items, isLoading }: IHeaderInvoiceView) {
  const {
    countryCode: [countryCode],
    configs: {
      invoices: {
        showInvoiceIssuerStateRegistration,
        showInvoiceReceiverStateRegistration,
      },
    },
    findPluralRule,
  } = useCountryConfigs()

  const document = t(`Global.attributes.document.${countryCode}`)
  const corporateName = t("Global.attributes.businessName")

  const columns: ModalViewItem<Invoice>[][] = [
    [
      {
        label: t("Invoice.attributes.invoiceViewNumber.one"),
        content: (invoice) => (
          <strong className="fw-bold fs-5 text-dark">
            {invoice.invoiceNumber}
          </strong>
        ),
        field: "invoiceNumber",
        emptyState: <strong className="fw-bold fs-5 text-dark">-</strong>,
      },
      {
        label: t("Invoice.attributes.emission"),
        content: (invoice) =>
          InvoicesEmmitedAt({
            item: invoice,
          }),
        field: "emmitedAt",
      },
      {
        label: t("Invoice.attributes.monthGoalOn"),
        content: (invoice) => intlFormatDate(invoice.monthGoalOn),
        field: "monthGoalOn",
      },
      {
        label: t("Invoice.attributes.invoiceViewRepurposalNumber"),
        content: LinkToRepurposal,
        field: "repurposalCode",
      },
      {
        label: (
          <>
            {t("Global.attributes.erpNumber")}{" "}
            <Info>
              {t("Global.alerts.thisFieldIsSetAccordingToTheRepurposal")}
            </Info>
          </>
        ),
        content: (invoice) => invoice.repurposalCore?.erpNumber ?? "-",
      },
      {
        label: t("Bills.attributes.billNumber"),
        content: (invoice) =>
          invoice?.billId ? (
            <CustomLink
              className="text-primary"
              href={`/financial/bills?financialId=${invoice.billId}`}
            >
              {invoice.billId}
            </CustomLink>
          ) : (
            "-"
          ),
        field: "billId",
      },
      {
        label: t("Deliveries.attributes.deliveryNumber"),
        content: (invoice) =>
          invoice?.deliveryId ? (
            <CustomLink
              className="text-primary"
              href={`/logistics/deliveries?logisticsId=${invoice.deliveryId}`}
            >
              {invoice.deliveryId}
            </CustomLink>
          ) : (
            "-"
          ),
        field: "deliveryId",
      },
      {
        label: t("Invoice.attributes.amountNF"),
        content: (invoice) =>
          FormatMoney({ amount: Math.abs(Number(invoice.valueCents)) }),
        field: "valueCents",
      },

      {
        label: t("Global.attributes.status"),
        content: (invoice) => t(`Invoice.attributes.status.${invoice.status}`),
        field: "status",
      },
      {
        label: t("Global.attributes.type"),
        content: (invoice) => t(`Invoice.attributes.kind.${invoice.kind}`),
        field: "kind",
      },
    ],
    [
      {
        label: t("Invoice.attributes.xml"),
        content: (invoice) => (
          <a
            href={invoice.xml}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              eventGa({
                action: "invoices_xml_table_click",
                category: "Invoices",
                label: "Invoices XML",
              })
            }}
          >
            {t("Global.actions.visualize")}
          </a>
        ),
        field: "xml",
      },
      {
        label: t("Invoice.attributes.pdf"),
        content: (invoice) => (
          <a
            href={invoice.pdf}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              eventGa({
                action: "invoices_pdf_table_click",
                category: "Invoices",
                label: "Invoices PDF",
              })
            }}
          >
            {t("Global.actions.visualize")}
          </a>
        ),
        field: "pdf",
      },
      {
        label: t("StockManagement.attributes.stockGrouping.one"),
        content: (invoice) => invoice?.repurposalStockGrouping,
        field: "repurposalStockGrouping",
      },
      {
        label: t("Invoice.attributes.stockKind"),
        content: (invoice) =>
          t([
            `StockCheckin.attributes.${
              invoice.repurposalCore &&
              invoice.repurposalCore.stock.checkins.length > 0
                ? stockKindsMap[
                    invoice.repurposalCore.stock.checkins?.[0]?.kind
                  ]
                : ""
            }`,
            "Global.alerts.NotIdentified",
          ]),
        "data-testid": "stockKind",
      },
      {
        label: t("Global.attributes.netWeight"),
        content: (invoice) => FormatWeight({ quantity: invoice.netWeight }),
        field: "netWeight",
      },
      {
        label: t("Global.attributes.grossWeight"),
        content: (invoice) => FormatWeight({ quantity: invoice.grossWeight }),
        field: "grossWeight",
      },
      {
        label: t("Global.attributes.createdBy"),
        content: (invoice) => AuditField(invoice.auditCreate),
        field: "auditCreate",
      },
      {
        label: t("Global.attributes.lastModifiedBy"),
        content: (invoice) => AuditField(invoice.auditLastUpdate),
        field: "auditLastUpdate",
      },
      {
        label: t(
          `Global.attributes.tag.${findPluralRule(items?.tags?.length)}`,
        ),
        content: (invoice) =>
          invoice.tags?.map(({ description }) => description).join(", ") ?? "-",
        field: "tags",
      },
    ],
    [
      {
        label: (
          <span className="text-secondary fs-5">
            {t("Invoice.attributes.issuer.one")}
          </span>
        ),
      },
      {
        label: `${corporateName} / ${document}`,
        content: (invoice) => {
          return CustomerSummary({
            ...invoice?.supplier,
            document: invoice?.supplierDocument,
          })
        },
        field: "supplier",
      },
      ...(showInvoiceIssuerStateRegistration
        ? ([
            {
              label: t("Invoice.attributes.fiscalDepartmentRegistration", {
                counterparty: t("Invoice.attributes.issuer.one"),
              }),
              content: (invoice) => invoice.issuerFiscalDepartmentRegistration,
              field: "issuerFiscalDepartmentRegistration",
            },
          ] as ModalViewItem<Invoice>[])
        : []),
      {
        label: t("Logistics.attributes.itemFrom", {
          location: t("Logistics.attributes.origin"),
          item: t([
            `Logistics.attributes.postalCode.${countryCode}`,
            "Logistics.attributes.postalCode.default",
          ]),
        }),
        content: (invoice) => formatCEP(invoice.postalCodeOrigin),
        field: "postalCodeOrigin",
      },
      {
        label: capitalizeText(
          t(
            [
              `Address.attributes.divisionAddress.${countryCode}`,
              "Address.attributes.divisionAddress.default",
            ],
            {
              location: t("Logistics.attributes.origin"),
            },
          ),
        ),
        content: (invoice) => invoice.originRegionName,
        field: "originRegionName",
      },
      {
        label: (
          <span className="text-secondary fs-5 pt-4">
            {capitalizeText(t("Global.attributes.intermediation"))}
          </span>
        ),
      },
      {
        label: capitalizeText(t("Global.attributes.dealKind")),
        content: (invoice) =>
          invoice.repurposalCore?.stock.salesChannel
            ? capitalizeText(
                t(
                  `Reports.KPIs.attributes.${SALES_CHANNEL_MAP[invoice.repurposalCore?.stock.salesChannel]}`,
                ),
              )
            : "-",
      },
      ...(items?.repurposalCore?.stock.salesChannel === "distribution_via"
        ? [
            {
              label: `${t("Repurposal.attributes.originRepurposal")}`,
              content: (invoice: Invoice) => (
                <LinkToRepurposal
                  repurposalCode={
                    invoice.repurposalCore?.originRepurposalCore?.code
                  }
                />
              ),
            },
            {
              label: `${t("Global.attributes.businessName")} (${t("Global.attributes.supplier.one")})`,
              content: (invoice: Invoice) =>
                invoice.repurposalCore?.stock.checkins?.[0]?.supplier
                  .corporateName,
              "data-testid": "origin-supplier",
            },
            {
              label: `${t([
                `Global.attributes.document.${countryCode}`,
                "Global.attributes.document.document",
              ])} (${t("Global.attributes.supplier.one")})`,
              content: (invoice: Invoice) => {
                const document =
                  invoice.repurposalCore?.stock.checkins?.[0]?.supplier.document
                return document ? formatDocument(document) : "-"
              },
            },
          ]
        : []),
    ],
    [
      {
        label: (
          <span className="text-secondary fs-5">
            {t("Invoice.attributes.receiver.one")}
          </span>
        ),
      },
      {
        label: `${corporateName} / ${document}`,
        content: (invoice) => {
          return CustomerSummary({
            ...invoice?.buyer,
            document: invoice?.buyerDocument,
          })
        },
        field: "buyer",
      },
      ...(showInvoiceReceiverStateRegistration
        ? ([
            {
              label: t("Invoice.attributes.fiscalDepartmentRegistration"),
              content: (invoice) =>
                invoice.receiverFiscalDepartmentRegistration,
              field: "receiverFiscalDepartmentRegistration",
            },
          ] as ModalViewItem<Invoice>[])
        : []),
      {
        label: t("Logistics.attributes.itemFrom", {
          location: t("Logistics.attributes.destination"),
          item: t([
            `Logistics.attributes.postalCode.${countryCode}`,
            "Logistics.attributes.postalCode.default",
          ]),
        }),
        content: (invoice) => formatCEP(invoice.postalCodeDestination),
        field: "postalCodeDestination",
      },
      {
        label: capitalizeText(
          t(
            [
              `Address.attributes.divisionAddress.${countryCode}`,
              "Address.attributes.divisionAddress.default",
            ],
            {
              location: t("Logistics.attributes.destination"),
            },
          ),
        ),
        content: (invoice) => invoice.destinationRegionName,
        field: "destinationRegionName",
      },
    ],
  ]

  return (
    <CustomModalItemsView
      item={items}
      columns={columns}
      isLoading={isLoading}
      data-testid="invoiceSummary"
    />
  )
}
