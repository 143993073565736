import { Form, FormLabelProps } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export function Label(
  props: FormLabelProps & {
    required?: boolean
    optional?: boolean
    complement?: React.ReactNode
  },
) {
  const { t } = useTranslation()
  const {
    required,
    optional = false,
    children,
    complement,
    className,
    ...otherProps
  } = props

  const labelClassName = className ?? (complement ? "d-flex gap-1" : undefined)

  return (
    <Form.Label className={labelClassName} {...otherProps}>
      {children}
      {required && <span className="text-danger"> *</span>}
      {!required && optional && (
        <span className="text-muted"> {t("Global.attributes.optional")}</span>
      )}
      {complement}
    </Form.Label>
  )
}
