import { PriceInput } from "../../../../components"
import { t } from "i18next"
import { Form, InputGroup } from "react-bootstrap"

interface CheckinValueInputProps {
  isEdit?: boolean
  errors: CheckinModalErrors
  currency: {
    code: string
    symbol: string
  }
  priceInput: string
  setPriceInput: React.Dispatch<React.SetStateAction<string>>
}

export default function CheckinValueInput(props: CheckinValueInputProps) {
  const { isEdit, errors, currency, priceInput, setPriceInput } = props
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>
          {t("StockCheckin.attributes.checkinValue")}
          {isEdit && <span className="text-danger">*</span>}
        </Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon2">{currency.code}</InputGroup.Text>
          <InputGroup.Text id="basic-addon2" className="rounded-0">
            {currency.symbol}
          </InputGroup.Text>
          <PriceInput
            name="checkinValue"
            onChange={(value) => setPriceInput(String(value))}
            value={Number(priceInput)}
            isInvalid={isEdit && errors.checkinValueError}
            datatestid={isEdit ? "checkinValueEdit" : "checkinValue"}
          />
          {isEdit && (
            <Form.Control.Feedback type="invalid">
              {errors.checkinValueError && t("Global.alerts.requiredField")}
            </Form.Control.Feedback>
          )}
        </InputGroup>
      </Form.Group>
    </>
  )
}
