import { Tooltip as BootstrapTooltip, OverlayTrigger } from "react-bootstrap"
import type { Placement } from "react-bootstrap/esm/types"
import styled from "styled-components"

type Props = {
  children: React.ReactNode
  title?: string
  placement?: Placement
  as?: React.ElementType
}

const StyledDiv = styled.div`
  display: inherit;
`

export default function Tooltip({
  children,
  title,
  placement = "top",
  as: Component = StyledDiv,
}: Props) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={title ? <BootstrapTooltip>{title}</BootstrapTooltip> : <></>}
    >
      <Component>{children}</Component>
    </OverlayTrigger>
  )
}
