import { useTranslation } from "react-i18next"
import { createTag, getTags } from "../../../../../../../services/tags.service"
import { Form } from "react-bootstrap"
import { Label } from "../../../../../../../components/Form/formComponents/Label"
import DropdownTagsInputs from "../../../../../../../components/Form/formComponents/DropdownTagsInput"
import { isFunction } from "lodash"

export default function InvoiceTagInput(props: DefaultInvoiceFormsProps) {
  const { t } = useTranslation()
  const { invoice, setInvoice } = props

  async function createNewTag(description: string) {
    const { data } = await createTag(description)
    return data
  }

  return (
    <Form.Group className="mb-3">
      <Label>{t("Global.attributes.tag.other")}</Label>
      <DropdownTagsInputs<Tag>
        tags={invoice?.tags ?? []}
        setTags={(tags) =>
          setInvoice({
            ...invoice,
            tags: isFunction(tags) ? tags(invoice?.tags ?? []) : tags,
          })
        }
        createNewTag={createNewTag}
        tagValue={(tag) => tag?.description}
        tagsRequest={(props: { search: string; pageNumber: number }) =>
          getTags({ ...props, scope: "invoices" })
        }
      />
    </Form.Group>
  )
}
