import { endOfDay, startOfDay } from "date-fns"
import { t } from "i18next"
import { useEffect, useRef, useState } from "react"
import { Dropdown, Form } from "react-bootstrap"
import { DateRange, DayPicker } from "react-day-picker"
import "react-day-picker/dist/style.css"

import DatePickerFilterPresets from "./components/DatePickerFilterPresets"
import {
  datetimeToDate,
  intlFormatDate,
  toSelectedTimezone,
} from "../../../utils/timezoneHelpers"
import { getBrowserLanguage } from "../../../utils/getBrowserLanguage"

function createDefault(
  startFilter: DateFilter | undefined,
  prevState: DateFilter | undefined,
  onlyDate: boolean,
): DateRange | undefined {
  const dateFilter = prevState ?? startFilter
  if (!dateFilter) return undefined

  return onlyDate
    ? {
        from: new Date(`${dateFilter.initial} 00:00`),
        to: new Date(`${dateFilter.final} 00:00`),
      }
    : {
        from: new Date(dateFilter.initial),
        to: new Date(dateFilter.final),
      }
}

export default function DatePickerFilter(props: DatePickerInterfaceProps) {
  const {
    type,
    reset,
    setReset,
    setDate,
    filter,
    setFilter,
    filterName,
    label,
    prevState,
    footer,
    withPresets = true,
    onlyDate = false,
  } = props as DatePickerComponentProps

  const actualYear = new Date().getFullYear()
  const startFilter =
    filter && filterName ? (filter[filterName] as DateFilter) : undefined

  const { dateFnsLocale } = getBrowserLanguage()

  const toggleRef = useRef<HTMLButtonElement | null>(null)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    startFilter?.initial ? new Date(`${startFilter?.initial}`) : new Date(),
  )
  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >(createDefault(startFilter, prevState, onlyDate))

  const formatDate = (date: Date) => intlFormatDate(date)

  function handleSelectedDate(date: Date | undefined | DateRange) {
    let from: Date | undefined = undefined
    let to: Date | undefined = undefined
    ;({ from, to } = determineFromAndToDates(date))

    updateSelectedDatesState(date, from, to)

    const selectedDates = createSelectedDatesObject(from, to)
    updateFiltersAndDates(selectedDates)
  }

  function determineFromAndToDates(date: Date | undefined | DateRange): {
    from: Date | undefined
    to: Date | undefined
  } {
    let from: Date | undefined = undefined
    let to: Date | undefined = undefined

    if (date instanceof Date) {
      from = startOfDay(date)
    } else {
      const toDate = date?.to ?? date?.from
      from = date?.from ? startOfDay(date.from) : undefined
      to = toDate ? endOfDay(toDate) : from
    }
    return { from, to }
  }

  function updateSelectedDatesState(
    date: Date | undefined | DateRange,
    from: Date | undefined,
    to: Date | undefined,
  ) {
    if (date instanceof Date) {
      setSelectedDate(from)
    } else {
      setSelectedDateRange({ from, to })
    }
  }

  function createSelectedDatesObject(
    from: Date | undefined,
    to: Date | undefined,
  ) {
    return onlyDate
      ? {
          initial: from ? datetimeToDate(from) : "",
          final: to ? datetimeToDate(to) : "",
        }
      : {
          initial: from ? toSelectedTimezone(from).toISOString() : "",
          final: to ? toSelectedTimezone(to).toISOString() : "",
        }
  }

  function updateFiltersAndDates(selectedDates: {
    initial: string
    final: string
  }) {
    if (setFilter && filterName) {
      setFilter((prev) => ({
        ...prev,
        [filterName]: selectedDates,
      }))
    }
    if (setDate) {
      setDate(selectedDates)
    }
  }

  useEffect(() => {
    if (reset) {
      setDate && setDate({ initial: "", final: "" })
      setSelectedDate(undefined)
      setSelectedDateRange(undefined)
    }
    setReset(false)
  }, [reset])

  const formatDateSingleDate = () =>
    selectedDate ? formatDate(selectedDate) : t("Global.attributes.all")

  const formatRangeDate = () => {
    if (!selectedDateRange?.from) {
      return t("Global.attributes.all")
    }

    const fromDateString = formatDate(selectedDateRange.from)
    const toDateString = selectedDateRange.to
      ? formatDate(selectedDateRange.to)
      : ""
    return `${fromDateString} - ${toDateString}`
  }

  const formattedDate = () => {
    switch (type) {
      case "single-date":
        return formatDateSingleDate()
      case "range":
        return formatRangeDate()
      default:
        return ""
    }
  }

  return (
    <Form.Group className={label ? "mt-3" : ""}>
      {label && (
        <Form.Label
          className="limite-caracteres-label mb-0"
          data-testid={`${filterName}Label`}
          onClick={() => toggleRef.current?.click()}
        >
          {label}
        </Form.Label>
      )}
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-secondary"
          className="dropdown-input-button bg-white text-dark w-100 d-flex justify-content-between focus-ring form-select border"
          data-testid={`${filterName}FilterToggle`}
          ref={toggleRef}
        >
          <span className="text-truncate">{formattedDate()}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu data-testid={`${filterName}FilterContent`}>
          {type === "single-date" ? (
            <Dropdown.Header className="text-dark fs-6">
              <DayPicker
                mode="single"
                selected={selectedDate}
                onSelect={(date) => handleSelectedDate(date)}
                captionLayout="dropdown-buttons"
                fromYear={2023}
                toYear={Number(actualYear)}
                locale={dateFnsLocale}
                footer={footer}
              />
            </Dropdown.Header>
          ) : (
            <Dropdown.Header
              className="text-dark fs-6 d-flex"
              data-testid={`${filterName}DayPicker`}
            >
              {withPresets && (
                <DatePickerFilterPresets
                  handleSelectedDate={handleSelectedDate}
                  selectedDateRange={selectedDateRange}
                />
              )}
              <DayPicker
                mode="range"
                selected={selectedDateRange}
                onSelect={(date) => handleSelectedDate(date)}
                captionLayout="dropdown-buttons"
                fromYear={2023}
                toYear={Number(actualYear)}
                locale={dateFnsLocale}
                footer={footer}
                data-testid={`${filterName}DayPicker`}
              />
            </Dropdown.Header>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  )
}
