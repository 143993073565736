import React, { Dispatch, SetStateAction } from "react"
import { Col, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../../../components/Form/formComponents/Label"

interface PropsArquiveInvoiceForms extends DisableInvoiceFormsProps {
  typeXml: boolean
  setTypeXml: Dispatch<SetStateAction<boolean>>
  typePdf: boolean
  setTypePdf: Dispatch<SetStateAction<boolean>>
  showEdit?: boolean
  validated: boolean
  pdfFile?: File | Blob
  setPdfFile: React.Dispatch<React.SetStateAction<File | undefined>>
  xmlFile?: File | Blob
  setXmlFile: React.Dispatch<React.SetStateAction<File | undefined>>
}

export function ArquiveInvoiceForms({
  typeXml,
  setTypeXml,
  typePdf,
  setTypePdf,
  showEdit,
  validated,
  pdfFile,
  setPdfFile,
  xmlFile,
  setXmlFile,
  invoice,
  disableMessage,
}: PropsArquiveInvoiceForms) {
  const { t } = useTranslation()

  const handleImagePdf = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] as File
    if (file?.type !== "application/pdf") {
      setTypePdf(true)
    } else {
      setTypePdf(false)
    }

    if (setPdfFile) {
      setPdfFile(file)
    }
  }

  const handleImageXml = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] as File
    if (file?.type !== "text/xml") {
      setTypeXml(true)
    } else {
      setTypeXml(false)
    }
    if (setXmlFile) {
      setXmlFile(file)
    }
  }

  return (
    <>
      <Form.Group as={Col} className="mb-3" controlId="formBasicXml">
        <Label>{t("Invoice.attributes.xml")}</Label>
        <Form.Control
          required={!showEdit && validated && (Boolean(!pdfFile) || typeXml)}
          onChange={handleImageXml}
          type="file"
          accept=".xml"
          disabled={invoice?.automaticRegistration === 1}
          title={disableMessage}
        />
        {typeXml && (
          <small className="text-danger" style={{ fontSize: "13px" }}>
            {t("Global.alerts.xmlFormat")}
          </small>
        )}
        <Form.Control.Feedback type="invalid">
          {t("Global.alerts.requiredFieldInput")}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="formBasicPdf">
        <Label>{t("Invoice.attributes.pdf")}</Label>
        <Form.Control
          required={!showEdit && validated && (Boolean(!xmlFile) || typePdf)}
          onChange={handleImagePdf}
          type="file"
          accept=".pdf"
        />
        {typePdf && (
          <small className="text-danger">{t("Global.alerts.pdfFormat")}</small>
        )}
        <Form.Control.Feedback type="invalid">
          {t("Global.alerts.requiredFieldInput")}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  )
}
