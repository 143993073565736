import { FilterMultiSelect } from "./FilterMultiSelect"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useDebounceValue } from "usehooks-ts"
import { useCountryConfigs } from "../../../../../utils/hooks/useCountryConfigs"
import { getTags } from "../../../../../services/tags.service"

type TagsConfig = Omit<TagsSelectOptionsConfig, "type">

type FilterTagsSelectProps = {
  filter: Record<string, string[]>
  setFilter: React.Dispatch<React.SetStateAction<Record<string, string[]>>>
} & TagsConfig

interface SimpleIndexTagQuery {
  tags: Array<Tag>
  count: number
  page: number
}

function defaultRenderValue(item: Tag): string | number {
  return item?.id
}

function defaultRenderName(item: Tag) {
  return item?.description
}

function getNextPageParam(lastPage: SimpleIndexTagQuery) {
  return lastPage?.page + 1
}

export function FilterTagsSelect(props: FilterTagsSelectProps) {
  const {
    scope,
    removeScroll,
    removeSearch,
    label,
    renderValue = defaultRenderValue,
    renderName = defaultRenderName,
    ...otherConfigs
  } = props
  const [search, setSearch] = useDebounceValue("", 500)
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()

  async function getTagsList({
    pageParam,
  }: {
    pageParam: number
  }): Promise<SimpleIndexTagQuery> {
    const data = await getTags({
      search,
      pageNumber: pageParam,
      scope,
    })
    return { tags: data.tags, page: pageParam, count: data.count }
  }

  const query = useInfiniteQuery({
    queryKey: ["tags", scope, search, countryCode],
    queryFn: getTagsList,
    initialPageParam: 1,
    getNextPageParam,
    staleTime: 5 * 60 * 1000,
  })

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value)
  }

  function handleScroll() {
    query.fetchNextPage()
  }

  return (
    <FilterMultiSelect<Tag>
      renderName={renderName}
      renderValue={renderValue as (item: Tag | undefined) => string}
      options={query.data?.pages.flatMap((page) => page.tags)}
      isLoading={query.isLoading}
      onChange={!removeSearch ? handleSearch : undefined}
      onScroll={!removeScroll ? handleScroll : undefined}
      count={query.data?.pages.slice(-1)[0].count}
      labelName={label}
      {...otherConfigs}
    />
  )
}
