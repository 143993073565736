import * as Sentry from "@sentry/react"

export const initializeSentry = () => {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    ignoreErrors: [
      "Failed to fetch dynamically imported module",
      "Request failed with status code 401",
      "Request failed with status code 403",
      "Request failed with status code 410",
      "Request failed with status code 422",
      "Request failed with status code 409",
      "CanceledError",
    ],
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/recoloca\.gooxxy\.com/,
      /^https:\/\/recoloca-web-prod\.herokuapp\.com\/api\//,
    ],
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: import.meta.env.VITE_AMBIENTE,
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
