import { Table } from "react-bootstrap"
import React, { Fragment, useId } from "react"
import { TableSkeleton } from "../../Tables/TableSkeleton"
import { TableEmptyState } from "../../TableEmptyState"
import { PaginationTable } from "../../Pagination"
import { CustomTableAccordionRow } from "./components/CustomTableAccordionRow"
import { CustomTableRow } from "./components/CustomTableRow"
import { CustomTableBody } from "./components/CustomTableBody"

export function CustomTable<T>(props: CustomTableProps<T>) {
  const {
    items,
    columns,
    isLoading = false,
    itemsPerPage,
    page,
    setPage,
    totalItems,
    variant = "white",
    totalizers = <></>,
    otherContent = <></>,
    responsive = "lg",
    fixedTableHead,
    accordion,
    accordionBody,
    rowShouldHaveArrow = () => true,
    itemKey,
    isPaginate = true,
    className,
    headersClassName,
    rowsClassName,
    tbodyWhite = true,
    borderInherit,
    overflowVisible = false,
    additionalInfoPagination,
  } = props

  const tableId = useId()
  const rowId = (item: T, index: number) =>
    itemKey ? itemKey(item) : `${tableId}-${index}`

  const columnsLength = accordion ? columns.length + 1 : columns.length

  const renderTableHead = () => {
    const filteredColumns = columns.filter((item) => item.name !== undefined)

    if (filteredColumns.length === 0) return null

    return (
      <thead>
        <tr>
          {accordion && <th />}
          {filteredColumns.map((item: Column<T>) => (
            <Fragment key={`thead-tr-th-${item.name}`}>
              {!item.isNoVisible && (
                <th
                  className={`col-${item.size ?? "auto"} text-nowrap text-${
                    item.direction ?? "start"
                  } ${item.thClassName ?? ""} ${headersClassName ?? ""}`}
                  data-testid={item["th-data-testid"]}
                >
                  {item.name}
                </th>
              )}
            </Fragment>
          ))}
        </tr>
      </thead>
    )
  }

  const renderTableRows = () => {
    if (isLoading) {
      return <TableSkeleton cols={columnsLength} className={rowsClassName} />
    }

    if (!items || items.length === 0) {
      return (
        <TableEmptyState
          totalColumns={columnsLength}
          className={rowsClassName}
        />
      )
    }

    return (
      <>
        {items.map((item: T, index: number) => (
          <Fragment key={rowId(item, index)}>
            {accordion ? (
              <CustomTableAccordionRow
                accordionBody={accordionBody}
                item={item}
                index={index}
                columns={columns}
                rowId={rowId(item, index)}
                rowClassName={rowsClassName}
                rowShouldHaveArrow={rowShouldHaveArrow}
                dataTestId={`table-accordion-${index}`}
              />
            ) : (
              <tr>
                <CustomTableRow
                  item={item}
                  columns={columns}
                  rowId={rowId(item, index)}
                  index={index}
                  rowClassName={rowsClassName}
                />
              </tr>
            )}
          </Fragment>
        ))}
        {otherContent}
        <tr className={`totalizer-table`}>{totalizers}</tr>
      </>
    )
  }

  const renderPagination = () => {
    if (totalItems && totalItems > 0 && itemsPerPage && page && isPaginate) {
      return (
        <PaginationTable
          itemsPerPage={itemsPerPage}
          page={page}
          totalItems={totalItems}
          setPage={setPage}
          firstPageIndex={(page - 1) * itemsPerPage + 1}
          lastPageIndex={(page - 1) * itemsPerPage + items.length}
          {...(additionalInfoPagination && {
            additionalInfo: additionalInfoPagination,
          })}
        />
      )
    }
    return null
  }

  return (
    <>
      <div
        style={{
          overflow: overflowVisible ? "visible" : "auto",
          maxWidth: "100vw",
        }}
      >
        <Table
          variant={variant}
          responsive={responsive}
          className={`table-hover mb-4${tbodyWhite ? " tbody-white" : ""}${
            fixedTableHead ? " fix-table-head" : ""
          }${borderInherit ? " table-border-inherit" : ""} ${className ?? ""}`}
          hover
          data-testid={props["table-data-testid"]}
        >
          {renderTableHead()}
          <CustomTableBody accordion={accordion}>
            {renderTableRows()}
          </CustomTableBody>
        </Table>
      </div>
      {renderPagination()}
    </>
  )
}
