import { t } from "i18next"

interface TableEmptyStateProps {
  totalColumns?: number
  className?: string
}

export function TableEmptyState(props: TableEmptyStateProps) {
  const { totalColumns, className } = props

  return (
    <tr>
      <td
        colSpan={totalColumns ? totalColumns : 8}
        className={`${className ?? ""} border-bottom-0`}
      >
        <span data-testid={"noResultFound"}>
          {t("Global.alerts.noResultFound")}
        </span>
      </td>
    </tr>
  )
}
