import { useEffect } from "react"
import { Col, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { getRepurposalById } from "../../../../../../../services/invoices.service"
import { Label } from "../../../../../../../components/Form/formComponents/Label"
import { RepurposalInput } from "../../../../../../../components/Form/formComponents/RepurposalInput"

export function NumberInvoiceForms(props: NumberInvoiceFormsProps) {
  const {
    repurposalNumberValidated,
    setRepurposalNumberValidated,
    showType,
    setShowType,
    stockKind,
    setStockKind,
    invoice,
    setInvoice,
  } = props
  const { t } = useTranslation()
  const { id: repurposalId } = useParams()

  useEffect(() => {
    const checkRepurposalId = async () => {
      try {
        if (!invoice?.repurposalCode || invoice?.repurposalCode?.length === 0) {
          setRepurposalNumberValidated(true)
        } else if (invoice?.repurposalCode?.length !== 10) {
          setRepurposalNumberValidated(false)
        } else {
          setShowType(false)
          const response = await getRepurposalById({
            repurposalId: invoice?.repurposalCode,
          })
          if (response) {
            setRepurposalNumberValidated(true)
            if (response.stock.checkins === null) {
              setStockKind(t("Global.alerts.NotIdentified") as string)
            } else if (response.stock.checkins.kind === "finished_goods") {
              setStockKind(t("StockCheckin.attributes.finished") as string)
            } else if (response.stock.checkins.kind === "raw_materials") {
              setStockKind(t("StockCheckin.attributes.rawMaterial") as string)
            } else {
              setStockKind(t("Global.alerts.NotIdentified") as string)
            }
          } else {
            setRepurposalNumberValidated(false)
            setShowType(true)
          }
        }
      } catch (err) {
        console.error(err)
        setRepurposalNumberValidated(false)
      }
    }
    checkRepurposalId()
  }, [invoice?.repurposalCode])

  return (
    <>
      <RepurposalInput
        required
        label={t("Invoice.attributes.repurposalNumber")}
        disabled={!!repurposalId}
        onClick={(repurposal) =>
          setInvoice({
            ...invoice,
            repurposalCode: repurposal.code,
            billId: undefined,
            deliveryId: undefined,
          })
        }
        value={
          invoice?.repurposalCode
            ? { code: invoice?.repurposalCode }
            : undefined
        }
        additionalContent={
          <>
            {!invoice?.repurposalCode ? (
              ""
            ) : repurposalNumberValidated ? (
              <small className="text-muted">
                <strong>{t("Invoice.attributes.stockKind")}: </strong>{" "}
                {stockKind}{" "}
              </small>
            ) : (
              ""
            )}
            {(!repurposalNumberValidated && invoice?.repurposalCode) ||
            showType ? (
              <small className="text-danger">
                {t("Invoice.alerts.warning.invalid.repurposalNumber")}
              </small>
            ) : (
              ""
            )}{" "}
          </>
        }
      />
      <Form.Group as={Col} className="mb-3" controlId="orderNumber">
        <Label>{t("Global.attributes.requestNumber")}</Label>
        <Form.Control
          type="text"
          value={invoice?.invoiceOrder}
          onChange={(e) => {
            setInvoice({ ...invoice, invoiceOrder: e.target.value })
          }}
        />
      </Form.Group>
    </>
  )
}
