import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../../../components/Form/formComponents/Label"

export function InvoiceReceiverStateRegistration(
  props: DefaultInvoiceFormsProps,
) {
  const { t } = useTranslation()
  const { invoice, setInvoice } = props

  return (
    <Form.Group className="mb-3">
      <Label>
        {t("Invoice.attributes.fiscalDepartmentRegistrationCounterparty", {
          counterparty: t("Invoice.attributes.receiver.one"),
        })}
      </Label>
      <Form.Control
        type="number"
        value={invoice?.receiverFiscalDepartmentRegistration ?? ""}
        onChange={(event) =>
          setInvoice({
            ...invoice,
            receiverFiscalDepartmentRegistration: event.target.value.replace(
              /-/g,
              "",
            ),
          })
        }
      />
    </Form.Group>
  )
}
