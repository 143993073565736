import React from "react"
import { Placeholder } from "react-bootstrap"

interface TableSkeletonProps {
  cols: number
  className?: string
}

export function TableSkeleton(props: TableSkeletonProps) {
  const { cols, className = "bg-white" } = props
  const rows: Array<React.ReactElement> = []

  for (let i = 0; i < 3; i++) {
    const cells: Array<React.ReactElement> = []
    for (let j = 0; j < cols; j++) {
      cells.push(
        <td className={className} key={j}>
          <Placeholder as="p" animation="glow" className="mb-1">
            <Placeholder xs={12} className=" rounded-1" />
          </Placeholder>
        </td>,
      )
    }
    rows.push(<tr key={i}>{cells}</tr>)
  }

  return <>{rows}</>
}
