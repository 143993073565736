import { useEffect } from "react"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { BsInfoCircleFill } from "react-icons/bs"

export function BillMailInput(props: BillPartMailInput) {
  const { sendMail, setSendMail, customerMail, customerInfoIsLoading } = props
  const { t } = useTranslation()
  const value = customerInfoIsLoading
    ? t("Global.actions.loading")
    : customerMail

  useEffect(() => {
    setSendMail(!!customerMail && !customerInfoIsLoading)
  }, [customerMail, customerInfoIsLoading])

  return (
    <Form.Group className="mb-3">
      <Form.Check
        id="send-mail-check"
        label={
          <span className=" d-inline-flex align-items-center gap-1">
            {t("Global.attributes.sendMail")}
            <BsInfoCircleFill className="text-secondary" />
          </span>
        }
        className="mb-2"
        checked={sendMail}
        onChange={() => setSendMail(!sendMail)}
      />
      <Form.Control key={value} defaultValue={value} disabled />
    </Form.Group>
  )
}
