import * as locales from "date-fns/locale"
import { CountryCode } from ".."
import { countries } from "countries-list"
import {
  CountryConfiguration,
  countryConfigurations,
} from "../../../../configs/countryConfig"

export type DateFnsLocations = keyof typeof locales
export const localesProxy = new Proxy(locales, {
  get: (target, name: DateFnsLocations) => {
    const language =
      name.length === 4 ? (name.slice(0, 2) as DateFnsLocations) : undefined
    return name in target
      ? target[name]
      : language && language in target
        ? target[language]
        : target["ptBR"]
  },
})

export function getCountryCode(): CountryCode {
  return localStorage.getItem("countryCode") as CountryCode
}

export function storeCountryCode(code: CountryCode) {
  localStorage.setItem("countryCode", code)
}

export function getLanguage(countryCode: CountryCode) {
  return countries[countryCode].languages[0]
}

export function getIntLocale(countryCode: CountryCode) {
  return new Intl.Locale("pt-BR", {
    language: getLanguage(countryCode),
    region: countryCode,
  })
}

export function getPluralRules(countryCode: CountryCode) {
  return new Intl.PluralRules(getIntLocale(countryCode).toString())
}

export function findPluralRule(pluralRules: Intl.PluralRules, number?: number) {
  return pluralRules.select(number ?? 0)
}

export function getDateFnsLocale(countryCode: CountryCode) {
  const language = getLanguage(countryCode)
  return localesProxy[`${language}${countryCode}` as DateFnsLocations]
}

export function getCurrency(countryCode: CountryCode) {
  return countries[countryCode].currency.split(",")[0]
}

export function getCurrencySymbol(countryCode: CountryCode) {
  const localion = getIntLocale(countryCode)
  const currency = getCurrency(countryCode)
  const value = 0
  const formatted = value.toLocaleString(localion, {
    style: "currency",
    currency: currency,
  })
  return formatted.replace(/[\d.,\s]/g, "")
}

export function getCountryConfig(
  countryCode: CountryCode,
): CountryConfiguration {
  return countryCode in countryConfigurations
    ? countryConfigurations[countryCode]
    : countryConfigurations.BR
}

export function getLanguages(countryCode: CountryCode): Array<string> {
  return countries[countryCode].languages
}

export function documentRegex(document?: string, countryCode?: CountryCode) {
  if (!countryCode) {
    countryCode = getCountryCode()
  }

  return document
    ? document.replace(countryConfigurations[countryCode].document.regex, "")
    : ""
}

export const getTimezoneName = (countryCode?: CountryCode) => {
  if (!countryCode) {
    countryCode = getCountryCode()
  }

  return countryConfigurations[countryCode].timezone.name
}
