import { useEffect, useId, useState } from "react"
import {
  customersList,
  searchCostumers,
} from "../../../../services/customer.service"
import { DropdownInput } from "../../../DropdownInput"
import { ShowSimpleCustomer } from "./components/ShowSimpleCustomer"
import { useTranslation } from "react-i18next"
import { Dropdown, Form } from "react-bootstrap"
import { formatDocument } from "../../../../utils/Format/formatDocument"
import { documentValidation } from "../../../../utils/documentValidation"
import { Label } from "../Label"
import { useDebounceCallback } from "usehooks-ts"

export function DocumentInput(props: DocumentInputProps) {
  const {
    setDocument,
    document,
    label,
    required,
    isNotExternal,
    onCustomerChange,
    isNotAcceptNewValue,
    ...otherProps
  } = props
  const { t } = useTranslation()
  const id = useId()
  const [customer, setCustomer] = useState<
    Customer | UndefinedCustomer | undefined
  >(
    document
      ? {
          document: formatDocument(document),
          corporateName: "-",
        }
      : undefined,
  )
  const [customerIsLoading, setCustomerIsLoading] = useState<boolean>(false)
  const [customersOptions, setCustomersOptions] = useState<
    Array<Customer | UndefinedCustomer>
  >([])
  const [customersListIsLoading, setCustomersListIsLoading] =
    useState<boolean>(true)
  const [search, setSearch] = useState<string | undefined>()
  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(Infinity)
  const debounced = useDebounceCallback(handleSearch, 500)

  async function findCustomer(document: string) {
    setCustomerIsLoading(true)
    try {
      const data = await searchCostumers(document)
      setCustomer(data)
      onCustomerChange && onCustomerChange(data)
    } catch (error) {
      console.error(error)
    } finally {
      setCustomerIsLoading(false)
    }
  }

  async function getCustomers(newSearch?: string) {
    let count = 0
    setCustomersListIsLoading(true)
    try {
      const { data } = await customersList({
        search: newSearch,
        pageNumber: 1,
        context: "simple_index",
        isNotExternal,
      })
      const documentSearch =
        newSearch && documentValidation(newSearch)
          ? formatDocument(newSearch, true)
          : undefined

      setCustomersOptions([
        ...(documentSearch &&
        !isNotAcceptNewValue &&
        !data.customers.find((c: Customer) => c.document === documentSearch)
          ? [
              {
                document: newSearch,
                corporateName: "-",
              },
            ]
          : []),
        ...data.customers,
      ])
      count = data.count
    } catch (error) {
      console.error(error)
    } finally {
      setCustomersListIsLoading(false)
    }
    setCount(count)
  }

  async function handleScroll() {
    const newPage = page + 1
    let count = 0
    try {
      const { data } = await customersList({
        search,
        pageNumber: newPage,
        context: "simple_index",
      })
      const newList = [...customersOptions, ...data.customers]
      count = data.count
      setCustomersOptions(newList)
      setPage(newPage)
    } catch (error) {
      console.error(error)
    }
    setCount(count)
  }

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value)
    return getCustomers(event.target.value)
  }

  function handleCustomerClick(customer?: Customer | UndefinedCustomer) {
    setCustomer(customer)
    setDocument(customer?.document)
    onCustomerChange && onCustomerChange(customer)
  }

  useEffect(() => {
    document && findCustomer(document)
    getCustomers()
    return () => setCustomer(undefined)
  }, [])

  return (
    <Form.Group
      className="mb-3"
      controlId={id}
      data-testid={`${props["data-testid"]}FormGroup`}
    >
      <Label required={required} data-testid={`${props["data-testid"]}Label`}>
        {label}
      </Label>
      <DropdownInput
        value={customer}
        onClick={handleCustomerClick}
        isLoading={customersListIsLoading}
        options={customersOptions}
        onChange={(event) => debounced(event)}
        onScroll={handleScroll}
        count={count}
        renderName={(customer) => formatDocument(customer.document)}
        renderOption={ShowSimpleCustomer}
        select
        required={required}
        id={props[`data-testid`]}
        emptyState={
          <Dropdown.Header
            className="text-center"
            data-testid="dropdownNotFoundValue"
          >
            {t("Global.alerts.notFoundOrInvalidValue")}
          </Dropdown.Header>
        }
        {...otherProps}
      />
      <small
        className="text-muted"
        data-testid={`${props["data-testid"]}CustomerName`}
      >
        {customerIsLoading
          ? t("Global.actions.loading")
          : customer &&
            (customer.corporateName === "-"
              ? t("Global.alerts.NotIdentified")
              : customer.corporateName)}
      </small>
    </Form.Group>
  )
}
