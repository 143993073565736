import {
  Accordion,
  AccordionButton,
  Col,
  Form,
  InputGroup,
} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { LogisticCodesForms } from "../LogisticCodesForms/inex"
import { capitalizeText } from "../../../../../../../utils/Format"
import { formatCEP } from "../../../../../../../utils/Format/FormatCep"
import { WeightInput } from "../../../../../../../utils/Format/WeightInput"
import { useCountryConfigs } from "../../../../../../../utils/hooks/useCountryConfigs"
import { Label } from "../../../../../../../components/Form/formComponents/Label"

export function LogisticInvoiceForms({
  disableMessage,
  invoice,
  setInvoice,
  baseFields,
}: DisableInvoiceFormsProps & {
  baseFields?: boolean
}) {
  const { t } = useTranslation()
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <h2 className="accordion-header">
          <AccordionButton className=" mb-0 alert-danger gap-0">
            {t("Logistics.logistics")}
          </AccordionButton>
        </h2>
        <Accordion.Body>
          <LogisticCodesForms invoice={invoice} setInvoice={setInvoice} />
          {!baseFields && (
            <>
              <Form.Group
                as={Col}
                className="mb-3"
                controlId="postalCodeOrigin"
              >
                <Label>
                  {capitalizeText(
                    t(`Logistics.attributes.itemFrom`, {
                      location: t("Logistics.attributes.origin"),
                      item: t([
                        `Logistics.attributes.postalCode.${countryCode}`,
                        "Logistics.attributes.postalCode.default",
                      ]),
                    }),
                  )}
                </Label>
                <Form.Control
                  type="text"
                  disabled={invoice?.automaticRegistration === 1}
                  title={
                    invoice?.automaticRegistration === 1 ? disableMessage : ""
                  }
                  value={formatCEP(invoice?.postalCodeOrigin) || ""}
                  onChange={(e) => {
                    setInvoice({
                      ...invoice,
                      postalCodeOrigin: e.target.value.replace(/\D/g, ""),
                    })
                  }}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                className="mb-3"
                controlId="postalCodeDestination"
              >
                <Label>
                  {capitalizeText(
                    t(`Logistics.attributes.itemFrom`, {
                      location: t("Logistics.attributes.destination"),
                      item: t([
                        `Logistics.attributes.postalCode.${countryCode}`,
                        "Logistics.attributes.postalCode.default",
                      ]),
                    }),
                  )}
                </Label>
                <Form.Control
                  type="text"
                  disabled={invoice?.automaticRegistration === 1}
                  title={
                    invoice?.automaticRegistration === 1 ? disableMessage : ""
                  }
                  value={formatCEP(invoice?.postalCodeDestination) || ""}
                  onChange={(e) => {
                    setInvoice({
                      ...invoice,
                      postalCodeDestination: e.target.value.replace(/\D/g, ""),
                    })
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3" controlId="netWeight">
                <Label>{t("Global.attributes.netWeight")}:</Label>
                <InputGroup>
                  <WeightInput
                    disabled={invoice?.automaticRegistration === 1}
                    title={
                      invoice?.automaticRegistration === 1 ? disableMessage : ""
                    }
                    value={Number(invoice?.netWeight)}
                    onChange={(weight: number | undefined) =>
                      setInvoice({ ...invoice, netWeight: weight })
                    }
                  />

                  <InputGroup.Text id="basic-addon1">kg</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} className="mb-3" controlId="grossWeight">
                <Label>{t("Global.attributes.grossWeight")}:</Label>
                <InputGroup>
                  <WeightInput
                    disabled={invoice?.automaticRegistration === 1}
                    title={
                      invoice?.automaticRegistration === 1 ? disableMessage : ""
                    }
                    value={Number(invoice?.grossWeight)}
                    onChange={(weight: number | undefined) =>
                      setInvoice({ ...invoice, grossWeight: weight })
                    }
                  />
                  <InputGroup.Text id="basic-addon1">kg</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
