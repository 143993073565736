import { t } from "i18next"
import { FormControl, FormGroup, FormLabel } from "react-bootstrap"

interface CheckinFileInputProps {
  isEdit?: boolean
  errors: CheckinModalErrors
  setErrors: React.Dispatch<React.SetStateAction<CheckinModalErrors>>
  setFormData: React.Dispatch<React.SetStateAction<CheckinModalFormData>>
}

export default function CheckinFileInput(props: CheckinFileInputProps) {
  const { errors, setErrors, setFormData } = props

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    const isValidFormat = selectedFile?.name.match(/\.(xlsx|csv|xls|xml)$/i)

    setErrors((prevErrors) => ({
      ...prevErrors,
      spreadsheetError: !!selectedFile && !isValidFormat,
      invalidFileFormat: !!selectedFile && !isValidFormat,
    }))

    setFormData((prevData) => ({
      ...prevData,
      spreadsheet: isValidFormat ? selectedFile : undefined,
    }))
  }

  return (
    <>
      {!props.isEdit && (
        <FormGroup className="mb-3">
          <FormLabel>
            {t("StockCheckin.attributes.spreadsheet")}
            <span className="text-danger"> *</span>
          </FormLabel>
          <FormControl
            type="file"
            name="spreadsheet"
            accept=".xls, .csv, .xlsx, .xml"
            onChange={handleFileChange}
            isInvalid={errors.spreadsheetError || errors.invalidFileFormat}
            data-testid="spreadsheet"
          />
          <FormControl.Feedback type="invalid">
            {errors.invalidFileFormat
              ? t("StockCheckin.attributes.invalidFileFormat")
              : errors.spreadsheetError && t("Global.alerts.requiredField")}
          </FormControl.Feedback>
        </FormGroup>
      )}
    </>
  )
}
