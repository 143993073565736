import { useEffect, useState } from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import {
  billPayments,
  updatePayment,
} from "../../../../../services/payments.service"
import { captureException } from "@sentry/react"
import { AxiosError } from "axios"
import { BsPlusLg } from "react-icons/bs"
import { MultiErrorAlerts } from "../../../../../components/MultiErrorAlerts"
import { FormatMoney } from "../../../../../components/Formats/FormatMoney"
import { useCountryConfigs } from "../../../../../utils/hooks/useCountryConfigs"
import { formatDocument } from "../../../../../utils/Format/formatDocument"
import { CustomTable } from "../../../../../components/Customs/CustomTable"
import { ButtonSubmit } from "../../../../../components/Buttons/ButtonSubmit"
import { BillUnlinkPaymentButton } from "../BillUnlinkPaymentButton"
import { intlFormatDate } from "../../../../../utils/timezoneHelpers"
import { PaymentInput } from "../../../../../components/Form/formComponents/PaymentInput/PaymentInput"
import { useQueryClient } from "@tanstack/react-query"
export function BillReceiptsTable(props: BillBoundsTableProps) {
  const { t } = useTranslation()
  const { bill, collapsed } = props
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState<boolean>()
  const [success, setSuccess] = useState<boolean>()
  const [tableIsLoading, setTableIsLoading] = useState<boolean>()
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const [payment, setPayment] = useState<SimpleIndexPayment>()
  const [payments, setPayments] = useState<Array<TableIndexPayment>>([])
  const [totalAmount, setTotalAmount] = useState<number>()
  const columns: Array<Column<TableIndexPayment>> = [
    {
      thClassName: "text-nowrap",
      name: t("Receipts.attributes.paidIn"),
      field: (payment) => {
        return intlFormatDate(payment?.paidOn)
      },
    },
    {
      name: t("Global.attributes.number"),
      field: (item) => item.id as number,
    },
    {
      name: t("Global.attributes.businessName"),
      tdClassName: "text-truncate",
      field: (item) => item.buyer?.name ?? "-",
    },
    {
      name: t("Receipts.attributes.paymentMethod.abbr"),
      field: (item) => item.paymentType.name,
    },
    {
      name: t([
        `Global.attributes.document.${countryCode}`,
        "Global.attributes.document.document",
      ]),
      field: (item) => formatDocument(item.buyerDocument),
    },
    {
      name: t("Global.attributes.value") as string,
      direction: "end",
      field: (item) => <FormatMoney amount={Number(item.value)} />,
    },
    {
      name: t("Global.actions.actions") as string,
      direction: "end",
      size: 1,
      field: (item) => (
        <BillUnlinkPaymentButton
          payment={item}
          setSuccess={setSuccess}
          billId={bill.id}
        />
      ),
    },
  ]
  async function paymentsList() {
    setTableIsLoading(true)
    setErrorMessages([])
    try {
      const { data } = await billPayments(bill.id)
      setPayments(data)
      setTotalAmount(
        data.reduce(
          (total: number, payment) => total + Number(payment.value),
          0,
        ),
      )
    } catch (error) {
      console.error(error)
      captureException(error)
      if (error instanceof AxiosError) {
        setErrorMessages(error.response?.data.messages)
      }
    } finally {
      setTableIsLoading(false)
    }
  }
  async function handleLink(event: React.FormEvent<HTMLFormElement>) {
    if (!payment) return

    event.preventDefault()
    setIsLoading(true)
    setErrorMessages([])
    setSuccess(undefined)
    try {
      await updatePayment({
        payment: {
          id: payment.id,
          billId: bill.id,
        },
      })
      setPayment(undefined)
      setSuccess(true)
    } catch (error) {
      console.error(error)
      captureException(error)
      if (error instanceof AxiosError) {
        setErrorMessages(error.response?.data.messages)
      }
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (!collapsed && payments.length === 0) {
      paymentsList()
    }
  }, [collapsed])
  useEffect(() => {
    if (success) {
      queryClient.invalidateQueries({
        queryKey: [
          "paymentss",
          "simple_index",
          countryCode,
          { repurposalCoreCode: bill.repurposalCore.code },
        ],
        exact: false,
      })
      paymentsList()
    }
  }, [success])

  return (
    <>
      {errorMessages.length > 0 && <MultiErrorAlerts items={errorMessages} />}
      <Form noValidate onSubmit={handleLink}>
        <Form.Group
          controlId="bill-link-receipt-input"
          className="mb-3 text-start"
        >
          <div className="d-flex gap-2">
            <PaymentInput
              repurposalCoreCode={bill.repurposalCore.code}
              onClick={(payment) => setPayment(payment)}
              id="payment-input"
              value={payment}
            />
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{t("Global.actions.link")}</Tooltip>}
            >
              <ButtonSubmit
                variant="outline-primary"
                className="d-flex justify-content-center align-items-center"
                disabled={isLoading || !payment}
                loading={isLoading}
                name={<BsPlusLg />}
                type="submit"
              />
            </OverlayTrigger>
          </div>
        </Form.Group>
      </Form>
      <CustomTable
        items={payments}
        columns={columns}
        isLoading={tableIsLoading}
        totalizers={
          <>
            {[...Array(5)].map((_, index) => (
              <td key={index} />
            ))}
            <td className="text-end fw-bold">
              <FormatMoney amount={totalAmount} />
            </td>
            <td />
          </>
        }
        headersClassName="bg-transparent"
        rowsClassName="bg-transparent"
        borderInherit
        tbodyWhite={false}
      />
    </>
  )
}
