import React, { useEffect, useRef } from "react"
import { Button, OverlayTrigger, Popover } from "react-bootstrap"

export function PopoverCustom(
  props: React.PropsWithChildren<PopoverCustomProps>,
) {
  const {
    popoverContent,
    popoverClassName,
    variant = "outline-primary",
    className,
    placement,
    children,
    buttonSize,
    style,
    title,
    disabled = false,
    onClose,
    onShow,
    show,
    setShow,
  } = props

  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const popoverRef = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node) &&
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      setShow(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    show ? onShow && onShow() : onClose && onClose()
  }, [show])

  const popover = (
    <Popover
      id="popover-basic"
      style={style}
      className={popoverClassName}
      data-testid={props["data-testid"] + "-popover"}
    >
      <div onClick={(e) => e.stopPropagation()} ref={popoverRef}>
        {popoverContent}
      </div>
    </Popover>
  )

  return (
    <OverlayTrigger
      trigger="click"
      placement={placement}
      overlay={popover}
      show={show}
      onToggle={(nextShow) => setShow(nextShow)}
    >
      <Button
        ref={buttonRef}
        title={title}
        variant={variant}
        size={buttonSize}
        disabled={disabled}
        className={className}
        onClick={() => setShow(!show)}
        data-testid={props["data-testid"] + "-button"}
      >
        {children}
      </Button>
    </OverlayTrigger>
  )
}
