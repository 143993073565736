import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../components/Form/formComponents/Label"
import { DropdownInput } from "../../../../../components/DropdownInput"
import { BILL_KIND_MAP } from "../../utils/billsMaps"

export default function BillKindInput(props: DefaultBillField) {
  const { t } = useTranslation()
  const { bill, setBill } = props

  return (
    <Form.Group className="mb-3" controlId="bill-kind">
      <Label required>{t("Global.attributes.type")}</Label>
      <DropdownInput
        value={bill?.kind}
        options={BILL_KIND_MAP}
        onClick={(value) => setBill((bill) => ({ ...bill, kind: value }))}
        renderName={(value) => t(`Bills.attributes.kind.${value}`)}
        id="kind"
        required
        select
      />
    </Form.Group>
  )
}
