import { deleteStockCheckin } from "../../../../services"
import { Trans } from "react-i18next"
import { DeleteItem } from "../../../../components/Modal/DeleteItem/DeleteItem"
import { t } from "i18next"
import { AxiosError } from "axios"

interface CheckinDeleteProps {
  handleFeedback: (
    show: boolean,
    message: string | JSX.Element,
    type: string,
  ) => void
  showDeleteModal: boolean
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  checkinId: number | undefined
  checkinValue: string | undefined
  handleReloadTable: () => void
  deleteFeedback: {
    show: boolean
    message: string
  }
  setDeleteFeedback: (
    value: React.SetStateAction<{
      show: boolean
      message: string
    }>,
  ) => void
}

export default function CheckinDelete(props: CheckinDeleteProps) {
  const {
    handleFeedback,
    showDeleteModal,
    setShowDeleteModal,
    setIsLoading,
    isLoading,
    checkinId,
    deleteFeedback,
    setDeleteFeedback,
  } = props

  async function handleDeleteCheckin(checkinId: number) {
    try {
      setIsLoading(true)

      await deleteStockCheckin(checkinId)

      handleFeedback(
        true,
        <Trans
          i18nKey="StockCheckin.alerts.remove.success"
          values={{ code: checkinId }}
          components={{ b: <b /> }}
        />,
        "success",
      )
      props.handleReloadTable()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        const message = error?.response?.data?.messages
        setDeleteFeedback({
          show: true,
          message: message || t("Global.alerts.internalServerError"),
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <DeleteItem
        title={(t("Global.actions.remove") as string) + " checkin"}
        useButton={false}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        text={t("StockCheckin.alerts.remove.removeAlert") as string}
        onDelete={() => handleDeleteCheckin(checkinId as number)}
        loading={isLoading}
        testid={`${props.checkinValue}`}
        feedbackError={deleteFeedback}
        closeOnSuccess={false}
        customIcon={
          <>
            <span className="fs-6 m-1">
              {t("Global.attributes.delete") as string}
            </span>
          </>
        }
      />
    </>
  )
}
