import { BASE_URL_RUBY } from "../assets/data"
import { GetSuppliersProps } from "../types/componentsTypes"

import { api, countryCodeExceptionsApi, errorExceptionsApi } from "./api"
import {
  DeleteStockGroupingServiceType,
  StockGroupingItem,
} from "../types/stockGroupings"
import { formatDocument } from "../utils/Format/formatDocument"

export const registerCustomer = async (props: RegisterCustomerProps) => {
  const { image, customer } = props
  const {
    crmType,
    crmId,
    id,
    name,
    document,
    corporateName,
    isBuyer,
    isSupplier,
    supplierResponsibleId,
    buyerResponsibleName,
    additionalInfo,
    dealsNotAllowedGooxxy,
    additionalInformation,
  } = customer
  const formData = new FormData()
  name && formData.append("name", name)
  document && formData.append("document", formatDocument(document, true))
  corporateName && formData.append("corporateName", corporateName)
  formData.append("isBuyer", isBuyer?.toString())
  formData.append("isSupplier", isSupplier?.toString())
  formData.append("additionalInformation", additionalInformation ?? "")
  formData.append(
    "dealsNotAllowedGooxxy",
    dealsNotAllowedGooxxy?.toString() ?? "false",
  )
  formData.append(
    "supplierResponsibleId",
    supplierResponsibleId ? supplierResponsibleId.toString() : "",
  )
  buyerResponsibleName &&
    !additionalInfo &&
    formData.append(
      "additionalInfo[buyerResponsibleName]",
      buyerResponsibleName,
    )
  additionalInfo &&
    formData.append(
      "additionalInfo[buyerResponsibleName]",
      additionalInfo.buyerResponsibleName
        ? additionalInfo.buyerResponsibleName
        : "",
    )
  if (image) {
    formData.append("imageFile", image)
  }
  formData.append("crmType", crmType ?? "")
  formData.append("crmId", crmId?.toString() ?? "")

  return await api(`/clients/customers/${id ?? ""}`, {
    method: id ? "PUT" : "POST",
    data: formData,
  })
}

export const deleteCustomer = async (id: number | undefined) => {
  return api.delete(`/clients/customers/${id}`)
}

export const exportCustomers = async (queryString: string) => {
  return await api({
    url: `/clients/customers/export?${queryString}`,
    method: "POST",
    responseType: "blob",
  })
}

export const supplierResponsibleList = async ({
  search = "",
  pageNumber = 1,
} = {}) => {
  return countryCodeExceptionsApi.get(`/clients/supplier_responsibles`, {
    params: { search, pageNumber, itemsPerPage: 20 },
  })
}

export const customersList = async (props: CustomersListProps) => {
  const {
    bond,
    customersGroupIds,
    gogreenGroupsIds,
    supplierStatus,
    buyerStatus,
    ...otherProps
  } = props
  let isSupplier, isBuyer
  switch (bond) {
    case "buyer":
      isBuyer = true
      isSupplier = false
      break
    case "supplier":
      isBuyer = false
      isSupplier = true
      break
    case "":
      isBuyer = false
      isSupplier = false
      break
    case "supplierBuyer":
      isBuyer = true
      isSupplier = true
      break
    default:
      break
  }

  return api.get("/clients/customers/", {
    params: {
      ...otherProps,
      ...(isBuyer ? { isBuyer } : {}),
      ...(isSupplier ? { isSupplier } : {}),
      ...(supplierStatus ? { supplierStatus } : {}),
      ...(buyerStatus ? { buyerStatus } : {}),
      customersGroupIds: [
        ...(customersGroupIds ? customersGroupIds : []),
        ...(gogreenGroupsIds ? gogreenGroupsIds : []),
      ],
    },
  })
}

export const getCrmCustomer = async (props: GetCrmCustomerProps) => {
  const { document, signal } = props

  return errorExceptionsApi.get(
    `/crm/customers/${formatDocument(document, true)}`,
    {
      signal,
    },
  )
}

export async function SupplierList() {
  const response = await api.get(`/clients/suppliers/all`)
  return response.data
}

export const registerDistributionCenter = async (item: StockGroupingItem) => {
  const payload = {
    name: item.name ?? "",
    code: item.code ?? "",
    customerId: item.customerId,
    freightTypeIds: item.freightTypes.map((freight) => freight.id),
    minPrice: item.minPrice,
    minWeight: item.minWeight,
    minPallet: item.minPallet,
    statesServedIds: item.statesServed.map((state) => state.id),
  }

  return await api.post(
    `${BASE_URL_RUBY}/clients/suppliers/stock_groupings`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  )
}

export const updateDistributionCenter = async (item: StockGroupingItem) => {
  const payload = {
    name: item.name ?? "",
    code: item.code ?? "",
    customerId: item.customerId,
    freightTypeIds: item.freightTypes.map((freight) => freight.id),
    minPrice: item.minPrice,
    minWeight: item.minWeight,
    minPallet: item.minPallet,
    statesServedIds: item.statesServed.map((state) => state.id),
  }

  return await api.put(
    `${BASE_URL_RUBY}/clients/suppliers/stock_groupings/${item.id}`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  )
}

export const removeStockGrouping = async (
  props: DeleteStockGroupingServiceType,
) => {
  await api.delete(
    `${BASE_URL_RUBY}/clients/suppliers/stock_groupings/${props.stockGroupingId}`,
  )
}

export const listActiveStocks = async (
  props: OrderBy,
  states: Array<string>,
  salesChannel: string,
  groupBy: "originSupplier" | "supplier",
) => {
  if (!groupBy) groupBy = "supplier"
  const { orderByField, orderByDirection } = props
  return api.get(`clients/customers/stocks`, {
    params: {
      orderByField,
      orderByDirection,
      states,
      salesChannel,
      groupBy,
    },
  })
}

export const showAvailablesStock = async (
  id?: string,
  salesChannel?: SalesChannels,
) => {
  return await api.get(`/clients/suppliers/${id}/stocks/availables`, {
    params: {
      salesChannel,
    },
  })
}

export async function searchCostumers(document: string) {
  const response = await api.get(
    `/clients/customers/documents/${formatDocument(document, true)}`,
  )
  return response.data
}

export async function getSuppliers(props: GetSuppliersProps) {
  const { context = "simple_index", ...otherProps } = props

  return await api.get(`/clients/customers`, {
    params: {
      context,
      ...otherProps,
    },
  })
}

export async function getSupplier(
  id: string,
  params?: { context?: "contacts" },
) {
  return await api.get(`clients/customers/${id}`, {
    params,
  })
}

export async function getStockGrouping(id: number) {
  return await api.get(`/clients/suppliers/${id}/stock_groupings`)
}

export async function deleteStockGroup(id: number) {
  return api.delete(`/clients/suppliers/stock_groupings/${id}`)
}

export async function GetCustomerContacts(id: number) {
  return api.get(`/clients/customers/${id}/contacts`)
}

export function createCustomerContact(
  id: number,
  phone: string,
  origin: "recolocation" | "ploomes_crm" | "bills",
) {
  return api.post(`/clients/customers/${id}/contacts`, {
    phone,
    origin,
  })
}
