import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCountryConfigs } from "../../../../../utils/hooks/useCountryConfigs"
import {
  BindInvoicesTableProps,
  InvoicesQueryFilter,
} from "./bindedInvoicesTable"
import { setApiErrorMessages } from "../../../../../utils/setApiErrorMessage"
import { MultiErrorAlerts } from "../../../../../components/MultiErrorAlerts"
import { InvoicesTable } from "../InvoicesTable"
import { FormatWeight } from "../../../../../components/Formats/FormatWeight"
import { FormatMoney } from "../../../../../components/Formats/FormatMoney"
import { BindInvoice } from "./BindInvoice"
import UnbindModal from "../../../../../components/Modal/UnbindModal/UnbindModal"

export function BindedInvoicesTable(props: BindInvoicesTableProps) {
  const { t } = useTranslation()
  const {
    findPluralRule,
    countryCode: [countryCode],
  } = useCountryConfigs()
  const {
    originId,
    collapsed,
    getBindedInvoices,
    bindFunction,
    unbindFunction,
    unbindMessageKey,
    unbindTitle,
    queryKey,
    repurposalCoreCode,
    otherFilters = {},
    label,
  } = props
  const [success, setSuccess] = useState<boolean>()
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [invoice, setInvoice] = useState<Invoice | undefined>()
  const queryClient = useQueryClient()

  const bindedInvoicesQueryKey: Array<string | number> = [
    ...queryKey,
    "invoices",
    originId,
    countryCode,
  ]
  const invoicesQueryKey: Array<
    string | number | { repurposalCode: string } | InvoicesQueryFilter
  > = [
    "invoices",
    "simple_index",
    { repurposalCode: repurposalCoreCode },
    otherFilters,
  ]

  const {
    isFetching: isLoading,
    error,
    data,
  } = useQuery({
    queryKey: bindedInvoicesQueryKey,
    queryFn: getInvoices,
    enabled: !!originId && !collapsed,
  })

  async function getInvoices(): Promise<BindedInvoicesQuery> {
    const { data } = await getBindedInvoices(originId)
    setSuccess(false)
    const totalizers = (data as Array<Invoice>).reduce(
      (totalizers, invoice: Invoice) =>
        invoice.status === "confirmed"
          ? {
              netWeight: totalizers.netWeight + Number(invoice.netWeight),
              grossWeight: totalizers.grossWeight + Number(invoice.grossWeight),
              totalValue: totalizers.totalValue + Number(invoice.valueCents),
            }
          : totalizers,
      {
        netWeight: 0,
        grossWeight: 0,
        totalValue: 0,
      },
    )
    return {
      invoices: data,
      totalGrossWeight: totalizers.grossWeight,
      totalNetWeight: totalizers.netWeight,
      totalValue: totalizers.totalValue,
      count: data.length,
    }
  }

  useEffect(() => {
    if (success) {
      queryClient.invalidateQueries({
        queryKey: bindedInvoicesQueryKey,
        exact: true,
      })
      queryClient.invalidateQueries({
        queryKey: invoicesQueryKey,
        exact: false,
      })
      setSuccess(false)
    }
  }, [success])

  useEffect(() => {
    error && setApiErrorMessages(error, setErrorMessages)
  }, [error])

  return (
    <>
      {errorMessages.length > 0 && <MultiErrorAlerts items={errorMessages} />}
      <BindInvoice
        repurposalCoreCode={repurposalCoreCode}
        setErrorMessages={setErrorMessages}
        setSuccess={setSuccess}
        collapsed={collapsed}
        originId={originId}
        bindFunction={bindFunction}
        invoicesQueryKey={invoicesQueryKey}
        otherFilters={otherFilters}
        label={label}
      />
      <InvoicesTable
        selectedColumnsOptions={[
          "invoiceNumber",
          "emmitedAt",
          "netWeight",
          "grossWeight",
          "value",
          "status",
          "actions",
        ]}
        items={data?.invoices ?? []}
        isLoading={isLoading}
        invoice={invoice}
        setInvoice={setInvoice}
        setSuccess={setSuccess}
        setSuccessMessages={() => {}}
        customAdditionalInfo={(invoice) => (
          <UnbindModal
            title={unbindTitle}
            unbindMessageKey={unbindMessageKey}
            unbindId={invoice.numInvoice}
            originId={originId}
            unbindFunction={async () => {
              await unbindFunction(invoice.id, originId)
              setSuccess(true)
            }}
          />
        )}
        headersClassName="bg-transparent"
        rowsClassName="bg-transparent"
        borderInherit
        tbodyWhite={false}
        totalizers={
          <>
            <td className="fw-bold">
              {data?.count}{" "}
              {t(`Global.attributes.item.${findPluralRule(data?.count)}`)}
            </td>
            <td />
            <td className="fw-bold text-end">
              <FormatWeight quantity={data?.totalNetWeight} />
            </td>
            <td className="fw-bold text-end">
              <FormatWeight quantity={data?.totalGrossWeight} />
            </td>
            <td className="fw-bold text-end">
              <FormatMoney amount={data?.totalValue} />
            </td>
          </>
        }
        showModalInParams={false}
      />
    </>
  )
}
