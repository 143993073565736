import { useState } from "react"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { BsFiles, BsFillInfoCircleFill } from "react-icons/bs"
import {
  intlFormatDate,
  intlFormatDateTime,
} from "../../../utils/timezoneHelpers"
import { PopoverCustom } from "../../PopoverCustom"

export function AdditionalInfoAndDataCell(props: AdditionalInfoAndDataCell) {
  const { additionalInfo, data, showTime } = props
  const { t } = useTranslation()
  const [copyContent, setCopyContent] = useState(false)
  const [show, setShow] = useState(false)
  const isHTML = additionalInfo && /^<p>.*<\/p>$/.test(additionalInfo)
  const formatDateFunction = showTime ? intlFormatDateTime : intlFormatDate

  return (
    <span className=" d-inline-flex gap-2">
      {formatDateFunction(data)}
      {additionalInfo && (
        <PopoverCustom
          show={show}
          setShow={setShow}
          className="justify-content-center d-flex p-0 border-0 bg-transparent"
          buttonSize="sm"
          placement="top-start"
          popoverContent={
            <div className="d-flex flex-column">
              <div className="w-100 bg-body-secondary p-2 rounded-top-2">
                <b>{t("Global.attributes.additionalInformation")}</b>
              </div>
              {isHTML ? (
                <div
                  className="p-m-0 p-2"
                  dangerouslySetInnerHTML={{
                    __html: additionalInfo,
                  }}
                />
              ) : (
                <div className="p-2">{additionalInfo}</div>
              )}
              {!isHTML && (
                <div className="d-flex justify-content-end">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-copiar`}>
                        {copyContent
                          ? t("Global.actions.copied")
                          : t("Global.actions.copy")}
                      </Tooltip>
                    }
                    onExited={() => setCopyContent(false)}
                  >
                    {
                      <Button
                        variant="white"
                        className="border-0 d-flex p-2"
                        onClick={() => {
                          navigator.clipboard.writeText(additionalInfo)
                          setCopyContent(true)
                        }}
                      >
                        <BsFiles />
                      </Button>
                    }
                  </OverlayTrigger>
                </div>
              )}
            </div>
          }
        >
          <BsFillInfoCircleFill className="text-secondary" />
        </PopoverCustom>
      )}
    </span>
  )
}
