import { paymentsTypes } from "../../../../services/payments.service"
import { useQuery } from "@tanstack/react-query"
import { DropdownInput } from "../../../DropdownInput"
import { Label } from "../Label"
import { Form } from "react-bootstrap"
import { useEffect, useId } from "react"
import { useCountryConfigs } from "../../../../utils/hooks/useCountryConfigs"

export function PaymentTypeInput(props: PaymentTypeInputProps) {
  const { value, onClick, disabled, required, label, additionalContent, kind } =
    props
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()
  const controlId = useId()

  const { data, isLoading, error } = useQuery<PaymentType[]>({
    queryKey: ["paymentsTypes", countryCode, kind],
    queryFn: async () => (await paymentsTypes(kind)).data,
    staleTime: 5 * 60 * 1000,
  })

  useEffect(() => {
    error && console.error(error)
  }, [error])

  return (
    <Form.Group controlId={controlId} className="mb-3">
      <Label data-testid={props["data-testid"] + "-label"} required={required}>
        {label}
      </Label>
      <DropdownInput<PaymentType>
        id={props["data-testid"] + "-input"}
        renderName={(item) => item.name}
        isLoading={isLoading}
        required={required}
        disabled={disabled}
        onClick={onClick}
        options={data ?? []}
        value={value}
        deselectable
        select
      />
      {additionalContent}
    </Form.Group>
  )
}
