import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react"
import { errorExceptionsApi } from "../services/api"
import { useCountryConfigs } from "../utils/hooks/useCountryConfigs"
import { useNavigate } from "react-router-dom"
import { t } from "i18next"
import {
  getConfigsExternalRecolocation,
  getStockItemsExternalRecolocation,
} from "../services/repurposal.service"
import { externalPaymentsTypes } from "../services/payments.service"
import { useToast } from "../utils/hooks/useToast"
import { AxiosError } from "axios"

interface ExternalRecolocationContextTypes {
  stock: StockResponse | null
  buyerFormalization: (value: BuyerFormalization) => Promise<void>
  stockId: number | null
  messageFeedback: string
  success: boolean
  codeRepurposal: string
  setSuccess: Dispatch<SetStateAction<boolean>>
  getCustomersDocumentExternal: (
    document: string,
  ) => Promise<BuyerProposalOrder | null>
  headers: {
    hashedId: string
    featureType: string
    accessCode: string
  }
  externalRecolocation: ({
    code,
    hash,
  }: VerifyCodeProps) => Promise<VerifyCodeResponse | undefined>
  externalConfigs: ExternalConfigsType | undefined
  getPaymentTypes: () => Promise<void>
  paymentTypes: Array<PaymentType> | undefined
  externalUserData: {
    email: string
    phone: string
    code: number
    document: string
    corporateName: string
  }
  features?: {
    stockFreezeMinPrice: boolean
    stockAutomaticDebit: boolean
  } | null
}

interface BuyerFormalization {
  buyerDocument: string
  kind: string
  repurposalItemsAttributes: RepurposalItem[]
  contactAttributes?: ExternalRepurposalContacts
  buyerFreightKindId?: number
  paymentTypeId?: number
}

interface RepurposalItem {
  stockItemId: number
  price: number
  quantity: number
  conversionFactor: number
  totalPrice: number
}

interface ExternalRepurposalContacts {
  email?: string | null
  phone?: string | null
}

interface BuyerProviderTypes {
  children: ReactNode
}

interface VerifyCodeProps {
  code: string
  hash: string
}

interface VerifyCodeResponse {
  accessCode?: string
  authorizationData: string
  featureType: string
  hashedId?: string
  id: number
}

interface DistributionCenter {
  minPrice: string
  name: string
  supplier: {
    address?: {
      postalCode: string
      city: {
        name: string
        state: {
          abbreviation: string
        }
      }
    }
    id: number
    imageFile: string
    name: string
  }
  freightType: {
    id: number
    name: string
  }
  freightKinds?: {
    id: number
    name: "CIF" | "FOB"
  }[]
}

interface Manufacturer {
  name: string
}

interface RepurposalUnit {
  abbreviation: string
  name: string
  validationType: string
}

interface Item {
  gtin: string
  conversionFactor: number
  description: string
  manufacturer: Manufacturer
  repurposalUnit: RepurposalUnit
  thumbImage: string
}

interface StockItem {
  batch: string
  gtin: string
  expirationAt: string
  id: number
  item: Item
  price: string
  pricePerUnit: string
  availableQuantity: string
  quantity: string
  repurposalCount: number
  repurposalMaxPrice: number
}

interface StockResponse {
  active: boolean
  availableTotalPrice: string
  distributionCenter: DistributionCenter
  id: number
  stockItems: StockItem[]
  totalPrice: string
  status?: StockStatus
}

interface BuyerProposalOrder {
  corporateName: string
  document: string
  isBuyer: boolean
  isSupplier: boolean
}

type Error = {
  response: {
    data: {
      error: string
    }
    status: number
  }
}

type ExternalConfigsType = {
  configs: {
    showNegotiatedInfo: string
    freezeMinPrice: string
    timeToExpiration: string
  }
}

export const ExternalRecolocationContext = createContext(
  {} as ExternalRecolocationContextTypes,
)

export default function BuyerProvider({ children }: BuyerProviderTypes) {
  const [stock, setStock] = useState<StockResponse | null>(null)
  const baseURL = import.meta.env.VITE_BASEURL_RUBY
  const [hashedId, setHashedId] = useState("")
  const [codeRepurposal, setCodeRepurposal] = useState("")
  const [accessCode, setAccessCode] = useState("")
  const [stockId, setSockId] = useState<number | null>(null)
  const [success, setSuccess] = useState(false)
  const [messageFeedback, setMessageFeedback] = useState("")
  const i18nextLng = localStorage.getItem("i18nextLng")
  const toast = useToast()

  const [features, setFeatures] = useState({
    stockFreezeMinPrice: false,
    stockAutomaticDebit: false,
  })
  const [customerName, setCustomerName] = useState({
    document: "",
    corporateName: "",
  })
  const [externalUserData, setExternalUserData] = useState({
    email: "",
    phone: "",
    code: 0,
    document: "",
    corporateName: "",
  })

  const {
    countryCode: [, setCountryCode],
  } = useCountryConfigs()
  const [externalConfigs, setExternalConfigs] = useState<ExternalConfigsType>()
  const [paymentTypes, setPaymentTypes] = useState<Array<PaymentType>>()
  const navigate = useNavigate()

  const headers = {
    hashedId: hashedId,
    featureType: "buyer_repurposal",
    accessCode: accessCode,
    "Accept-language": i18nextLng ?? navigator.language,
  }

  async function buyerFormalization(payload: BuyerFormalization) {
    try {
      const { data } = await errorExceptionsApi.post(
        baseURL + "/external/repurposals",
        payload,
        {
          headers,
        },
      )
      setSuccess(true)
      setExternalUserData({
        email: payload?.contactAttributes?.email ?? "",
        phone: payload?.contactAttributes?.phone ?? "",
        code: data.code,
        ...customerName,
      })
      setCodeRepurposal(data.code)
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        toast.danger(e.response?.data.messages[0])
        throw new Error("Error while verifying: " + e.message)
      } else {
        throw e
      }
    }
  }

  async function getCustomersDocumentExternal(document: string) {
    const { data } = await errorExceptionsApi.get(
      "/external/clients/customers/documents/" + document,
      { headers },
    )
    setCustomerName({
      corporateName: data.corporateName,
      document: data.document.replace(/[^\d]/g, ""),
    })

    return data
  }

  async function getPaymentTypes() {
    const { data } = await externalPaymentsTypes(headers)
    setPaymentTypes(data)
  }

  async function externalRecolocation({ code, hash }: VerifyCodeProps) {
    setHashedId(hash)
    setAccessCode(code)
    try {
      const configResponse = await getConfigsExternalRecolocation(hash, code)
      const configsData = configResponse
      setExternalConfigs(configsData)
      const context =
        configsData.configs.showNegotiatedInfo === "true"
          ? "?context=negotiated_info_config"
          : " "

      const stockResponse = await getStockItemsExternalRecolocation(
        hash,
        code,
        context,
      )

      const stockData = stockResponse

      if (stockData) {
        setSockId(stockData.id)
        setStock(stockData)
        setFeatures(stockData.features)
        navigate(
          `/recolocacao/${stockData.distributionCenter.supplier.countryCode}/${hash}`,
        )
        setCountryCode(stockData.distributionCenter.supplier.countryCode)
      }

      return stockData
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error = e as unknown as Error
        if (error.response?.status === 401) {
          setMessageFeedback(error.response.data.error)
        } else {
          setMessageFeedback(t("ExternalRepurposal.alerts.error.stockInvalid")!)
        }
        throw new Error("Erro ao inicializar: " + error)
      } else {
        console.error(e)
      }
    }
  }

  return (
    <ExternalRecolocationContext.Provider
      value={{
        codeRepurposal,
        externalUserData,
        features,
        buyerFormalization,
        setSuccess,
        success,
        messageFeedback,
        stock,
        stockId,
        getCustomersDocumentExternal,
        headers,
        externalRecolocation,
        externalConfigs,
        getPaymentTypes,
        paymentTypes,
      }}
    >
      {children}
    </ExternalRecolocationContext.Provider>
  )
}
