import { useTranslation } from "react-i18next"
import { useInfiniteQuery } from "@tanstack/react-query"
import { invoiceList } from "@services"
import { useState } from "react"
import { AxiosError } from "axios"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { BsPlusLg } from "react-icons/bs"
import { useDebounceValue } from "usehooks-ts"
import { BindInvoiceProps } from "./bindedInvoicesTable"
import { DropdownInput } from "../../../../../components/DropdownInput"
import { ButtonSubmit } from "../../../../../components/Buttons/ButtonSubmit"
import { CodeAndCustomersOptions } from "../../../../../components/Form/formComponents/CodeAndCustomersOptions/CodeAndCustomersOptions"

function getNextPageParam(lastPage: SimpleIndexInvoicesQuery) {
  return lastPage.count > lastPage.itemsPerPage * lastPage.pageNumber
    ? lastPage.pageNumber + 1
    : undefined
}

export function BindInvoice(props: BindInvoiceProps) {
  const { t } = useTranslation()
  const {
    originId,
    setSuccess,
    setErrorMessages,
    collapsed,
    repurposalCoreCode,
    invoicesQueryKey,
    bindFunction,
    otherFilters,
    label,
  } = props
  const [invoice, setInvoice] = useState<SimpleIndexInvoice>()
  const [search, setSearch] = useDebounceValue<string>("", 500)
  const [isBinding, setIsBinding] = useState(false)
  const { isLoading, data } = useInfiniteQuery({
    queryKey: [...invoicesQueryKey, search],
    queryFn: getInvoices,
    getNextPageParam: getNextPageParam,
    initialPageParam: 1,
    enabled: !collapsed,
  })

  async function getInvoices({
    pageParam,
  }: {
    pageParam: number
  }): Promise<SimpleIndexInvoicesQuery> {
    return await invoiceList({
      pageNumber: pageParam.toString(),
      itemsPerPage: "20",
      search,
      repurposalCode: repurposalCoreCode,
      ...otherFilters,
    })
  }

  async function bindInvoice() {
    if (!invoice) return
    setIsBinding(true)
    setSuccess(false)
    try {
      await bindFunction(invoice.id, originId)
      setSuccess(true)
      setInvoice(undefined)
    } catch (error) {
      error instanceof AxiosError &&
        error.response?.data.message &&
        setErrorMessages(error.response.data.message)
    } finally {
      setIsBinding(false)
    }
  }

  function renderOptions(invoice: SimpleIndexInvoice) {
    return (
      <CodeAndCustomersOptions
        code={invoice.invoiceNumber}
        firstCustomerLabel={t("Invoice.attributes.issuer.one").toString()}
        firstCustomer={{ ...invoice.supplier, document: invoice.buyerDocument }}
        secondCustomerLabel={t("Invoice.attributes.receiver.one").toString()}
        secondCustomer={{
          ...invoice.buyer,
          document: invoice.supplierDocument,
        }}
      />
    )
  }

  return (
    <Form.Group as="form" controlId="form bind-invoice" className="mb-3">
      {label && (
        <Form.Label className="d-inline-flex align-items-center gap-1">
          {label}
        </Form.Label>
      )}
      <div className="d-flex gap-2">
        <DropdownInput
          isLoading={isLoading}
          options={data?.pages.flatMap(({ invoices }) => invoices) ?? []}
          value={invoice}
          onChange={(event) => setSearch(event.target.value)}
          onClick={(invoice) => setInvoice(invoice)}
          renderName={(invoice) => invoice.invoiceNumber}
          renderOption={renderOptions}
          select
        />
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{t("Global.actions.link")}</Tooltip>}
        >
          <ButtonSubmit
            variant="outline-primary"
            className="d-flex justify-content-center align-items-center"
            disabled={isBinding || !invoice}
            loading={isBinding}
            name={<BsPlusLg />}
            onClick={bindInvoice}
          />
        </OverlayTrigger>
      </div>
    </Form.Group>
  )
}
