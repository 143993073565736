import React, { ReactNode, useEffect, useState } from "react"

import { useParams } from "react-router-dom"
import {
  CountryCode,
  useCountryConfigs,
} from "../../utils/hooks/useCountryConfigs"
import * as Sentry from "@sentry/browser"
import PrivateComponent from "../PrivateComponent"
import { useExternalBuyerApproval } from "../../utils/hooks/useExternalBuyerApproval"
import { t } from "i18next"

interface Props {
  children: ReactNode
}

export default function PrivateIsBuyerRepurposalApproval({ children }: Props) {
  const {
    handleVerificationCode,
    messageFeedback,
    setHashedId,
    setAccessCode,
  } = useExternalBuyerApproval()

  const { country, hashedId } = useParams()
  const [isActive, setIsActive] = useState(false)
  const {
    countryCode: [, setCountryCode],
  } = useCountryConfigs()
  const [isError, setIsError] = useState(false)
  const [inputValues, setInputValues] = useState(["", "", "", ""])
  const [isDisabled, setIsDisabled] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const countryCode: CountryCode = (country as CountryCode) ?? "BR"
    countryCode && setCountryCode(countryCode)
  }, [country])

  useEffect(() => {
    setHashedId(hashedId || "")
  }, [])

  const delay = (amount = 750) =>
    new Promise((resolve) => setTimeout(resolve, amount))

  const verify = async () => {
    const value = inputValues.join("")
    if (value.length !== 4) {
      setIsDisabled(true)
    }
    try {
      setLoading(true)
      setAccessCode(value)
      const response = await handleVerificationCode(value)

      if (response) {
        setIsActive(true)
      }

      await delay()
    } catch (error) {
      setIsError(true)
      Sentry.captureException(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setLoading(true)
      verify()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <PrivateComponent
      handleSubmit={handleSubmit}
      messageFeedback={messageFeedback}
      inputValues={inputValues}
      setInputValues={setInputValues}
      setIsError={setIsError}
      isActive={isActive}
      isDisabled={isDisabled}
      isError={isError}
      loading={loading}
      buttonText={t("ExternalBuyerApproval.attributes.accessRelocation")}
    >
      {children}
    </PrivateComponent>
  )
}
