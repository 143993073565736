import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { CustomLink } from "../../../../../../../components/Customs/CustomLink"
import { BsFiles } from "react-icons/bs"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export function RepurposalCell({
  repurposalCode,
}: Invoice | Payment | { repurposalCode: undefined | string }) {
  const { t } = useTranslation()
  const [copyContent, setCopyContent] = useState(false)

  function handleCopy() {
    navigator.clipboard.writeText(repurposalCode ?? "")
    setCopyContent(true)
    setTimeout(() => setCopyContent(false), 5000)
  }

  return repurposalCode ? (
    <div className="d-flex">
      <CustomLink href={`/recolocacoes/visualizar/${repurposalCode}`}>
        {repurposalCode}
      </CustomLink>
      <OverlayTrigger
        overlay={
          <Tooltip>
            {copyContent
              ? t("Global.actions.copied")
              : t("Global.actions.copy")}
          </Tooltip>
        }
      >
        <Button
          variant=""
          className="border-0 d-flex p-2 text-primary"
          onClick={handleCopy}
        >
          <BsFiles />
        </Button>
      </OverlayTrigger>
    </div>
  ) : (
    "-"
  )
}
