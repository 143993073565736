import {
  BsFillExclamationTriangleFill,
  BsFillSendFill,
  BsSendCheckFill,
} from "react-icons/bs"
import { ActionButton } from "../../../../../components/Buttons/ActionButton"
import { t } from "i18next"
import { deliveryIsLate } from "../../../../Logistics/Deliveries/utils/deliveryIsLate"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { VisualizeButton } from "../../../../../components/Buttons/VisualizeButton/VisualizeButton"

export function BillActionsCell(props: BillActionsCellProps) {
  const {
    bill,
    setShowBillViewModal,
    setShowShareModal,
    setBillId,
    showModalInParams,
  } = props
  const isLate =
    bill.status === "waiting_for_payment" &&
    deliveryIsLate(new Date(), bill.dueIn)
  const isSent =
    bill.billSentAt != null && !isLate && bill.status === "waiting_for_payment"

  return (
    <div className="d-flex gap-3 justify-content-end align-items-center">
      {isLate && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{t("Bills.alerts.lateBill")}</Tooltip>}
        >
          <span>
            <BsFillExclamationTriangleFill
              data-testid={`bill-${bill.id}-late`}
              className="text-danger"
            />
          </span>
        </OverlayTrigger>
      )}
      {isSent && bill.billSentAt && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{t("Bills.alerts.billSent")}</Tooltip>}
        >
          <span>
            <BsSendCheckFill
              data-testid={`bill-${bill.id}-sent`}
              className="text-success"
            />
          </span>
        </OverlayTrigger>
      )}
      <div className="d-flex gap-2 justify-content-end align-items-center">
        <VisualizeButton
          showModalInParams={showModalInParams}
          id={bill.id}
          setId={setBillId}
          setShow={setShowBillViewModal}
          data-testid={`show-bill-${bill.id}`}
          idOption="financialId"
        />
        <ActionButton
          onClick={() => {
            setBillId(bill.id)
            setShowShareModal(true)
          }}
          title={t("Global.attributes.share") as string}
          data-testid={`share-bill-${bill.id}`}
        >
          <BsFillSendFill />
        </ActionButton>
      </div>
    </div>
  )
}
