import React from "react"
import { InvoiceStatusDecorations } from "../DecorationTable"
import { FormatMoney } from "../../../../../../../components/Formats/FormatMoney"

export function InvoicesValueCell(item: Invoice) {
  return (
    <div>
      <InvoiceStatusDecorations item={item}>
        <FormatMoney
          amount={Number(item?.valueCents)}
          isNegative={Number(item?.valueCents) < 0}
        />
      </InvoiceStatusDecorations>
    </div>
  )
}
