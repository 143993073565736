import { AdditionalInfoAndDataCell } from "../../../../../../../components/Cells/AdditionalInfoAndDataCell"

export function InvoicesEmmitedAt(props: { item: Invoice }) {
  const { item } = props

  const data =
    item?.status !== "waiting_for_identification" ? item.emmitedAt : undefined

  return (
    <AdditionalInfoAndDataCell
      data={data}
      additionalInfo={item.aditionalInfo}
    />
  )
}
