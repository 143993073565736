import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../../../components/Form/formComponents/Label"

export function LogisticCodesForms(props: DefaultInvoiceFormsProps) {
  const { invoice, setInvoice } = props
  const { t } = useTranslation()
  return (
    <>
      <Form.Group className="mb-3" controlId="checklist">
        <Label>{t("Invoice.attributes.checkList")}</Label>
        <Form.Control
          type="text"
          placeholder=""
          value={invoice?.checkList}
          onChange={(e) => {
            setInvoice({ ...invoice, checkList: e.target.value })
          }}
          name="checklist"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="tmsNumber">
        <Label>{t("Invoice.attributes.tmsNumber")}</Label>
        <Form.Control
          type="text"
          placeholder=""
          value={invoice?.tmsNumber}
          onChange={(e) => {
            setInvoice({ ...invoice, tmsNumber: e.target.value })
          }}
          name="tmsNumber"
        />
      </Form.Group>
    </>
  )
}
