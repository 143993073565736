import { Form } from "react-bootstrap"
import { RichText } from "../../../../../components/Form/formComponents/RichText"
import {
  BANK_FIELDS_MAP,
  billPaymentTypeDataConfigs,
} from "../../utils/billPartPaymentTypeData"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../components/Form/formComponents/Label"
import { PaymentDataInput } from "../../../../../components/Form/formComponents/PaymentDataInput"
import { capitalizeText } from "../../../../../utils/Format"
import { useEffect, useState } from "react"

export function BillPaymentTypeDataInput(props: DefaultBillField) {
  const { bill, setBill } = props
  const { t } = useTranslation()
  const [firstRender, setFirstRender] = useState(true)
  const { isFixed, wasMapped, label, withoutPaymentTypeData } =
    billPaymentTypeDataConfigs(
      bill?.paymentType,
      bill?.repurposalCore?.negotiationKind,
      bill?.kind,
    )

  function handleChange(value?: string) {
    setBill((bill) => ({ ...bill, paymentTypeData: value }))
  }

  function setDefaultValue() {
    if (!bill?.paymentType || bill?.repurposalCore) return

    let paymentTypeData: string | undefined = ""
    if (withoutPaymentTypeData) {
      paymentTypeData = bill?.paymentType?.name
    } else if (!wasMapped) {
      paymentTypeData = BANK_FIELDS_MAP.map(
        (field) => `<p><strong>${t(field)}:</strong> </p>`,
      ).join("")
    }

    setBill((bill) => ({
      ...bill,
      paymentTypeData,
      fixedPaymentTypeData: undefined,
    }))
  }

  useEffect(() => {
    firstRender ? setFirstRender(false) : setDefaultValue()
  }, [bill?.paymentType, bill?.repurposalCore])

  return (
    <>
      {bill?.paymentType &&
        bill.repurposalCore &&
        !withoutPaymentTypeData &&
        (isFixed ? (
          <PaymentDataInput
            label={capitalizeText(t(label))}
            paymentTypeId={bill?.paymentType?.id}
            value={bill?.fixedPaymentTypeData}
            onClick={(paymentTypeData) =>
              setBill((bill) => ({
                ...bill,
                fixedPaymentTypeData: paymentTypeData,
              }))
            }
            required
          />
        ) : (
          <Form.Group
            className="mb-3"
            controlId="bill-part-payment-type-data-input"
          >
            <Label required>{capitalizeText(t(label))}</Label>
            {wasMapped ? (
              <Form.Control
                type="text"
                value={bill?.paymentTypeData}
                onChange={(event) => handleChange(event.target.value)}
                required
              />
            ) : (
              <RichText
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["link"],
                  ],
                }}
                value={bill?.paymentTypeData ?? ""}
                onChange={handleChange}
                id="bill-part-payment-type-data-input"
                required
              />
            )}
          </Form.Group>
        ))}
    </>
  )
}
