import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../Label"
import { DropdownInput } from "../../../DropdownInput"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useCountryConfigs } from "../../../../utils/hooks/useCountryConfigs"
import { useDebounceValue } from "usehooks-ts"
import { billsList } from "../../../../services/bills.service"
import { CodeAndCustomersOptions } from "../CodeAndCustomersOptions/CodeAndCustomersOptions"
import { capitalizeText } from "../../../../utils/Format"
import { FormatMoney } from "../../../Formats/FormatMoney"
import { Info } from "../../../Info"
import { determineSenderAndReceiver } from "../../../../pages/Financial/Bills/utils/determineSenderAndReceiver/determineSenderAndReceiver"

interface SimpleIndexBillQuery {
  count: number
  pageNumber: number
  bills: Array<SimpleIndexBill>
}

function getNextPageParam({ pageNumber, count }: SimpleIndexBillQuery) {
  const ITEMS_PER_PAGE = 20
  return pageNumber < Math.ceil(count / ITEMS_PER_PAGE)
    ? pageNumber + 1
    : undefined
}

export default function BillInput(props: BillInputProps) {
  const { t } = useTranslation()
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()
  const { value, onClick, repurposalCode, suggestionInfo } = props
  const [search, setSearch] = useDebounceValue("", 500)

  async function fetchBills({ pageParam }: { pageParam: number }) {
    if (!repurposalCode) throw new Error("Repurposal code is required")
    const { data } = await billsList({
      pageNumber: pageParam,
      context: "simple_index",
      filters: {
        repurposalCode: repurposalCode,
        billNumber: search,
      },
    })
    return { bills: data.bills, count: data.count, pageNumber: pageParam }
  }

  const { data, isFetching, fetchNextPage } = useInfiniteQuery({
    queryKey: ["bills", "simple_index", countryCode, search, repurposalCode],
    queryFn: fetchBills,
    getNextPageParam,
    initialPageParam: 1,
    enabled: !!repurposalCode,
  })

  return (
    <Form.Group className="mb-3" controlId="bill-input-id">
      <Label className="d-flex gap-1 align-items-center">
        {t("Bills.attributes.billNumber")}
        {suggestionInfo && <Info>{suggestionInfo}</Info>}
      </Label>
      <DropdownInput<Partial<SimpleIndexBill>>
        value={value}
        onClick={onClick}
        onChange={(event) => setSearch(event.target.value)}
        options={
          (data?.pages && data.pages.length > 1
            ? data?.pages.flatMap((page) => page.bills)
            : data?.pages[0].bills) ?? []
        }
        renderName={(bill) => String(bill.id)}
        renderOption={(bill) => (
          <CodeAndCustomersOptions
            code={bill.id}
            firstCustomer={determineSenderAndReceiver(bill).sender}
            firstCustomerLabel={capitalizeText(
              t("Bills.attributes.sender.one"),
            )}
            firstCustomerSize={4}
            secondCustomer={determineSenderAndReceiver(bill).receiver}
            secondCustomerLabel={capitalizeText(
              t("Bills.attributes.receiver.one"),
            )}
            secondCustomerSize={4}
            additionalContent={
              <div className="col-4 text-truncate text-end">
                <strong>{t("Global.attributes.value") + ": "}</strong>
                <FormatMoney
                  amount={bill.amount ? Number(bill.amount) : undefined}
                />
              </div>
            }
          />
        )}
        isLoading={isFetching}
        onScroll={() => fetchNextPage()}
        disabled={!repurposalCode}
        select
      />
    </Form.Group>
  )
}
