import { CustomTable } from "../../../../../components/Customs/CustomTable"
import { BillAmountCell } from "../BillAmountCell"
import { BillStatusCell } from "./BillStatusCell"
import { capitalizeText } from "../../../../../utils/Format"
import { useTranslation } from "react-i18next"
import { BillViewModal } from "../BillViewModal"
import { useState } from "react"
import { RepurposalCell } from "../../../Invoice/components/InvoicesTable/components/InvoiceRepurposalCell/inde"
import { BillShareModal } from "../BillShareModal"
import { AdditionalInfoAndDataCell } from "../../../../../components/Cells/AdditionalInfoAndDataCell"
import { InvociesCustomerCell } from "../../../Invoice/components/InvoicesTable/components/InvoicesCustomerCell"
import { BillActionsCell } from "../BillActionsCell/BillActionsCell"
import { BillAccordionContent } from "../BillAccordionContent"
import { Info } from "../../../../../components/Info"
import { determineSenderAndReceiver } from "../../utils/determineSenderAndReceiver/determineSenderAndReceiver"
import { BillReceiptedAtCell } from "./BillReceiptedCell"

const DEFAULT_COLUMNS: Array<BillsColumns> = [
  "dueIn",
  "receiptedAt",
  "id",
  "repurposalCode",
  "erpNumber",
  "sender",
  "receiver",
  "amount",
  "status",
  "actions",
]

export function BillsTable(props: BillsTableProps) {
  const {
    bills,
    page,
    setPage,
    count,
    columns = DEFAULT_COLUMNS,
    success,
    setSuccess,
    setSuccessMessage,
    totalizers,
    isLoading,
    showModalInParams = true,
  } = props
  const { t } = useTranslation()
  const [bill, setBill] = useState<Bill>()
  const [billId, setBillId] = useState<number>()
  const [showBillViewModal, setShowBillViewModal] = useState<boolean>()
  const [showShareModal, setShowShareModal] = useState<boolean>()

  const billsFields: Record<BillsColumns, Column<Bill>> = {
    dueIn: {
      name: capitalizeText(t("Global.attributes.due")),
      field: (bill) =>
        AdditionalInfoAndDataCell({
          data: bill.dueIn,
          additionalInfo: bill.additionalInfo,
        }),
      "td-data-testid": (bill) => `bill-${bill.id}-due-in`,
      direction: "start",
    },
    receiptedAt: {
      name: capitalizeText(t("Bills.attributes.receipt")),
      field: BillReceiptedAtCell,
      "td-data-testid": (bill) => `bill-${bill.id}-receipted-at`,
      direction: "start",
    },
    id: {
      name: capitalizeText(t("Bills.attributes.billNumber")),
      thClassName: "text-nowrap",
      field: (bill) => bill.id,
      "td-data-testid": (bill) => `bill-${bill.id}-id`,
    },
    sender: {
      name: capitalizeText(t("Bills.attributes.sender.one")),
      field: (bill) => {
        const { sender } = determineSenderAndReceiver(bill)
        return InvociesCustomerCell({
          customer: sender,
          document: sender?.document,
        })
      },
      title: (bill) => {
        const { sender } = determineSenderAndReceiver(bill)
        return sender?.corporateName ?? "-"
      },
      size: 3,
      tdClassName: "limite-caracteres",
      "td-data-testid": (bill) => `bill-${bill.id}-sender`,
    },
    repurposalCode: {
      name: capitalizeText(t("Invoice.attributes.repurposalNumber")),
      thClassName: "text-nowrap",
      field: (bill) =>
        RepurposalCell({ repurposalCode: bill.repurposalCore?.code }),
      "td-data-testid": (bill) => `bill-${bill.id}-repurposal-code`,
    },
    receiver: {
      name: capitalizeText(t("Bills.attributes.receiver.one")),
      field: (bill) => {
        const { receiver } = determineSenderAndReceiver(bill)
        return InvociesCustomerCell({
          customer: receiver,
          document: receiver?.document,
        })
      },
      title: (bill) =>
        bill.repurposalCore?.buyer?.corporateName ??
        bill.repurposalCore.buyerDocument,
      size: 3,
      tdClassName: "limite-caracteres",
      "td-data-testid": (bill) => `bill-${bill.id}-receiver`,
    },
    erpNumber: {
      name: (
        <>
          {t("Global.attributes.erpNumber")}{" "}
          <Info>
            {t("Global.alerts.thisFieldIsSetAccordingToTheRepurposal")}
          </Info>
        </>
      ),
      thClassName: "text-nowrap d-flex align-items-center gap-1",
      field: (bill) => bill.repurposalCore.erpNumber || "-",
    },
    amount: {
      tdClassName: "text-nowrap",
      name: capitalizeText(t("Global.attributes.value")),
      field: BillAmountCell,
      direction: "end",
      "td-data-testid": (bill) => `bill-${bill.id}-amount`,
    },
    status: {
      thClassName: "ps-5",
      tdClassName: "ps-5",
      name: capitalizeText(t("Global.attributes.status")),
      field: (bill) => (
        <BillStatusCell
          bill={bill}
          setSuccess={setSuccess}
          setSuccessMessage={setSuccessMessage}
        />
      ),
      "td-data-testid": (bill) => `bill-${bill.id}-status-${bill.status}`,
    },
    actions: {
      name: t("Global.actions.actions"),
      direction: "end",
      field: (item) => (
        <BillActionsCell
          bill={item}
          setShowBillViewModal={setShowBillViewModal}
          setShowShareModal={setShowShareModal}
          setBillId={setBillId}
          showModalInParams={showModalInParams}
        />
      ),
      "td-data-testid": (bill) => `bill-${bill.id}-actions`,
    },
  }

  const tableColumns: Array<Column<Bill>> = []
  columns.forEach((selected) => {
    tableColumns.push(billsFields[selected as BillsColumns])
  })

  return (
    <>
      <BillShareModal
        billId={billId}
        setBillId={setBillId}
        bill={bill}
        setBill={setBill}
        show={showShareModal}
        setShow={setShowShareModal}
        setSuccess={setSuccess}
        setSuccessMessage={setSuccessMessage}
      />
      <BillViewModal
        billId={billId}
        setBillId={setBillId}
        show={showBillViewModal}
        setShow={setShowBillViewModal}
        success={success}
        setSuccess={setSuccess}
        setSuccessMessage={setSuccessMessage}
        showModalInParams={showModalInParams}
      />
      <CustomTable
        items={bills}
        columns={tableColumns}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        itemsPerPage={20}
        totalItems={count}
        variant="light"
        itemKey={(bill) => bill.id}
        totalizers={totalizers}
        accordion
        accordionBody={BillAccordionContent}
      />
    </>
  )
}
