import { useTranslation } from "react-i18next"
import { LinkToRepurposal } from "../LinkToRepurposal"
import { FormatMoney } from "../../../../../../../components/Formats/FormatMoney"
import { FinancialCopyLine } from "../../../../../components/FinancialCopyLine"

export function InvoiceCopyCard(props: InvoiceCopyCardProps) {
  const { invoice, isLoading } = props
  const { t } = useTranslation()

  return (
    <>
      <p className="mb-1">{t("Invoice.action.copyInvoiceData")}</p>
      <div className="p-3 text-warning-emphasis bg-warning-subtle border border-warning-subtle rounded-3">
        <FinancialCopyLine
          label={`${t("Invoice.invoice.abbr")}`}
          value={
            <>
              {invoice?.invoiceNumber}
              {invoice?.id && (
                <span>
                  {" ("}
                  <a
                    href={`/financial/invoices?financialId=${invoice.id}`}
                    className="d-inline"
                    target="_blank"
                    rel="noreferrer"
                    data-testid="invoice-copy-card-pdf"
                  >
                    {t("Global.actions.visualize")}
                  </a>
                  {")"}
                </span>
              )}
            </>
          }
          isLoading={isLoading}
          data-testid="invoice-copy-card-number"
        />
        <FinancialCopyLine
          label={`${t("Global.attributes.value")}`}
          value={FormatMoney({
            isNotJSX: true,
            amount:
              Number(invoice?.valueCents) *
              (invoice?.kind === "return" ? -1 : 1),
          })}
          isLoading={isLoading}
          data-testid="invoice-copy-card-value"
        />
        <FinancialCopyLine
          label={`${t("Invoice.attributes.issuer.one")}`}
          value={invoice?.supplier?.corporateName ?? "-"}
          isLoading={isLoading}
          data-testid="invoice-copy-card-issuer"
        />
        <FinancialCopyLine
          label={`${t("Invoice.attributes.receiver.one")}`}
          value={invoice?.buyer?.corporateName ?? "-"}
          isLoading={isLoading}
          data-testid="invoice-copy-card-receiver"
        />
        <FinancialCopyLine
          label={`${t("Invoice.attributes.repurposalNumber")}`}
          value={
            invoice?.repurposalCode ? (
              <LinkToRepurposal {...invoice} className="d-inline" />
            ) : (
              "-"
            )
          }
          isLoading={isLoading}
          data-testid="invoice-copy-card-repurposal-number"
        />
        <FinancialCopyLine
          label={`${t("StockManagement.attributes.stockGrouping.one")}`}
          value={
            <>{invoice?.repurposalCore?.stock.stockGrouping.name ?? "-"}</>
          }
          isLoading={isLoading}
          data-testid="invoice-copy-card-repurposal-stock-grouping"
        />
        <FinancialCopyLine
          label={`${t("Logistics.attributes.chosenFreight")}`}
          value={
            invoice?.repurposalCore?.repurposalLogistic?.freightKind?.name ??
            "-"
          }
          isLoading={isLoading}
          data-testid="invoice-copy-card-repurposal-freight-kind"
        />
        <FinancialCopyLine
          value={
            <>
              {invoice?.pdf && (
                <a
                  href={invoice?.pdf}
                  className="d-inline"
                  target="_blank"
                  rel="noreferrer"
                  data-testid="invoice-copy-card-pdf"
                >
                  {t("Global.actions.access")} PDF
                </a>
              )}
              {invoice?.pdf && invoice?.xml && <span>{" | "}</span>}
              {invoice?.xml && (
                <a
                  href={invoice?.xml}
                  className="d-inline"
                  target="_blank"
                  rel="noreferrer"
                  data-testid="invoice-copy-card-xml"
                >
                  {t("Global.actions.access")} XML
                </a>
              )}
            </>
          }
          isLoading={isLoading}
          data-testid="invoice-copy-card-pdf-xml"
        />
      </div>
    </>
  )
}
