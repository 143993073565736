import { useTranslation } from "react-i18next"
import { ShareLinkModal } from "../../../../../components/Modal/ShareLinkModal"
import { Button } from "react-bootstrap"
import { BsEnvelopeFill, BsWhatsapp } from "react-icons/bs"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { getBill } from "../../../../../services/bills.service"
import { captureException } from "@sentry/react"
import { AxiosError, HttpStatusCode } from "axios"
import { BillSendMail } from "../BillSendMail"
import { BillPhoneModal } from "../BillPhoneModal"
import { getSupplier } from "../../../../../services/customer.service"
import { viewRepurposal } from "../../../../../services/repurposal.service"
import { useQuery } from "@tanstack/react-query"

export function BillShareModal(
  props: DefaultBillModalProps & {
    billId?: number
    setBillId?: Dispatch<SetStateAction<number | undefined>>
  },
) {
  const {
    bill,
    setBill,
    billId,
    setBillId,
    show,
    setShow,
    setSuccess,
    setSuccessMessage,
  } = props
  const { t } = useTranslation()
  const [showMail, setShowMail] = useState<boolean>()
  const [showMessageModal, setShowMessageModal] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>()
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const link = bill?.externalAccessControl?.hashedId
    ? `${import.meta.env.VITE_AUTH_REDIRECT}/payment/${
        bill?.externalAccessControl?.hashedId
      }/`
    : undefined

  const {
    data: contacts,
    isLoading: contactsAreLoading,
    error: contactsError,
  } = useQuery({
    queryKey: ["customer", bill?.repurposalCore?.buyer.id],
    queryFn: getContacts,
    staleTime: 5 * 60 * 1000,
    enabled: !!bill?.repurposalCore?.buyer.id && (showMail || showMessageModal),
  })

  const {
    data: repurposalContact,
    isLoading: repurposalContactIsLoading,
    error: repurposalContactError,
  } = useQuery({
    queryKey: ["repurposalCore", bill?.repurposalCore?.id],
    queryFn: getRepurposalContact,
    staleTime: 5 * 60 * 1000,
    enabled: !!bill?.repurposalCore?.code && (showMail || showMessageModal),
  })

  async function getContacts(): Promise<Array<Contact>> {
    setErrorMessages([])
    const { data } = await getSupplier(String(bill?.repurposalCore?.buyer.id), {
      context: "contacts",
    })
    return data.contacts
  }

  async function getRepurposalContact(): Promise<Contact> {
    setErrorMessages([])
    const data = await viewRepurposal(
      String(bill?.repurposalCore?.code),
      undefined,
      "contact_and_payment_type",
    )
    return data.contact
  }

  function handleClose() {
    setBill(undefined)
    setBillId?.(undefined)
  }

  async function findBillPart() {
    setIsLoading(true)
    setErrorMessages([])
    try {
      const { data } = await getBill(billId)
      setBill(data)
    } catch (error) {
      console.error(error)
      captureException(error)
      if (error instanceof AxiosError) {
        const { response } = error
        response?.status === HttpStatusCode.InternalServerError
          ? setErrorMessages([t("Global.alerts.internalServerError")])
          : setErrorMessages(response?.data.messages)
      }
    } finally {
      setIsLoading(false)
    }
  }

  function showError(error: Error) {
    console.error(error)
    captureException(error)
    error instanceof AxiosError &&
      error.response?.data?.messages &&
      setErrorMessages(error.response?.data?.messages)
  }

  useEffect(() => {
    repurposalContactError && showError(repurposalContactError)
    contactsError && showError(contactsError)
  }, [repurposalContactError, contactsError])

  useEffect(() => {
    show && billId && findBillPart()
  }, [billId, show])

  return (
    <>
      <BillPhoneModal
        bill={bill}
        setBill={setBill}
        show={showMessageModal}
        setShow={setShowMessageModal}
        link={link}
        contacts={contacts}
        contactsAreLoading={contactsAreLoading}
        repurposalContact={repurposalContact}
        repurposalContactIsLoading={repurposalContactIsLoading}
        setSuccess={setSuccess}
        setSuccessMessage={setSuccessMessage}
      />
      <BillSendMail
        show={showMail}
        setShow={setShowMail}
        bill={bill}
        setBill={setBill}
        setSuccess={setSuccess}
        setSuccessMessage={setSuccessMessage}
        contacts={contacts}
        contactsAreLoading={contactsAreLoading}
        repurposalContact={repurposalContact}
        repurposalContactIsLoading={repurposalContactIsLoading}
      />
      <ShareLinkModal
        show={show}
        setShow={setShow}
        onClose={handleClose}
        isLoading={isLoading}
        customErrors={errorMessages}
        title={t("BillParts.actions.shareBill")}
        accessCode={bill?.externalAccessControl?.accessCode}
        link={link}
        data-testid="share-bill-part"
        additionalActions={
          <>
            <Button
              variant="outline-primary"
              className="d-flex justify-content-center align-items-center"
              onClick={() => {
                setShow(false)
                setShowMessageModal(true)
              }}
              disabled={isLoading}
            >
              <BsWhatsapp className="my-1" />
            </Button>
            <Button
              variant="outline-primary"
              className="d-flex justify-content-center align-items-center"
              onClick={() => {
                setShow(false)
                setShowMail(true)
              }}
              disabled={isLoading}
            >
              <BsEnvelopeFill className="my-1" />
            </Button>
          </>
        }
      />
    </>
  )
}
