import { Fragment, useEffect, useState } from "react"
import { Button, Modal, Spinner } from "react-bootstrap"
import { CustomAlert } from "../../Customs/CustomAlert"
import { ActionButton } from "../../Buttons/ActionButton"
import { BsFillTrashFill } from "react-icons/bs"
import * as Sentry from "@sentry/browser"

import { t } from "i18next"

interface IDeleteItem {
  onDelete: () => Promise<void>
  title?: string
  success?: boolean
  loading?: boolean
  text?: string
  name?: string
  feedbackError?: {
    show: boolean
    message: string
  }
  useIcon?: boolean
  customIcon?: React.ReactNode
  buttonText?: string
  testid?: string
  useTooltip?: boolean
  disabled?: boolean
  buttonClassName?: string
  useButton?: boolean
  showModal?: boolean
  setShowModal?: (value: boolean) => void
  onOpenModal?: () => void
  closeOnSuccess?: boolean
  buttonVariant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "outline-primary"
    | "outline-secondary"
    | "outline-success"
    | "outline-danger"
    | "outline-warning"
    | "outline-info"
    | "outline-light"
    | "outline-dark"
}

export function DeleteItem({
  name,
  onDelete,
  text,
  loading,
  feedbackError,
  title,
  useIcon = true,
  useTooltip = true,
  customIcon,
  buttonText,
  buttonVariant = "outline-primary",
  buttonClassName,
  testid,
  disabled,
  showModal = false,
  setShowModal,
  useButton = true,
  onOpenModal,
  closeOnSuccess = true,
}: IDeleteItem) {
  const [show, setShow] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)

  const showValidation = showModal && setShowModal

  const onClose = () => {
    if (showValidation) {
      setShowModal(false)
    } else {
      setShow(false)
    }
  }
  const open = () => {
    onOpenModal && onOpenModal()
    if (showValidation) {
      setShowModal(true)
    } else {
      setShow(true)
    }
  }

  useEffect(() => {
    if (feedbackError) {
      setShowFeedback(feedbackError.show)
    }
  }, [feedbackError && feedbackError.show])

  const actionButtonContent = useTooltip
    ? buttonText || (t("Global.actions.remove") as string)
    : undefined

  const handleDelete = async () => {
    try {
      await onDelete()
      closeOnSuccess && onClose()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  return (
    <Fragment>
      {useButton &&
        (!buttonText && useIcon ? (
          <ActionButton
            title={actionButtonContent}
            onClick={open}
            disabled={disabled}
            variant={buttonVariant}
            data-testid={`delete-item-${testid}`}
            className={buttonClassName}
          >
            {customIcon || <BsFillTrashFill />}
          </ActionButton>
        ) : (
          <Button
            onClick={open}
            disabled={disabled}
            variant={buttonVariant}
            data-testid={`delete-item-${testid}`}
          >
            {actionButtonContent}
          </Button>
        ))}

      <Modal show={showValidation ? showModal : show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomAlert
            onClose={() => {
              setShowFeedback(false)
            }}
            variant="danger"
            show={showFeedback}
            dismissible={true}
          >
            {feedbackError && feedbackError.message}
          </CustomAlert>
          <span dangerouslySetInnerHTML={{ __html: text as string }} />{" "}
          <b dangerouslySetInnerHTML={{ __html: name as string }} /> ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleDelete}
            disabled={loading}
            data-testid="delete-item-yes"
          >
            {loading ? <Spinner size="sm" /> : t("Global.actions.yesRemove")}
          </Button>
          <Button variant="secondary" type="button" onClick={onClose}>
            {t("Global.actions.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}
