import { useTranslation } from "react-i18next"
import { CustomModalItemsView } from "../../../../../components/Customs/CustomModalItensView"
import { capitalizeText } from "../../../../../utils/Format"
import { FormatMoney } from "../../../../../components/Formats/FormatMoney"
import { stockKindsMap } from "../../../../../utils/stockKindsMap"
import { useCountryConfigs } from "../../../../../utils/hooks/useCountryConfigs"
import { formatDocument } from "../../../../../utils/Format/formatDocument"
import { LinkToRepurposal } from "../../../Invoice/components/invoiceModalView/components/LinkToRepurposal"
import { AdditionalInfoAndDataCell } from "../../../../../components/Cells/AdditionalInfoAndDataCell"
import { generateBillFullStatus } from "../../utils/generateStatusPopoverValue"
import { camelCase } from "lodash"
import { AuditField } from "../../../../../components/Summaries/AuditField"
import { Info } from "../../../../../components/Info"
import { CustomerSummary } from "../../../../../components/Summaries/CustomerSummary/CustomerSummary"
import {
  intlFormatDate,
  intlFormatDateTime,
} from "../../../../../utils/timezoneHelpers"
import { determineSenderAndReceiver } from "../../utils/determineSenderAndReceiver/determineSenderAndReceiver"

export function BillViewAttributes(props: BillViewAttributesProps) {
  const { bill, isLoading } = props
  const { t } = useTranslation()
  const {
    countryCode: [countryCode],
    findPluralRule,
  } = useCountryConfigs()

  const document = t(`Global.attributes.document.${countryCode}`)
  const corporateName = t("Global.attributes.businessName")

  const columns: ModalViewItem<Bill>[][] = [
    [
      {
        label: capitalizeText(t("Bills.attributes.billNumber")),
        content: (bill) => (
          <strong className="fw-bold fs-5 text-dark">{bill.id}</strong>
        ),
        field: "id",
      },
      {
        label: capitalizeText(t("Global.attributes.dueOn")),
        content: (bill) =>
          AdditionalInfoAndDataCell({
            data: bill.dueIn,
            additionalInfo: bill.additionalInfo,
          }),
        field: "dueIn",
      },
      {
        label: t("Bills.attributes.receiptedAt"),
        content: (bill) => intlFormatDate(bill.receiptedAt),
      },
      {
        label: capitalizeText(t("Global.attributes.createdAt")),
        content: (bill) => intlFormatDateTime(bill.createdAt),
        field: "createdAt",
      },
      {
        label: t("Receipts.attributes.paymentMethod.full"),
        content: (bill) => bill.paymentType?.name,
        field: "paymentType",
      },
      {
        label: capitalizeText(t("Global.attributes.amount")),
        content: (bill) =>
          FormatMoney({ amount: Number(bill.amount), isNotJSX: true }),
        field: "amount",
      },
    ],
    [
      {
        label: capitalizeText(t("Global.attributes.category")),
        content: (bill) => {
          const checkins = bill.repurposalCore?.stock.checkins
          const stockKind =
            checkins && checkins.length > 0
              ? checkins[checkins.length - 1].kind
              : ""
          return t([
            `StockCheckin.attributes.${
              stockKind ? stockKindsMap[stockKind] : ""
            }`,
            "Global.alerts.NotIdentified",
          ])
        },
      },
      {
        label: capitalizeText(t("Global.attributes.status")),
        content: (bill) => {
          const { status } = generateBillFullStatus(t, bill)
          return t(`Bills.attributes.status.${camelCase(status)}.full`)
        },
        field: "status",
      },
      {
        label: t("Global.attributes.type"),
        content: (bill) => t(`Bills.attributes.kind.${bill.kind}`),
        field: "kind",
      },
      {
        label: t("Global.attributes.createdBy"),
        content: (bill) => AuditField(bill.auditCreate),
        field: "auditCreate",
      },
      {
        label: t("Global.attributes.lastModifiedBy"),
        content: (bill) => AuditField(bill.auditLastUpdate),
        field: "auditLastUpdate",
      },
      {
        label: t(`Global.attributes.tag.${findPluralRule(bill?.tags?.length)}`),
        content: (bill) =>
          bill.tags?.map(({ description }) => description).join(", ") ?? "-",
        field: "tags",
      },
      {
        label: t(
          `Invoice.attributes.invoiceViewNumber.${findPluralRule(bill?.invoices?.length)}`,
        ),
        content: (bill) =>
          bill.invoices.map(({ numInvoice }) => numInvoice).join(", ") ?? "-",
        "data-testid": "invoice-numbers",
      },
    ],
    [
      {
        label: (
          <span className="text-secondary fs-5">
            {capitalizeText(t("Repurposal.repurposal.one"))}
          </span>
        ),
      },
      {
        label: capitalizeText(t("Global.attributes.repurposalNumber.abbr")),
        content: ({ repurposalCore }) => (
          <LinkToRepurposal repurposalCode={repurposalCore?.code} />
        ),
        field: "repurposalCore",
        "data-testid": "code",
      },
      {
        label: (
          <>
            {t("Global.attributes.erpNumber")}{" "}
            <Info>
              {t("Global.alerts.thisFieldIsSetAccordingToTheRepurposal")}
            </Info>
          </>
        ),
        content: (bill) => bill.repurposalCore.erpNumber || "-",
      },
      {
        label: capitalizeText(
          t("Global.attributes.repurposalResponsible.full"),
        ),
        content: (bill) => bill.repurposalCore?.responsibleBy?.name ?? "-",
        field: "repurposalCore",
        "data-testid": "responsible-name",
      },
      {
        label: (
          <span className="text-secondary fs-5 pt-4">
            {capitalizeText(t("Global.attributes.intermediation"))}
          </span>
        ),
      },
      {
        label: capitalizeText(t("Global.attributes.dealKind")),
        content: ({ repurposalCore: { negotiationKind } }) =>
          t(
            `Reports.KPIs.attributes.${
              negotiationKind === "gooxxy_to_buyer"
                ? "viaDistribution"
                : "viaService"
            }`,
          ),
      },
      ...(bill?.repurposalCore.negotiationKind === "gooxxy_to_buyer"
        ? [
            {
              label: `${t("Repurposal.attributes.originRepurposal")}`,
              content: ({ repurposalCore }: Bill) => (
                <LinkToRepurposal
                  repurposalCode={repurposalCore?.originRepurposalCore?.code}
                />
              ),
            },
            {
              label: `${t("Global.attributes.businessName")} (${t("Global.attributes.supplier.one")})`,
              content: ({
                repurposalCore: {
                  stock: { checkins },
                },
              }: Bill) => checkins?.[0]?.supplier.corporateName ?? "-",
            },
            {
              label: `${t([
                `Global.attributes.document.${countryCode}`,
                "Global.attributes.document.document",
              ])} (${t("Global.attributes.supplier.one")})`,
              content: ({
                repurposalCore: {
                  stock: { checkins },
                },
              }: Bill) => {
                const document = checkins?.[0]?.supplier.document
                return document ? formatDocument(document) : "-"
              },
            },
          ]
        : []),
    ],
    [
      {
        label: (
          <span className="text-secondary fs-5">
            {capitalizeText(t("Bills.attributes.sender.one"))}
          </span>
        ),
      },
      {
        label: `${corporateName} / ${document}`,
        content: (bill) => {
          const { sender } = determineSenderAndReceiver(bill)
          return CustomerSummary({ ...sender, corporateName: sender?.name })
        },
        "data-testid": "bill-sender",
      },
      {
        label: (
          <span className="text-secondary fs-5 pt-4">
            {capitalizeText(t("Global.attributes.externalAccess"))}
          </span>
        ),
      },
      {
        label: t("Global.attributes.accessCode"),
        content: (bill) => (
          <span>
            <span data-testid="bill-sumary-field-access-code">
              {bill.externalAccessControl?.accessCode}
            </span>
            {" ("}
            <a
              target="_blank"
              rel="noreferrer"
              className="d-inline"
              href={`${import.meta.env.VITE_AUTH_REDIRECT}/payment/${
                bill?.externalAccessControl?.hashedId
              }/`}
              data-testid="bill-sumary-field-access-link"
            >
              {capitalizeText(t("Global.actions.access"))}
            </a>
            {")"}
          </span>
        ),
        field: "externalAccessControl",
      },
    ],
    [
      {
        label: (
          <span className="text-secondary fs-5">
            {capitalizeText(t("Bills.attributes.receiver.one"))}
          </span>
        ),
      },
      {
        label: `${corporateName} / ${document}`,
        content: (bill) => {
          const { receiver } = determineSenderAndReceiver(bill)
          return CustomerSummary({ ...receiver, document: receiver?.document })
        },
        "data-testid": "repurposal-buyer",
      },
    ],
  ]

  return (
    <CustomModalItemsView
      item={bill}
      columns={columns}
      isLoading={isLoading}
      data-testid="bill-sumary"
    />
  )
}
