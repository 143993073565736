import { Dropdown } from "react-bootstrap"

type CustomDropdown = {
  children?: React.ReactNode
  name: React.ReactNode
  variant?: string
  className?: string
  dropdownClassName?: string
  "data-testid"?: string
  disabled?: boolean
  align?: "end" | "start"
  menuClassName?: string
}
export function CustomDropdown(props: CustomDropdown) {
  const {
    name,
    variant,
    children,
    className = "",
    dropdownClassName = "",
    disabled,
    align,
    menuClassName = "",
  } = props
  return (
    <Dropdown className={` ${dropdownClassName}`}>
      <Dropdown.Toggle
        disabled={disabled}
        variant={variant}
        className={className}
        style={{ maxWidth: "220px" }}
        data-testid={props["data-testid"]}
      >
        {name}
      </Dropdown.Toggle>
      <Dropdown.Menu className={menuClassName} align={align}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  )
}
