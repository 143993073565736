import { VisualizeButton } from "../../../../../../components/Buttons/VisualizeButton/VisualizeButton"

export function InvoicesActionCell(props: InvoicesActionsCell) {
  const { invoice, setInvoiceId, showModalInParams, customAdditionalInfo } =
    props

  return (
    <div className="d-flex justify-content-end gap-2">
      <VisualizeButton
        showModalInParams={showModalInParams}
        id={invoice.id}
        setId={setInvoiceId}
        data-testid={`showInvoice${invoice?.invoiceNumber}`}
        idOption="financialId"
      />
      {customAdditionalInfo?.(invoice)}
    </div>
  )
}
