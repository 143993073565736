import { FormGroup, FormLabel } from "react-bootstrap"
import { DropdownInput } from "../../../../components/DropdownInput"
import { t } from "i18next"

interface CheckinKindInputProps {
  kind?: string
  setFormData: React.Dispatch<React.SetStateAction<CheckinModalFormData>>
  setKind: React.Dispatch<React.SetStateAction<string | undefined>>
}

export default function CheckinKindInput(props: CheckinKindInputProps) {
  const { kind, setFormData, setKind } = props

  const kindOptions = ["raw_materials", "finished_goods"]

  function convertKind(id: string | undefined) {
    const name =
      id === "raw_materials"
        ? t("StockCheckin.attributes.rawMaterial")
        : t("StockCheckin.attributes.finished")
    return name as string
  }

  function selectKind(selected: string) {
    setFormData((prevData) => ({
      ...prevData,
      productType: selected,
    }))
    setKind(selected)
  }
  return (
    <>
      <FormGroup className="mb-3">
        <FormLabel>{t("Global.attributes.category")}</FormLabel>
        <DropdownInput
          select
          value={kind || undefined}
          options={kindOptions}
          renderName={convertKind}
          renderTitle={convertKind}
          onClick={(selected) => selectKind(selected)}
          id="productType"
        />
      </FormGroup>
    </>
  )
}
