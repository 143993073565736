import { getCountryCode } from "../../hooks/useCountryConfigs/utils"

const masks: Record<
  string,
  {
    set: (value: string) => string
    clean: (value: string) => string
  }
> = {
  BR: {
    set: (value: string) => {
      return value
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1")
    },
    clean: (value: string) => value?.replace(/\D+/g, ""),
  },
  CO: {
    set: (value: string) => {
      if (value.length < 10) {
        return value
          .replace(/(\d{8})(\d)/, "$1-$2")
          .replace(/(-\d{1})\d+?$/, "$1")
      } else if (value.length === 10) {
        return value
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1-$2")
          .replace(/(-\d{1})\d+?$/, "$1")
      } else if (value.length === 9 || value.length === 11) {
        const prefix = value.slice(0, 1)
        const rest = value.slice(1, 7)
        const lastDigits = value.slice(7)

        if (value.length === 9) {
          return `${prefix}.${rest}-${lastDigits}`
        } else if (value.length === 11) {
          return `${prefix}.${rest}.${lastDigits.slice(0, 3)}-${lastDigits.slice(3)}`
        }
      }
      return value
    },
    clean: (value: string) => value?.replace(/\D+/g, ""),
  },
  MX: {
    set: (value: string) => value.replace(/(\w{13})\w+?$/, "$1"),
    clean: (value: string) => value.replace(/[^0-9a-zA-Z]/g, ""),
  },
  default: {
    set: (value: string) => value,
    clean: (value: string) => value?.replace(/\D+/g, ""),
  },
}

export function formatDocument(value?: string, onlyClean?: boolean) {
  const countryCode = getCountryCode()
  if (!value) return "-"

  const selectedMask = masks[countryCode] || masks.default
  const cleanedDocument = selectedMask.clean(value)
  return onlyClean ? cleanedDocument : selectedMask.set(cleanedDocument)
}
