import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { ButtonSubmit } from "../../../../../components/Buttons/ButtonSubmit"
import { sendBillMail } from "../../../../../services/bills.service"
import { captureException } from "@sentry/react"
import { AxiosError } from "axios"
import { MultiErrorAlerts } from "../../../../../components/MultiErrorAlerts"
import { CustomAlert } from "../../../../../components/Customs/CustomAlert"
import { DropdownInput } from "../../../../../components/DropdownInput"

export function BillSendMail(
  props: DefaultBillModalProps & {
    repurposalContact: Contact | undefined
    repurposalContactIsLoading: boolean
    contacts: Array<Contact> | undefined
    contactsAreLoading: boolean
  },
) {
  const {
    show,
    setShow,
    bill,
    setBill,
    setSuccess,
    setSuccessMessage,
    contacts,
    contactsAreLoading,
    repurposalContact,
    repurposalContactIsLoading,
  } = props
  const { t } = useTranslation()
  const [sandMailCheck, setSandMailCheck] = useState<boolean>(true)
  const [contact, setContact] = useState<Contact>()
  const [mails, setMails] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>()
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const [showWarning, setShowWarning] = useState<boolean>(!!bill?.sentMailAt)
  const [validated, setValidated] = useState(false)
  const originMail = repurposalContact?.email
  const sandMailIsRequired = !!contact

  function handleClose() {
    setShow(false)
    setBill(undefined)
    setSandMailCheck(true)
    setMails(undefined)
    setIsLoading(undefined)
    setErrorMessages([])
    setContact(undefined)
    setValidated(false)
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (event.currentTarget.checkValidity()) {
      setIsLoading(true)
      setErrorMessages([])
      try {
        let sandMail = false
        const additionalMail = mails?.split(";") ?? []
        if (sandMailCheck && contact?.email) {
          sandMail = contact.email === originMail
          !sandMail && additionalMail?.push(contact.email)
        }

        await sendBillMail({
          billId: bill?.id,
          sendMailBuyer: sandMail,
          sendAdditionalMailAt: additionalMail?.join(";"),
        })
        setSuccess(true)
        setSuccessMessage(t("Email.alerts.sendEmail.success"))
        handleClose()
      } catch (error) {
        console.error(error)
        captureException(error)
        error instanceof AxiosError &&
          error.response?.data.message &&
          setErrorMessages([error.response?.data.message])
      } finally {
        setIsLoading(false)
      }
    } else setValidated(true)
  }

  function handleClick(contact: Contact | undefined) {
    setContact(contact)
    setSandMailCheck(!!contact)
  }

  function handleShow() {
    setSuccess(false)
    setSuccessMessage(false)
  }

  useEffect(() => {
    if (show && bill) {
      setShowWarning(!!bill?.sentMailAt)
    }
  }, [show, bill])

  useEffect(() => {
    setContact(contacts?.find((contact) => contact.email === originMail))
    setSandMailCheck(!!originMail)
  }, [repurposalContact])

  return (
    <Modal show={show} onHide={handleClose} onShow={handleShow} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Email.attributes.titleEmail")}</Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          {errorMessages.length > 0 && (
            <MultiErrorAlerts items={errorMessages} />
          )}
          <CustomAlert
            variant="warning"
            onClose={() => setShowWarning(false)}
            dismissible
            show={showWarning}
          >
            {t("Bills.alerts.sendMail")}
          </CustomAlert>
          <CustomAlert
            onClose={() => setValidated(false)}
            variant="danger"
            show={validated}
            dismissible={true}
            data-testid="alertRequiredFields"
          >
            {t("Global.alerts.emptyFields")}
          </CustomAlert>
          <Form.Group className="mb-3">
            <Form.Check
              label={
                <>
                  {t("Email.actions.sendMailTocustomer")}
                  {sandMailIsRequired && (
                    <span className="text-danger"> *</span>
                  )}
                </>
              }
              checked={sandMailCheck}
              onChange={() => setSandMailCheck(!sandMailCheck)}
              id="bill-part-send-mail-to-customer"
              disabled={!contact}
              required={sandMailIsRequired}
            />
            <DropdownInput
              renderName={(contact) => contact.email}
              onClick={handleClick}
              isLoading={contactsAreLoading}
              disabled={repurposalContactIsLoading}
              options={contacts ?? []}
              value={contact}
              deselectable
              required={sandMailCheck}
              select
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="bill-part-additional-emails">
            <Form.Label>{t("Email.attributes.additionalEmails")}</Form.Label>
            <Form.Control
              as="textarea"
              value={mails}
              onChange={(event) => setMails(event.target.value)}
            />
            <small className="text-secondary">
              {t("Email.attributes.placeholderEmail")}
            </small>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            {t("Global.actions.cancel")}
          </Button>
          <ButtonSubmit
            loading={isLoading}
            variant="success"
            type="submit"
            name={t("Global.actions.confirm")}
            disabled={isLoading}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
