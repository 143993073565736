import { MdClose } from "react-icons/md"

export default function TagsView<T extends CatalogTag | Tag>({
  tags,
  setTags,
  tagValue,
}: ITagsView<T>) {
  function onRemove(index: number) {
    const arrayAux = [...tags]
    arrayAux.splice(index, 1)
    setTags(arrayAux)
  }

  return tags?.length ? (
    <div className="d-flex flex-wrap gap-2 w-100 mb-2">
      {tags.map((tag, index) => (
        <div key={tag.id} className="tags-view d-flex align-items-center gap-1">
          <span className="ps-2">{tagValue(tag)}</span>
          <button
            className="border-0 m-0"
            id="button"
            onClick={() => onRemove(index)}
          >
            <MdClose />
          </button>
        </div>
      ))}
    </div>
  ) : null
}
