import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { BsInfoCircleFill } from "react-icons/bs"
import { InfoProps } from "./info"

export function Info(props: InfoProps) {
  const { className = "text-secondary", children, placement = "top" } = props
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip>{children}</Tooltip>}
    >
      <span className="d-inline-flex">
        <BsInfoCircleFill className={className} />
      </span>
    </OverlayTrigger>
  )
}
