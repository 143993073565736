import React, { useEffect, useRef, useState } from "react"
import { Form } from "react-bootstrap"
import { useCountryConfigs } from "../../hooks/useCountryConfigs"

type WeightInputProps = {
  name?: string
  value?: number
  onChange: (weight: number | undefined) => void
  required?: boolean
  disabled?: boolean
  isInvalid?: boolean
  defaultValue?: string
  title?: string
  className?: string
  datatestid?: string
}

export const WeightInput: React.FC<WeightInputProps> = ({
  name,
  value,
  onChange,
  required,
  isInvalid,
  disabled,
  title,
  className,
  defaultValue,
  datatestid,
}) => {
  const { locale } = useCountryConfigs()
  const [inputValue, setInputValue] = useState<string>(
    formatValue(Number(value)),
  )
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setInputValue(formatValue(Number(value)))
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maskRegex = /\D/g
    const rawValue = e.target.value.replace(maskRegex, "")
    const cursorPosition = e.target.selectionStart || 0
    const newValue = rawValue ? Number(rawValue) / 1000 : undefined
    const formattedValue = newValue ? formatValue(newValue) : ""

    setInputValue(formattedValue)
    onChange(newValue)

    const newCursorPosition = calculateCursorPosition(
      cursorPosition,
      e.target.value,
      formattedValue,
    )

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition)
      }
    }, 0)
  }

  function formatValue(value: number): string {
    return isNaN(value)
      ? ""
      : value.toLocaleString(locale, {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        })
  }

  function calculateCursorPosition(
    previousCursorPosition: number,
    previousValue: string,
    newValue: string,
  ): number {
    const previousLength = previousValue.length
    const newLength = newValue.length

    if (newLength > previousLength) {
      return previousCursorPosition + (newLength - previousLength)
    }

    return previousCursorPosition
  }

  return (
    <Form.Control
      ref={inputRef}
      data-testid={datatestid}
      className={className}
      type="text"
      name={name}
      defaultValue={defaultValue}
      disabled={disabled}
      isInvalid={isInvalid}
      value={inputValue ?? ""}
      title={title}
      onChange={handleChange}
      required={required}
      placeholder={formatValue(0)}
    />
  )
}
