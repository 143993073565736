import { ChangeEvent } from "react"
import { Form } from "react-bootstrap"
import { Label } from "../../../../../components/Form/formComponents/Label"
import { useTranslation } from "react-i18next"

export default function InvoiceAutomaticOrderInput(
  props: DefaultInvoiceFormsProps,
) {
  const { t } = useTranslation()
  const { invoice, setInvoice } = props

  return (
    <Form.Group className="mb-3" controlId="crmNumber">
      <Label>{t("Global.attributes.requestNumber")}</Label>
      <Form.Control
        type="number"
        placeholder=""
        name="NPedido"
        value={invoice?.invoiceOrder}
        isInvalid={false}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setInvoice((prev) => ({
            ...prev,
            invoiceOrder: e.target.value,
          }))
        }}
      />
    </Form.Group>
  )
}
