import { ReactNode, useEffect, useState } from "react"
import { t } from "i18next"
import { removeInvoice } from "../../../../../services/invoices.service"
import { InvoiceModalView } from "../invoiceModalView"
import { InvoicesEmmitedAt } from "./components/InvoicesEmmitedAt"
import { InvociesCustomerCell } from "./components/InvoicesCustomerCell"
import { InvoicesStatusCell } from "./components/InvoicesStatusCell"
import { InvoicesValueCell } from "./components/InvoicesValueCell"
import { CustomDeleteModal } from "../../../../../components/Customs/CustomDeleteModal"
import { FormatWeight } from "../../../../../components/Formats/FormatWeight"
import { RepurposalCell } from "./components/InvoiceRepurposalCell/inde"
import { InvoiceStatusDecorations } from "./components/DecorationTable"
import { CustomTable } from "../../../../../components/Customs/CustomTable"
import { intlFormatDate } from "../../../../../utils/timezoneHelpers"
import { InvoicesActionCell } from "./components/InvoicesActionCell"
import { Info } from "../../../../../components/Info"

function FormatWaiting({
  item,
  response,
}: {
  item: Invoice
  response: ReactNode
}) {
  return <>{item.status === "waiting_for_identification" ? "-" : response}</>
}

export function InvoicesTable(props: InvoicesTableProps) {
  const {
    items,
    itemsPerPage,
    page,
    setPage,
    totalItems,
    isLoading,
    totalizers,
    selectedColumnsOptions = [
      "emmitedAt",
      "monthGoalOn",
      "invoiceNumber",
      "repurposalCode",
      "erpNumber",
      "issuer",
      "receiver",
      "netWeight",
      "grossWeight",
      "value",
      "status",
      "actions",
    ],
    invoice,
    setInvoice,
    setSuccess,
    setSuccessMessages,
    customAdditionalInfo,
    variant = "light",
    headersClassName,
    rowsClassName,
    borderInherit,
    tbodyWhite,
    showModalInParams = true,
  } = props
  const [invoiceId, setInvoiceId] = useState<number>()
  const [showModalRemove, setShowModalRemove] = useState(false)
  const [removeSuccess, setRemoveSuccess] = useState(false)
  const [removeSuccessMessage, setRemoveSuccessMessage] =
    useState<React.ReactNode>()
  const [showView, setShowView] = useState<boolean>()

  function handleSuccess(show: boolean, message: ReactNode) {
    setShowModalRemove(false)
    setSuccess(show)
    setSuccessMessages(message)
  }

  function handleSuccessProxy(show: boolean, message: ReactNode) {
    setShowModalRemove(false)
    handleSuccess(show, message)
  }

  async function handleRemoveInvoices(): Promise<void> {
    await removeInvoice({ invoiceId: invoice?.id })
  }

  useEffect(() => {
    if (removeSuccess && removeSuccessMessage) {
      handleSuccessProxy(true, removeSuccessMessage)
      setRemoveSuccess(false)
      setRemoveSuccessMessage(undefined)
    }
  }, [removeSuccess, removeSuccessMessage])

  useEffect(() => {
    invoiceId && setShowView(true)
  }, [invoiceId])

  const columnsOptions: InvoicesColumns = {
    emmitedAt: {
      name: t("Invoice.attributes.emission"),
      direction: "start",
      field: (invoice) => InvoicesEmmitedAt({ item: invoice }),
    },
    monthGoalOn: {
      name: t("Invoice.attributes.monthGoalOn"),
      field: (item) => intlFormatDate(item.monthGoalOn),
    },
    repurposalCode: {
      thClassName: "text-nowrap",
      name: t("Invoice.attributes.repurposalNumber"),
      field: (item) =>
        FormatWaiting({
          item,
          response: RepurposalCell(item),
        }),
    },
    invoiceNumber: {
      name: t("Invoice.attributes.invoiceViewNumber.one"),
      field: (item) =>
        FormatWaiting({
          item,
          response: item.invoiceNumber || item.numInvoice,
        }),
      "td-data-testid": (item) =>
        `invoiceNumber${item.invoiceNumber || item.numInvoice}`,
      thClassName: "text-nowrap",
    },
    netWeight: {
      name: t("Global.attributes.netWeight"),
      field: (item) => (
        <InvoiceStatusDecorations item={item}>
          {item?.netWeight ? FormatWeight({ quantity: item?.netWeight }) : "-"}
        </InvoiceStatusDecorations>
      ),
      tdClassName: "text-nowrap",
      thClassName: "text-nowrap",
      direction: "end",
    },
    grossWeight: {
      name: t("Global.attributes.grossWeight"),
      field: (item) => (
        <InvoiceStatusDecorations item={item}>
          {item?.grossWeight
            ? FormatWeight({ quantity: item?.grossWeight })
            : "-"}
        </InvoiceStatusDecorations>
      ),
      tdClassName: "text-nowrap",
      thClassName: "text-nowrap",
      direction: "end",
    },
    issuer: {
      name: t("Invoice.attributes.issuer.one"),
      tdClassName: "limite-caracteres-buttom",
      size: 6,
      title: (item) => (item?.supplier ? item.supplier.corporateName : "-"),
      field: (item) =>
        FormatWaiting({
          item,
          response: InvociesCustomerCell({
            customer: item.supplier,
            document: item.supplierDocument,
          }),
        }),
    },
    receiver: {
      name: t("Invoice.attributes.receiver.one"),
      tdClassName: "limite-caracteres-buttom",
      size: 6,
      title: (item) => (item?.buyer ? item.buyer.corporateName : "-"),
      field: (item) =>
        FormatWaiting({
          item,
          response: InvociesCustomerCell({
            customer: item.buyer,
            document: item.buyerDocument,
          }),
        }),
    },
    erpNumber: {
      name: (
        <>
          {t("Global.attributes.erpNumber")}{" "}
          <Info>
            {t("Global.alerts.thisFieldIsSetAccordingToTheRepurposal")}
          </Info>
        </>
      ),
      thClassName: "text-nowrap d-flex align-items-center gap-1",
      field: (item) => item.repurposalCore?.erpNumber || "-",
    },
    status: {
      name: t("Global.attributes.status"),
      field: (invoice) => (
        <InvoicesStatusCell
          invoice={invoice}
          setSuccess={setSuccess}
          setSuccessMessages={setSuccessMessages}
        />
      ),
    },
    value: {
      tdClassName: "text-nowrap",
      name: t("Global.attributes.value"),
      direction: "end",
      field: InvoicesValueCell,
    },

    actions: {
      name: t("Global.actions.actions"),
      direction: "end",
      field: (invoice) => (
        <InvoicesActionCell
          invoice={invoice}
          setInvoiceId={setInvoiceId}
          customAdditionalInfo={customAdditionalInfo}
          showModalInParams={showModalInParams}
        />
      ),
    },
  }

  const columns: Array<Column<Invoice>> = []
  selectedColumnsOptions.forEach((selected) => {
    columns.push(columnsOptions[selected as InvoicesColumnsOptions])
  })

  return (
    <>
      <InvoiceModalView
        handleSuccess={handleSuccess}
        invoice={invoice}
        setInvoice={setInvoice}
        invoiceId={invoiceId}
        setInvoiceId={setInvoiceId}
        onEditClick={() => {
          handleSuccessProxy(false, "")
          setShowModalRemove(false)
        }}
        setShowModalRemove={setShowModalRemove}
        show={showView}
        setShow={setShowView}
        showModalInParams={showModalInParams}
      />
      <CustomDeleteModal
        title={t("Invoice.attributes.removeNf")}
        deleteFunction={handleRemoveInvoices}
        item={invoice?.invoiceNumber ?? ""}
        itemType={t("Invoice.attributes.abbreviationInvoice")}
        show={showModalRemove}
        setShow={setShowModalRemove}
        setSuccess={setRemoveSuccess}
        successMessagei18nKey="Invoice.alerts.remove.success"
        setSuccessMessages={setRemoveSuccessMessage}
        data-testid={""}
      />
      <CustomTable
        items={items}
        columns={columns}
        itemsPerPage={itemsPerPage}
        page={page}
        setPage={setPage}
        totalItems={totalItems}
        isLoading={isLoading || !items}
        totalizers={totalizers}
        variant={variant}
        headersClassName={headersClassName}
        rowsClassName={rowsClassName}
        borderInherit={borderInherit}
        tbodyWhite={tbodyWhite}
      />
    </>
  )
}
