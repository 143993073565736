import axios, { HttpStatusCode } from "axios"
import "react-toastify/dist/ReactToastify.css"
import { authTokens } from "../utils/authTokens"
import { forbiddenRedirect, loginRedirect } from "../utils/redirectUltis"
import { toast } from "react-toastify"
import { t } from "i18next"
import * as Sentry from "@sentry/browser"
import { getCountryCode } from "../utils/hooks/useCountryConfigs/utils"
import { countryConfigurations } from "../configs/countryConfig"
import i18n from "../i18n/i18n"

const accessToken = localStorage.getItem("access-token")

function createAPIClient({
  needCountryCode = true,
  needErrorsInterceptors = true,
} = {}) {
  const api = axios.create({
    baseURL: import.meta.env.VITE_BASEURL_RUBY,
    headers: {
      "Accept-language": navigator.language,
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "access-token": authTokens.getAccessToken(),
      client: authTokens.getClient(),
      expirity: authTokens.getExpirity(),
      uid: authTokens.getUid(),
      locale: i18n.language,
    },
  })

  api.interceptors.request.use((configs) => {
    const selectedCountry = getCountryCode()

    if (needCountryCode) {
      configs.headers.set("Country-Code", selectedCountry)
    }

    const targetTimezone = countryConfigurations[selectedCountry].timezone.name
    configs.headers.set("Time-Zone", targetTimezone)

    return configs
  })

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (needErrorsInterceptors) {
        const status = error.response?.status
        if (status === HttpStatusCode.Unauthorized) {
          authTokens.cleanTokens()
          loginRedirect()
        } else if (status === HttpStatusCode.Forbidden) {
          forbiddenRedirect()
        } else if (status === HttpStatusCode.InternalServerError) {
          toast.error(t("Global.alerts.internalServerError"))
        }
      }
      Sentry.captureException(error)
      return Promise.reject(error)
    },
  )

  return api
}

export const api = createAPIClient()
export const errorExceptionsApi = createAPIClient({
  needErrorsInterceptors: false,
})
export const countryCodeExceptionsApi = createAPIClient({
  needCountryCode: false,
})
