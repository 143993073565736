import { useTranslation } from "react-i18next"
import { AdditionalInfoAccordion } from "../../../../../../components/Form/formComponents/AdditionalInfoAccordion"
import BillTagInput from "../BillTagInput"

export function BillAdditionalInfo(props: DefaultBillField) {
  const { bill, setBill } = props
  const { t } = useTranslation()
  return (
    <AdditionalInfoAccordion
      additionalInfo={bill?.additionalInfo}
      setAdditionalInfo={(info) =>
        setBill((bill) => ({ ...bill, additionalInfo: info }))
      }
      entityName={t("Bills.bill.one")}
    >
      <BillTagInput bill={bill} setBill={setBill} />
    </AdditionalInfoAccordion>
  )
}
