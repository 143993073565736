export function setDeliveryOrigin(
  delivery: SimpleDelivery,
): DocumentAndNamesCustomer {
  const {
    kind,
    repurposalCore: {
      stock: { checkins, distributionCenter },
    },
  } = delivery

  return kind === "inbound"
    ? checkins?.[0]?.supplier
    : distributionCenter.supplier
}
