import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { INVOICES_KIND_MAP } from "../../../../utils/kindMap"
import { useEffect } from "react"
import { DropdownInput } from "../../../../../../../components/DropdownInput"
import { Label } from "../../../../../../../components/Form/formComponents/Label"
import InvoicesReturnReasonInput from "../ReasonsFields/InvoiceReturnReasonField"

export function KindInvoiceForms({
  invoice,
  setInvoice,
  isEditing = false,
}: InvoiceFormPropsWithEditing) {
  const { t } = useTranslation()

  useEffect(() => {
    !isEditing &&
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        kind: "standard",
      }))
  }, [isEditing])

  function handleStatusChange(selected: invoicesKind | undefined) {
    setInvoice((invoice) => ({
      ...invoice,
      kind: selected,
      returnReason:
        selected !== "return" ? undefined : invoice?.cancellationReason,
    }))
  }

  return (
    <>
      <Form.Group className="mb-3" controlId="kind">
        <Label required>{t("Global.attributes.type")}</Label>
        <DropdownInput<invoicesKind>
          select
          value={invoice?.kind}
          options={INVOICES_KIND_MAP}
          renderName={(item) => t(`Invoice.attributes.kind.${item}`)}
          onClick={handleStatusChange}
          required
          deselectable
          id="invoiceKind"
        />
      </Form.Group>
      <InvoicesReturnReasonInput invoice={invoice} setInvoice={setInvoice} />
    </>
  )
}
