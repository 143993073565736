import { t } from "i18next"
import { Button } from "react-bootstrap"
import { BsPencilFill } from "react-icons/bs"
import { FaPlus } from "react-icons/fa"

interface CheckinModalButtonProps {
  addSubcheckin?: boolean
  isEdit?: boolean
  testid?: string
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  setDeleteFeedback: React.Dispatch<
    React.SetStateAction<{ show: boolean; message: string }>
  >
}

export default function CheckinModalButton(props: CheckinModalButtonProps) {
  const { isEdit, addSubcheckin, testid, setShow, setDeleteFeedback } = props

  const open = () => {
    setShow(true)
    setDeleteFeedback({ show: false, message: "" })
  }

  return (
    <>
      {addSubcheckin ? null : isEdit ? (
        <div
          className="d-flex align-items-center justify-content-start gap-2"
          onClick={open}
          data-testid={`stock-checkin-edit-button-${testid}`}
        >
          <BsPencilFill size={15} className="" />
          <span>{t("StockCheckin.actions.identify/change")}</span>
        </div>
      ) : (
        <Button
          variant="success"
          onClick={open}
          data-testid="stock-checkin-register-button"
        >
          <span>{t("StockCheckin.attributes.registerCheckin")}</span>
        </Button>
      )}

      {addSubcheckin && !isEdit && (
        <div
          onClick={open}
          className="d-flex align-items-center gap-2 justify-content-start"
          data-testid={`checkin-add-subcheckin-button-${testid}`}
        >
          <FaPlus className="" color="#222529" size={15} />
          <span className="">{t("StockCheckin.attributes.addSubcheckin")}</span>
        </div>
      )}
    </>
  )
}
