import React from "react"
import { getRepurposalById } from "../../../../../services/invoices.service"
import { RepurposalInput } from "../../../../../components/Form/formComponents/RepurposalInput"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"

interface InvoiceAutomaticRepurposalCodeInputProps {
  invoice: EditableInvoice | undefined
  setInvoice: React.Dispatch<React.SetStateAction<EditableInvoice | undefined>>
  repurposalId: string | undefined
}

export default function InvoiceAutomaticRepurposalCodeInput(
  props: InvoiceAutomaticRepurposalCodeInputProps,
) {
  const { t } = useTranslation()
  const { invoice, setInvoice, repurposalId } = props

  const { data: stockKind, isFetching } = useQuery({
    enabled: !!invoice?.repurposalCode,
    queryKey: ["repurposals", { code: invoice?.repurposalCode }],
    queryFn: findStockKind,
  })

  async function findStockKind() {
    const data = await getRepurposalById({
      repurposalId: invoice?.repurposalCode,
    })

    switch (data.stock.checkins.kind) {
      case "finished_goods":
        return t("StockCheckin.attributes.finished")
      case "raw_materials":
        return t("StockCheckin.attributes.rawMaterial")
      default:
        return t("Global.alerts.NotIdentified")
    }
  }

  return (
    <RepurposalInput
      required
      label={t("Invoice.attributes.repurposalNumber")}
      value={
        invoice?.repurposalCode
          ? {
              code: invoice?.repurposalCode,
            }
          : undefined
      }
      onClick={(repurposal) =>
        setInvoice((prev) => ({
          ...prev,
          repurposalCode: repurposal.code,
          billId: undefined,
          deliveryId: undefined,
        }))
      }
      disabled={!!repurposalId}
      additionalContent={
        <>
          {!isFetching &&
            invoice?.repurposalCode &&
            (stockKind ? (
              <small className="text-muted">
                <strong>{t("Invoice.attributes.stockKind")}: </strong>{" "}
                {stockKind}{" "}
              </small>
            ) : (
              <small className="text-danger">
                {t("Invoice.alerts.warning.invalid.repurposalNumber")}
              </small>
            ))}
        </>
      }
    />
  )
}
