import { useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { BsClipboard2CheckFill } from "react-icons/bs"
import { MultiErrorAlerts } from "../../MultiErrorAlerts"
import { Skeleton } from "../../Skeleton"
import { CopyButton } from "../../Buttons/CopyButton"

export function ShareLinkModal(props: ShareLinkModalProps) {
  const {
    link,
    accessCode,
    show,
    setShow,
    onClose,
    additionalActions,
    title,
    isLoading,
    customErrors,
  } = props
  const { t } = useTranslation()
  const [copyIsLoading, setCopyIsLoading] = useState<boolean>()
  const accessCodeArray = accessCode?.split("")

  function handleClose() {
    setShow(false)
    setCopyIsLoading(false)
    onClose?.()
  }

  async function handleCopy() {
    setCopyIsLoading(true)
    const message = t("Global.attributes.shareLinkWithCode", {
      link,
      code: accessCode,
    })
    await navigator.clipboard.writeText(message)
    setCopyIsLoading(false)
  }

  function characterCard(value: string | number) {
    return (
      <div
        className="border py-1 px-4 rounded-1 fs-1"
        data-testid={`share-code-${value}`}
      >
        {value}
      </div>
    )
  }

  const loadingCards = [...Array(4)].map((value, index) => (
    <div className="border px-1 py-1 rounded-1 fs-1" key={value}>
      <Skeleton my={0} isloading>
        <div className="mx-3">{index}</div>
      </Skeleton>
    </div>
  ))

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title data-testid={props["data-testid"] + "-modal-title"}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {customErrors && customErrors.length > 0 && (
          <MultiErrorAlerts items={customErrors} />
        )}
        <Form>
          <Form.Group>
            <Form.Label data-testid={props["data-testid"] + "-link-label"}>
              URL
            </Form.Label>
            <div className="border p-2 rounded-1">
              <Skeleton my={0} isloading={isLoading}>
                {link ? (
                  <a
                    className="w-100 text-truncate"
                    href={link}
                    title={link}
                    target="_blank"
                    rel="noreferrer"
                    data-testid={props["data-testid"] + "-link-value"}
                  >
                    {link}
                  </a>
                ) : (
                  "-"
                )}
              </Skeleton>
            </div>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label
              data-testid={props["data-testid"] + "-access-code-label"}
            >
              {t("Global.attributes.accessCode")}
            </Form.Label>
            <div
              className="d-flex gap-2 text-uppercase"
              data-testid={props["data-testid"] + "-access-code-value"}
            >
              {isLoading
                ? loadingCards
                : accessCodeArray?.map((item) => characterCard(item)) ??
                  [...Array(4)].map(() => characterCard("-"))}
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {additionalActions}
        <CopyButton
          onClick={handleCopy}
          className="d-flex align-items-center gap-2 ms-auto"
          data-testid={props["data-testid"] + "-copy-button"}
          isLoading={copyIsLoading}
        >
          <BsClipboard2CheckFill />
          {t("Global.attributes.copyData")}
        </CopyButton>
      </Modal.Footer>
    </Modal>
  )
}
