import { api } from "./api"

interface GetStockManagementProps {
  originSupplierDocuments?: string[]
  suppliersDocuments?: string[]
  supplierResponsiblesIds?: string[]
  itemsPerPage: string
  pageNumber: string
  search?: Record<string, string>
  status?: string[]
  kind?: string[]
  createdAtStartDate?: string
  createdAtFinalDate?: string
  createdAtMonthYear?: string
}
export async function getStockManagement({
  itemsPerPage,
  pageNumber,
  originSupplierDocuments,
  suppliersDocuments,
  supplierResponsiblesIds,
  search,
  status,
  kind,
  createdAtMonthYear,
  createdAtStartDate,
  createdAtFinalDate,
}: GetStockManagementProps) {
  const url = `/stocks`
  const response = await api.get(url, {
    params: {
      originSupplierDocuments,
      suppliersDocuments,
      supplierResponsiblesIds,
      itemsPerPage,
      pageNumber,
      status,
      kind,
      createdAtMonthYear,
      createdAtStartDate,
      createdAtFinalDate,
      ...search,
    },
  })
  return response.data
}

export async function updateStockStatus(id: number, status: string) {
  const payload = {
    status,
  }
  const response = await api.patch(`/stocks/${id}`, payload)
  return response.data
}

export async function updateStockKind(id: number, kind: string) {
  const payload = {
    kind: kind,
  }
  const response = await api.patch(`/stocks/${id}`, payload)
  return response.data
}

export async function getStock(id: number) {
  const response = await api.get(`/stocks/${id}`)
  return response.data
}

interface IUpdateInfoAddtional {
  id: number
  message: string
}

export async function updateInfoAddtional({
  id,
  message,
}: IUpdateInfoAddtional) {
  const payload = {
    additionalInformation: message,
  }
  const response = await api.patch(`/stocks/${id}`, payload)
  return response.data
}

interface SendEmailStockManagementProps {
  id: number
  emailSendTo: string
}

export async function sendEmailStockManagement({
  id,
  emailSendTo,
}: SendEmailStockManagementProps) {
  const payload = {
    emailSendTo: emailSendTo,
  }
  const response = await api.post(
    `/stocks/${id}/send_opportunity_email`,
    payload,
  )
  response.data
}

interface StockSimpleListParams {
  search?: string
  context: string
  pageNumber: string
}

export async function StockSimpleList(props: StockSimpleListParams) {
  const { search, context, pageNumber } = props
  const response = await api.get("/stocks", {
    params: {
      search,
      context,
      pageNumber,
    },
  })
  return response.data
}

export async function StockCheckinList({
  itemsPerPage = "20",
  search,
  pageNumber,
  kind,
  supplierId = "",
  supplierResponsibleId,
  gooxxySolutions = "",
  createdAtStartDate = "",
  createdAtFinalDate = "",
  ...otherProps
}: StockCheckinListParams) {
  const response = await api.get("/stocks/checkin", {
    params: {
      itemsPerPage,
      pageNumber,
      kind,
      supplierId,
      supplierResponsibleId: [...(supplierResponsibleId ?? [])],
      gooxxySolutions,
      createdAtStartDate,
      createdAtFinalDate,
      search,
      ...otherProps,
    },
  })
  return response.data
}

export async function createStockCheckin(payload: StockCheckinPayload) {
  const formData = new FormData()
  Object.entries(payload).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item, index) => formData.append(`${key}[${index}]`, item))
    } else {
      formData.append(key, value)
    }
  })
  const response = await api.post(`/stocks/checkin`, formData)
  return response.data
}

export async function deleteStockCheckin(id: number) {
  const response = await api.delete(`/stocks/checkin/${id}`)
  return response.data
}

export async function updateStockCheckin(
  id: number,
  payload: StockCheckinPayload,
) {
  const response = await api.put(`/stocks/checkin/${id}`, payload)
  return response.data
}

export interface CreateCheckinExternalProps {
  createdByName: string
  createdByEmail: string
  supplierName: string
  file: File | null
  additionalInformation?: string | undefined
}

export async function createCheckinExternal(
  payload: CreateCheckinExternalProps,
) {
  const formData = new FormData()
  formData.append("createdByName", payload.createdByName)
  formData.append("createdByEmail", payload.createdByEmail)
  formData.append("supplierName", payload.supplierName)
  if (payload.file) {
    formData.append("file", payload.file)
  }
  if (payload.additionalInformation) {
    formData.append("additionalInformation", payload.additionalInformation)
  }
  const response = await api.post(`/public/stocks/checkin`, formData)
  return response.data
}

type ExternalAccessCodeProps = {
  payload: {
    featureType: string
    authorizationData: {
      stockId: string
    }
  }
}
export async function externalAccessCode({ payload }: ExternalAccessCodeProps) {
  const { data } = await api.post("/external_access_controls", payload)
  return data
}

type UpdateExternalAccessConfigProps = {
  hashedId: string
  configs: {
    showNegotiatedInfo: string
    freezeMinPrice: string
    timeToExpiration: string
  }
}

export async function updateExternalAccessConfigs({
  hashedId,
  configs: { showNegotiatedInfo, freezeMinPrice, timeToExpiration },
}: UpdateExternalAccessConfigProps) {
  const payload = {
    configs: {
      showNegotiatedInfo,
      freezeMinPrice,
      timeToExpiration,
    },
  }
  return await api.put(`/external_access_controls/configs/${hashedId}`, payload)
}

export async function exportStockManagement({
  suppliersDocuments = "",
  supplierResponsiblesIds = "",
  status = "",
  createdAtFinalDate,
  createdAtStartDate,
}: ExportStockManagement) {
  return await api.get(`stocks/export_xlsx`, {
    responseType: "blob",
    params: {
      suppliersDocuments: suppliersDocuments,
      supplierResponsiblesIds: supplierResponsiblesIds,
      createdAtFinalDate,
      createdAtStartDate,
      status: status,
    },
  })
}

export async function deleteStockManagement(id: number) {
  const { data } = await api.delete(`/stocks/${id}`)
  return data
}

export async function exportCheckin({
  supplierId = "",
  supplierResponsibleId = "",
  kind = "",
  gooxxySolutions = "",
  createdAtStartDate = "",
  createdAtFinalDate = "",
}: ExportCheckin) {
  try {
    return await api.get("stocks/checkins/export_xlsx", {
      params: {
        kind,
        supplierId,
        supplierResponsibleId: [
          ...(supplierResponsibleId ? supplierResponsibleId : []),
        ],
        gooxxySolutions,
        createdAtStartDate,
        createdAtFinalDate,
      },
      responseType: "blob",
    })
  } catch (error) {
    console.error(error)
  }
}

export type RefreshStockTableResponse = {
  distributionCenters: DistributionCentersTableData[]
}

export type DistributionCentersTableData = {
  id: number
  code: number
  name: string
}

export async function refreshStockTableData(id: string) {
  return await api.get(`/clients/customers/${id}/distribution_centers`)
}

export type StockSuppliers = {
  count: number
  itemsPerPage: number
  pageNumber: number
  customers: StockSupplierDropdownItem[]
}

export type StockSupplierDropdownItem = {
  corporateName: string
  document: string
  id: string
  name: string
}

export async function postStocks(formData: FormData) {
  return await api.post(`/stocks`, formData)
}

export type ItemByEanResponse = {
  description: string
}

export async function getItemByEan(ean: string) {
  return await api.get(`catalogs/items/codes/${ean}`)
}

export type RelateEanToSkuProps = {
  skusObject: {
    _codItem: number
    supplierCode: number
    supplierId: number
    itemId: number
  }
}
export async function listSolutions() {
  return await api.get(`stocks/gooxxy_solutions`)
}

export async function relateEanToSku(props: RelateEanToSkuProps) {
  return await api.post(`catalogs/items/item_skus`, props.skusObject)
}

export interface OriginCheckinListProps {
  id: string
  pageNumber?: number
  itemsPerPage?: number
  search?: string
}
export async function getOriginCheckinsList(props: OriginCheckinListProps) {
  return await api.get(
    props.id !== ""
      ? `/stocks/checkin?supplierIdNotIn=${props.id}&search=${props.search}`
      : `/stocks/checkin?&search=${props.search}`,
  )
}

interface ShowStockProps {
  id: number
  pageNumber: number
  search: string
  itemsWithImage?: number
  repurposalUnitId?: number
  orderBy: OrderBy
}
export async function showStock(props: ShowStockProps) {
  const { id, orderBy, itemsWithImage, repurposalUnitId, ...otherProps } = props
  return await api.get(`/stocks/${id}/stock_items/paginated`, {
    params: {
      itemsPerPage: 20,
      orderByField: orderBy.orderByField,
      orderByDirection: orderBy.orderByDirection,
      itemsWithImage: itemsWithImage
        ? itemsWithImage === 1
          ? true
          : false
        : null,
      repurposalUnitId: repurposalUnitId,
      ...otherProps,
    },
  })
}

interface UpdateStatusCheckinProps {
  id: number
  status: string
}

export async function updateStatusCheckin(props: UpdateStatusCheckinProps) {
  const { id, status } = props
  return await api.patch(`/stocks/checkin/${id}/status`, {
    status: status,
  })
}

interface RevertStatusCheckinProps {
  id: number
}

export async function revertStatusCheckin(props: RevertStatusCheckinProps) {
  const { id } = props
  return await api.put(`/stocks/checkin/${id}/status/revert`)
}

export async function getMinPriceStock(props: { id: number }) {
  const { id } = props
  return await api.get(`/stocks/${id}`, {
    params: { context: "stock_grouping_min_price" },
  })
}
