import { formatDocument } from "../../../../utils/Format/formatDocument"

export function CodeAndCustomersOptions(props: CodeAndCustomersOptionsProps) {
  const {
    code,
    firstCustomer,
    firstCustomerLabel,
    firstCustomerSize = 6,
    secondCustomer,
    secondCustomerLabel,
    secondCustomerSize = 6,
    additionalContent,
  } = props
  const firstCustomerIdentification =
    firstCustomer?.corporateName ??
    (firstCustomer?.document ? formatDocument(firstCustomer.document) : "-")
  const secondCustomerIdentification =
    secondCustomer?.corporateName ??
    (secondCustomer?.document ? formatDocument(secondCustomer.document) : "-")

  return (
    <>
      <div className="fw-bold">{code}</div>
      <div className=" d-flex text-secondary">
        <div className={`col-${firstCustomerSize} text-truncate`}>
          <strong>{firstCustomerLabel + ": "}</strong>
          <span title={firstCustomerIdentification ?? "-"}>
            {firstCustomerIdentification ?? "-"}
          </span>
        </div>
        <div className={`col-${secondCustomerSize} text-truncate`}>
          <strong>{secondCustomerLabel + ": "}</strong>
          <span title={secondCustomerIdentification ?? "-"}>
            {secondCustomerIdentification ?? "-"}
          </span>
        </div>
        {additionalContent}
      </div>
    </>
  )
}
