import { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { HeaderInvoiceView } from "./components/InvoiceHeaderModal"
import { useTranslation } from "react-i18next"
import { InvoicesFormModalNew } from "../InvoicesFormModalNew"
import { getInvoice } from "../../../../../services/invoices.service"
import { AxiosError } from "axios"
import { MultiErrorAlerts } from "../../../../../components/MultiErrorAlerts"
import { InvoiceCopyCard } from "./components/InvoiceCopyCard"
import { FinancialModal } from "../../../components/FinancialModalView"

export function InvoiceModalView(props: InvoiceModalViewProps) {
  const {
    handleSuccess,
    invoice,
    setInvoice,
    onEditClick,
    setShowModalRemove,
    show,
    setShow,
    showModalInParams,
    setInvoiceId,
    invoiceId,
  } = props
  const { t } = useTranslation()
  const [showModalOpen, setShowModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])

  const handleClose = () => {
    setShow(false)
    setInvoiceId(undefined)
    setIsLoading(false)
    setErrorMessages([])
  }

  const handleEditClick = () => {
    handleClose()
    setShowModalOpen(true)
    onEditClick()
  }

  async function findInvoice() {
    setIsLoading(true)
    setErrorMessages([])
    try {
      if (invoiceId) {
        const newInvoice: Invoice = await getInvoice(invoiceId)
        setInvoice({
          ...newInvoice,
          ...(newInvoice.kind === "return"
            ? { valueCents: `${Number(newInvoice.valueCents) * -1}` }
            : {}),
        })
      }
    } catch (error) {
      console.error(error)
      setInvoice(undefined)
      error instanceof AxiosError &&
        error.response?.data.messages &&
        setErrorMessages(error.response.data.messages)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    findInvoice()
    handleSuccess(false, undefined)
  }, [invoiceId])

  return (
    <>
      <FinancialModal
        show={show}
        setShow={setShow}
        financialId={invoiceId}
        setFinancialId={setInvoiceId}
        onHide={() => {
          handleClose()
          setInvoice(undefined)
        }}
        showModalInParams={showModalInParams}
        data-testid="InvoiceViewModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Invoice.attributes.invoiceView")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessages.length > 0 && (
            <MultiErrorAlerts items={errorMessages} />
          )}
          <div className="col-lg-10 col-12">
            <HeaderInvoiceView items={invoice} isLoading={isLoading} />
          </div>
          <InvoiceCopyCard invoice={invoice} isLoading={isLoading} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            data-testid="editInvoice"
            onClick={handleEditClick}
            className="me-auto"
          >
            {t("Global.actions.edit")}
          </Button>
          <Button
            variant="outline-primary"
            data-testid="closeInvoiceViewModal"
            onClick={handleClose}
          >
            {t("Global.actions.close")}
          </Button>
        </Modal.Footer>
      </FinancialModal>
      {showModalOpen && (
        <InvoicesFormModalNew
          showModalOpen={showModalOpen}
          setShowModalOpen={setShowModalOpen}
          handleSuccess={handleSuccess}
          isEditing
          invoice={invoice}
          setInvoice={
            setInvoice as React.Dispatch<
              React.SetStateAction<EditableInvoice | undefined>
            >
          }
          setShowModalRemove={setShowModalRemove}
        />
      )}
    </>
  )
}
