import { useEffect, useState } from "react"
import { setApiErrorMessages } from "../../../utils/setApiErrorMessage"
import { Badge, Button, Form, Modal } from "react-bootstrap"
import { MultiErrorAlerts } from "../../MultiErrorAlerts"
import { CustomAlert } from "../../Customs/CustomAlert"
import { Label } from "../../Form/formComponents/Label"
import { DropdownInput } from "../../DropdownInput"
import { ButtonSubmit } from "../../Buttons/ButtonSubmit"
import { useTranslation } from "react-i18next"

interface EditStatusModalProps<T extends string> {
  status: T | undefined
  setStatus: React.Dispatch<React.SetStateAction<T | undefined>>
  originalStatus: T | undefined
  statusOptions: Array<T> | undefined
  onSubmit: (status: T) => Promise<void>
  additionalFields?: React.ReactNode
  onClose?: () => void
  onShow?: () => void
  title: string | undefined | null
  renderName: (status: T) => string
  badgeMap: Record<T, ChangeStatusBadgeOption>
  contentName?: string
  badgeName: string
  "data-testid"?: string
}

interface ChangeStatusBadgeOption {
  icon: React.ReactNode
  variant: string
  className?: string
}

export function EditStatusModal<T extends string>(
  props: EditStatusModalProps<T>,
) {
  const {
    status,
    setStatus,
    originalStatus,
    statusOptions,
    additionalFields,
    onClose,
    onShow,
    onSubmit,
    title,
    renderName,
    badgeMap,
    badgeName,
    contentName = badgeName,
    "data-testid": dataTestId,
  } = props
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const [show, setShow] = useState(false)
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const {
    variant = "secondary",
    icon = null,
    className = "",
  } = originalStatus ? badgeMap[originalStatus] : {}

  function handleShow() {
    setShow(true)
    setStatus(originalStatus)
    onShow?.()
  }

  function handleClose() {
    setShow(false)
    setValidated(false)
    setIsLoading(false)
    setErrorMessages([])
    setStatus(undefined)
    onClose?.()
  }

  function handleClick() {
    setShow(true)
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (event.currentTarget.checkValidity() && status) {
      try {
        setIsLoading(true)
        setErrorMessages([])
        await onSubmit(status)
        handleClose()
      } catch (error) {
        setApiErrorMessages(error, setErrorMessages)
      } finally {
        setIsLoading(false)
      }
    } else {
      setValidated(true)
    }
  }

  useEffect(() => {
    setStatus(originalStatus)
  }, [originalStatus])

  return (
    <>
      <Badge
        as={"button"}
        onClick={handleClick}
        bg={variant}
        className={`${className ?? ""} border-0 d-flex justify-content-center align-items-center gap-1`}
        data-testid={`${dataTestId}-status`}
      >
        {icon}
        {contentName}
      </Badge>
      <Modal show={show} onShow={handleShow} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            {errorMessages.length > 0 && (
              <MultiErrorAlerts items={errorMessages} />
            )}
            <CustomAlert
              onClose={() => setValidated(false)}
              variant="danger"
              show={validated}
              dismissible={true}
              data-testid="alertRequiredFields"
            >
              {t("Global.alerts.emptyFields")}
            </CustomAlert>
            <Form.Group className="mb-3">
              <Label required>{t("Global.attributes.status")}</Label>
              <DropdownInput
                renderName={renderName}
                onClick={(status) => {
                  setStatus(status)
                }}
                options={statusOptions ?? []}
                value={status}
                required
                select
                id={dataTestId + "-status"}
              />
            </Form.Group>
            {additionalFields}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleClose}
              variant="outline-primary"
              data-testid={`${dataTestId}-cancel`}
            >
              {t("Global.actions.cancel")}
            </Button>
            <ButtonSubmit
              type="submit"
              variant="primary"
              name={t("Global.actions.save")}
              loading={isLoading}
              disabled={isLoading}
              data-testid={`${dataTestId}-submit`}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
