import { api } from "./api"

type TagsScope = "invoices" | "bills" | "payments" | "deliveries"

interface GetTagProps {
  pageNumber: number
  search?: string
  scope?: TagsScope
}

export async function getTags(props: GetTagProps) {
  const { data } = await api.get(`/tags`, { params: props })
  return data
}

export function createTag(description: string) {
  return api.post(`/tags`, { description })
}
