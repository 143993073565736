import { captureException } from "@sentry/react"
import { useQuery } from "@tanstack/react-query"
import { useId } from "react"
import { Form } from "react-bootstrap"
import { Label } from "../Label"
import { DropdownInput } from "../../../DropdownInput"
import { paymentTypeDatasList } from "../../../../services/payments.service"

export function PaymentDataInput(props: PaymentDataInputProps) {
  const { label, paymentTypeId, value, onClick, required } = props
  const id = useId()

  async function getPaymentTypeDatas(): Promise<Array<PaymentTypeData>> {
    const { data } = await paymentTypeDatasList(paymentTypeId)
    return data
  }

  const {
    isLoading,
    data: paymentTypeDatas,
    error,
  } = useQuery({
    queryKey: ["paymentTypeDatas", paymentTypeId],
    queryFn: getPaymentTypeDatas,
    enabled: !!paymentTypeId,
    staleTime: 30 * 60 * 1000,
  })

  if (error) {
    captureException(error)
    console.error(error)
  }

  return (
    <Form.Group className="mb-3" controlId={id}>
      <Label required={required}>{label}</Label>
      <DropdownInput<PaymentTypeData>
        renderOption={(value) => (
          <span
            className="p-m-0"
            dangerouslySetInnerHTML={{
              __html: value.data ?? "Payment data not found",
            }}
          />
        )}
        renderName={(value) => value.name}
        options={paymentTypeDatas ?? []}
        isLoading={isLoading}
        onClick={onClick}
        required={required}
        value={value}
        id={"payment-type-data"}
      />
    </Form.Group>
  )
}
