import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"

type PriceInputProps = {
  name?: string
  value?: number
  onChange?: (newValue: number | null | undefined | string) => void
  required?: boolean
  disabled?: boolean
  isInvalid?: boolean
  defaultValue?: string
  title?: string
  className?: string
  datatestid?: string
  allowNegativeValue?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  noPlaceholder?: boolean
}

export const PriceInput: React.FC<PriceInputProps> = ({
  name,
  value,
  onChange,
  required,
  isInvalid,
  disabled,
  title,
  className,
  defaultValue,
  datatestid,
  allowNegativeValue = false,
  onBlur,
  noPlaceholder,
}) => {
  const [inputValue, setInputValue] = useState<string>(formatValue(value ?? 0))

  useEffect(() => {
    if (value === null || value === undefined) {
      setInputValue("")
    } else {
      setInputValue(formatValue(value))
    }
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target
    const inputRef = e.currentTarget
    const selectionStart = input.selectionStart || 0
    const maskRegex = allowNegativeValue ? /[^-?\d]/g : /\D/g
    const rawValue = input.value.replace(maskRegex, "")

    if (rawValue === "") {
      setInputValue("")
      if (onChange) {
        onChange(undefined)
      }
      return
    }

    const newValue = parseFloat(rawValue) / 100
    const formattedValue = formatValue(newValue)
    const rawLength = input.value.length
    const formattedLength = formattedValue.length
    const cursorOffset = formattedLength - rawLength

    setInputValue(formattedValue)

    if (onChange) {
      onChange(newValue)
    }

    setTimeout(() => {
      if (inputRef) {
        const newCursorPosition = selectionStart + cursorOffset
        inputRef.setSelectionRange(newCursorPosition, newCursorPosition)
      }
    }, 0)
  }

  function formatValue(value: number): string {
    if (isNaN(value) || value === 0) {
      return ""
    }

    const formattedValue = Math.abs(value).toFixed(2).replace(".", ",")

    const [integerPart, decimalPart] = formattedValue.split(",")

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ".",
    )

    return (
      (allowNegativeValue && value < 0 ? "-" : "") +
      `${formattedIntegerPart},${decimalPart}`
    )
  }

  return (
    <Form.Control
      data-testid={datatestid}
      className={className}
      type="text"
      inputMode="numeric"
      name={name}
      defaultValue={defaultValue}
      disabled={disabled}
      isInvalid={isInvalid}
      value={inputValue}
      title={title}
      onChange={handleChange}
      required={required}
      placeholder={noPlaceholder ? "" : "0,00"}
      onBlur={onBlur}
    />
  )
}
