import styled, { css } from "styled-components"
import { Button, ButtonProps } from "react-bootstrap"

interface ButtonPaginationProps extends ButtonProps {
  pageNumber?: string
  buttonColor: string
  isCurrentPage?: boolean
}
export const ButtonPagination = styled(Button)<ButtonPaginationProps>`
  ${({ pageNumber }) =>
    String(pageNumber) === "..." &&
    css`
      border: 0;
    `}

  ${({ pageNumber }) =>
    Number(pageNumber) < 10 &&
    css`
      padding: 0 8px;
    `}
    
  margin: 0;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isCurrentPage, buttonColor }) =>
    !isCurrentPage &&
    css`
      background-color: ${buttonColor};
    `}
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 12px 2px;

  nav {
    display: inline-flex;
    gap: 6px;
    border-radius: 8px;
  }
`
