import { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { Skeleton } from "../../Skeleton"
import { ModalItemView } from "./components/ModalItemView"

export function CustomModalItemsView<T>(props: ModalItemsViewProps<T>) {
  const { isLoading, item, columns, additionalFields } = props

  return (
    <Row className="px-3" data-testid={`${props["data-testid"]}-content`}>
      {columns.map((column, index) => (
        <Col
          key={index}
          className="d-flex flex-column p-2"
          data-testid={`${props["data-testid"]}-col-${index}`}
        >
          {column.map((cell, index) => {
            const { field, content, emptyState = "-" } = cell
            const fieldDataTestId = field ? `-${String(field)}` : ""
            const cellDataTestId = cell["data-testid"]
              ? `-${cell["data-testid"]}`
              : ""
            let children = undefined
            if (content) {
              if (item && (!field || (field && item[field])) && !!content(item))
                children = content(item)
              else children = emptyState
            }

            return (
              <Fragment key={index}>
                <ModalItemView
                  label={
                    <Skeleton isloading={isLoading}>{cell.label}</Skeleton>
                  }
                  data-testid={`${props["data-testid"]}${
                    content
                      ? `Field${fieldDataTestId}${cellDataTestId}`
                      : "Title"
                  }`}
                >
                  <span
                    data-testid={`${props["data-testid"]}${
                      field ? `-${String(field)}` : ""
                    }${cellDataTestId}-content`}
                  >
                    <Skeleton isloading={isLoading}>{children}</Skeleton>
                  </span>
                </ModalItemView>
              </Fragment>
            )
          })}
        </Col>
      ))}
      {additionalFields && (
        <Col className="col-12 mt-3"> {additionalFields}</Col>
      )}
    </Row>
  )
}
