import { useState } from "react"
import { Button, Modal, Spinner } from "react-bootstrap"
import { Trans, useTranslation } from "react-i18next"
import { AxiosError } from "axios"
import { MultiErrorAlerts } from "../../MultiErrorAlerts"
import { CustomDeleteModalProps } from "./customDeleteModal"
import { useNavigate } from "react-router-dom"

export function CustomDeleteModal(props: CustomDeleteModalProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    title,
    show,
    setShow,
    setSuccess,
    successMessagei18nKey,
    setSuccessMessages,
    item,
    itemType,
    deleteFunction,
    caution,
    setFullSuccess,
    customerDelete,
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  const handleClose = () => {
    setShow(false)
    setIsLoading(false)
    setErrorMessages([])
  }

  async function handleDelete() {
    setSuccess && setSuccess(false)
    setSuccessMessages && setSuccessMessages(undefined)
    setFullSuccess && setFullSuccess({ show: false, message: undefined })
    try {
      setIsLoading(true)
      await deleteFunction()
      const successMessage = (
        <Trans i18nKey={successMessagei18nKey} values={{ item }}>
          ...<strong>{item}</strong>...
        </Trans>
      )
      setSuccess && setSuccess(true)
      setSuccessMessages && setSuccessMessages(successMessage)
      setFullSuccess && setFullSuccess({ show: true, message: successMessage })
      handleClose()
      if (customerDelete) navigate(`/customers?userDelete=${item}`)
    } catch (error) {
      if (error instanceof AxiosError)
        setErrorMessages(error.response?.data.messages)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} data-testid={props["data-testid"]}>
      <Modal.Header closeButton>
        <Modal.Title data-testid={props["data-testid"] + "-title"}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body data-testid={props["data-testid"] + "-body"}>
        {errorMessages?.length > 0 && (
          <MultiErrorAlerts items={errorMessages} />
        )}
        {caution && (
          <>
            <span className="text-danger">
              <>{t("Global.alerts.remove.caution")}</> {itemType.toLowerCase()}!
            </span>
            <br />
          </>
        )}
        <span>
          {`${t("Global.alerts.remove.alert")} ${itemType} `}
          <strong>{item}</strong>?
        </span>
      </Modal.Body>
      <Modal.Footer data-testid={props["data-testid"] + "-footer"}>
        <>
          <Button
            variant="primary"
            onClick={async () => {
              await handleDelete()
            }}
            data-testid={`${props["data-testid"]}RemoveButton`}
          >
            {!isLoading ? t("Global.actions.yesRemove") : <Spinner size="sm" />}
          </Button>
          <Button
            variant="secondary"
            onClick={handleClose}
            data-testid={`${props["data-testid"]}CancelButton`}
          >
            {t("Global.actions.cancel")}
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  )
}
