import { FilterMultiSelect } from "../FilterMultiSelect"
import { getUsersList } from "../../../../../../services/users.service"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useDebounceValue } from "usehooks-ts"
import { useCountryConfigs } from "../../../../../../utils/hooks/useCountryConfigs"

function defaultRenderValue(item: baseData) {
  return item.id
}

function defaultRenderName(item: baseData) {
  return item.name
}

function getNextPageParam(lastPage: SimpleIndexUserQuery) {
  return lastPage.page + 1
}

export function FilterUsersSelect(props: FilterUsersSelectProps) {
  const {
    removeScroll,
    removeSearch,
    label,
    isResponsibleForRepurposal,
    ...otherConfigs
  } = props
  const [search, setSearch] = useDebounceValue("", 500)
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()

  async function getUsers({
    pageParam,
  }: {
    pageParam: number
  }): Promise<SimpleIndexUserQuery> {
    const { data } = await getUsersList({
      search: search.trim(),
      context: "simple_index",
      kind: "internal",
      isResponsibleForRepurposal,
      page: pageParam,
    })
    return { users: data.users, count: data.count, page: pageParam }
  }

  const query = useInfiniteQuery({
    queryKey: [
      "users",
      "simple_index",
      "internal",
      isResponsibleForRepurposal,
      search,
      countryCode,
    ],
    queryFn: getUsers,
    initialPageParam: 1,
    getNextPageParam,
    staleTime: 5 * 60 * 1000,
  })

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value)
  }

  async function handleScroll() {
    query.fetchNextPage()
  }

  return (
    <>
      {FilterMultiSelect<baseData>({
        renderName: defaultRenderName,
        renderValue: defaultRenderValue,
        disabled: props.disabled,
        options: query.data?.pages.flatMap((page) => page.users),
        isLoading: query.isLoading,
        onChange: !removeSearch ? handleSearch : undefined,
        onScroll: !removeScroll ? handleScroll : undefined,
        labelName: label,
        count: query.data?.pages.slice(-1)[0].count,
        ...otherConfigs,
      })}
    </>
  )
}
