import { useTranslation } from "react-i18next"
import { capitalizeText } from "../../../../../../utils/Format"
import { RepurposalInput } from "../../../../../../components/Form/formComponents/RepurposalInput"

export function BillRepurposalInput(
  props: DefaultBillField & { hasDefaultValue: boolean },
) {
  const { bill, setBill, hasDefaultValue } = props
  const { t } = useTranslation()

  return (
    <RepurposalInput<RepurposalWithNegotiationAndFreightKind>
      onClick={(repurposalCore) =>
        setBill((bill) => ({
          ...bill,
          repurposalCore,
        }))
      }
      label={capitalizeText(t("Invoice.attributes.repurposalNumber"))}
      value={
        bill?.repurposalCore as
          | RepurposalWithNegotiationAndFreightKind
          | undefined
      }
      id="bill-repurposal-input"
      context="core_index_with_freight_type"
      required
      disabled={hasDefaultValue}
    />
  )
}
