import { createContext, ReactNode, useContext, useState } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

interface FeedbackState {
  type: "success" | "warning" | "error" | null
  message: string
}

interface FeedbackContextProps {
  lastFeedback: FeedbackState
  addFeedback: {
    success: (message: string) => void
    warning: (message: string) => void
    error: (message: string) => void
  }
  clearFeedback: () => void
}

const FeedbackContext = createContext<FeedbackContextProps | undefined>(
  undefined,
)

export const FeedbackProvider = ({ children }: { children: ReactNode }) => {
  const [lastFeedback, setLastFeedback] = useState<FeedbackState>({
    type: null,
    message: "",
  })

  const toastConfig = {
    autoClose: 3000,
  }

  const addFeedback = {
    success: (message: string) => {
      setLastFeedback({ type: "success", message })
      toast.success(message, toastConfig)
    },
    warning: (message: string) => {
      setLastFeedback({ type: "warning", message })
      toast.warning(message, toastConfig)
    },
    error: (message: string) => {
      setLastFeedback({ type: "error", message })
      toast.error(message, toastConfig)
    },
  }

  const clearFeedback = () => {
    setLastFeedback({ type: null, message: "" })
  }

  return (
    <FeedbackContext.Provider
      value={{ lastFeedback, addFeedback, clearFeedback }}
    >
      {children}
    </FeedbackContext.Provider>
  )
}

export const useFeedback = () => {
  const context = useContext(FeedbackContext)
  if (!context) {
    throw new Error("useFeedback must be used within a FeedbackProvider")
  }
  return context
}
