export function deliveryIsLate(
  deliveryAt: string | Date,
  deliveryLimit: string | Date,
): boolean {
  const deliveryAtDate =
    deliveryAt instanceof Date ? deliveryAt : new Date(deliveryAt)
  const deliveryLimitDate =
    deliveryLimit instanceof Date ? deliveryLimit : new Date(deliveryLimit)

  deliveryAtDate.setHours(0, 0, 0, 0)
  deliveryLimitDate.setHours(0, 0, 0, 0)

  return deliveryLimitDate < deliveryAtDate
}
