import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../../../components/Form/formComponents/Label"

export default function InvoicesReturnReasonInput(
  props: DefaultInvoiceFormsProps,
) {
  const { t } = useTranslation()
  const { invoice, setInvoice } = props

  return (
    <>
      {invoice && invoice.kind === "return" && (
        <Form.Group className="mb-3" controlId="return-reason">
          <Label required>{t("Invoice.attributes.returnReason")}</Label>
          <Form.Control
            as="textarea"
            value={invoice.returnReason}
            onChange={(e) =>
              setInvoice({
                ...invoice,
                returnReason: e.target.value.replace(/\n/g, " "),
              })
            }
            required
          />
        </Form.Group>
      )}
    </>
  )
}
