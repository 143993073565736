import { CustomAlert } from "../../../../components"
import { createStockCheckin, updateStockCheckin } from "../../../../services"
import { AxiosError } from "axios"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { Trans } from "react-i18next"
import { useCountryConfigs } from "../../../../utils/hooks/useCountryConfigs"
import { validateEmails } from "../../../../utils/validateEmails"
import SubcheckinDropdown from "../SubcheckinDropdown/SubcheckinDropdown"
import CheckinFileInput from "./CheckinFileInput"
import CheckinKindInput from "./CheckinKindInput"
import CheckinSolutionInput from "./CheckinSolutionInput"
import CheckinSupplierInput from "./CheckinSupplierInput"
import CheckinValueInput from "./CheckinValueInput"
import CheckinWeightInputs from "./CheckinWeightInputs"
import CheckinDelete from "./CheckinDelete"
import CheckinModalButton from "./CheckinModalButton"

interface StockCheckinFormModalProps {
  isEdit?: boolean
  checkinValue?: string
  sentMailAt?: string | null
  grossWeight?: string
  netWeight?: string
  handleReloadTable: () => void
  handleFeedback: (
    show: boolean,
    message: string | JSX.Element,
    type: string,
  ) => void
  supplier?: SuppliersList | null
  checkinId?: number
  productType?: string
  testid?: string
  gooxxySolutions?: Record<string, string[]>
  addSubcheckin?: boolean
  additionalInformation?: string
  originCheckinId?: StockCheckin
  loading: boolean
}

interface AxiosErrorResponseData {
  messages?: string[] | string
}

export function StockCheckinFormModal(props: StockCheckinFormModalProps) {
  const { currency } = useCountryConfigs()
  const [show, setShow] = useState(false)
  const [messageError, setMessageError] = useState({ show: false, message: "" })
  const [sendEmail, setSendEmail] = useState<boolean>(false)
  const [subcheckin, setSubcheckin] = useState<boolean>(
    props.addSubcheckin || false,
  )
  const [emails, setEmails] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [priceInput, setPriceInput] = useState(props.checkinValue || "")
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [originCheckin, setOriginCheckin] = useState<StockCheckin | undefined>(
    props.originCheckinId || undefined,
  )
  const [kind, setKind] = useState(props.isEdit ? props.productType : undefined)
  const [solution, setSolution] = useState<Record<string, string[]>>(
    props.isEdit && props.gooxxySolutions ? props.gooxxySolutions : {},
  )

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [value, setValue] = useState<SuppliersList | null>(
    props.supplier ?? null,
  )

  const [deleteFeedback, setDeleteFeedback] = useState({
    show: false,
    message: "",
  })

  const getInitialErrors = (): CheckinModalErrors => ({
    supplierError: false,
    spreadsheetError: false,
    checkinValueError: false,
    invalidFileFormat: false,
    emailError: false,
  })

  const getInitialFormData = (): CheckinModalFormData => ({
    supplier: props?.supplier?.id || "",
    checkinValue: props.checkinValue || "",
    grossWeight: props.grossWeight || "",
    netWeight: props.netWeight || "",
    additionalInformation: props.additionalInformation || "",
    spreadsheet: undefined,
    productType: props.productType || "",
    gooxxySolutionIds: props.gooxxySolutions || [],
    originCheckinId: props.originCheckinId || ({} as StockCheckin),
  })

  const [formData, setFormData] =
    useState<CheckinModalFormData>(getInitialFormData())

  const [errors, setErrors] = useState<CheckinModalErrors>(getInitialErrors())

  useEffect(() => {
    if (props.isEdit) {
      setFormData({
        ...formData,
        checkinValue: props.checkinValue || "",
        grossWeight: props.grossWeight || "",
        netWeight: props.netWeight || "",
        productType: props.productType || "",
        gooxxySolutionIds: props.gooxxySolutions || [],
        additionalInformation: props.additionalInformation || "",
      })
    }
  }, [props.isEdit])

  useEffect(() => {
    if (isFormSubmitted) {
      const isSupplierFilled = String(formData.supplier).trim() !== ""
      const isSpreadsheetFilled = formData.spreadsheet !== undefined
      const isCheckinValueFilled = formData.checkinValue.trim() !== ""

      if (
        (isSupplierFilled || !props.isEdit) &&
        isSpreadsheetFilled &&
        (isCheckinValueFilled || !props.isEdit)
      ) {
        false
      } else {
        true
      }
    }
  }, [
    isFormSubmitted,
    formData.supplier,
    formData.spreadsheet,
    props.isEdit,
    formData.checkinValue,
  ])

  useEffect(() => {
    if (priceInput?.length > 0 || Boolean(priceInput) || priceInput !== "0.0") {
      setErrors({
        supplierError: false,
        spreadsheetError: false,
        checkinValueError: false,
        invalidFileFormat: false,
        emailError: false,
      })
    }
  }, [priceInput])

  const handleValidateEmails = () => {
    const isValid = sendEmail ? validateEmails(emails) : true

    setErrors((prevErrors) => ({
      ...prevErrors,
      emailError: !isValid,
    }))

    return isValid
  }

  const createAndEditStockCheckin = async () => {
    setIsFormSubmitted(true)

    if (hasFormErrors()) {
      setMessageError({ show: true, message: t("Global.alerts.emptyFields") })
      return
    }

    try {
      setIsLoading(true)

      const cleanPayload = getCleanPayload()

      if (props.isEdit) {
        await updateCheckin(cleanPayload)
      } else {
        await createCheckin(cleanPayload)
      }

      onClose()
      props?.handleReloadTable()
    } catch (error) {
      handleCheckinError(error as AxiosError<AxiosErrorResponseData>)
    } finally {
      setIsLoading(false)
    }
  }

  const hasFormErrors = () => {
    const isSupplierEmpty =
      !formData.supplier || formData.supplier === "undefined"
    const isSpreadsheetEmpty = !formData.spreadsheet && !props.isEdit
    const isCheckinValueInvalid =
      props.isEdit && (!priceInput || Number(priceInput) <= 0)

    const hasErrors =
      isSupplierEmpty ||
      isSpreadsheetEmpty ||
      isCheckinValueInvalid ||
      !handleValidateEmails()

    setErrors({
      supplierError: isSupplierEmpty,
      spreadsheetError: isSpreadsheetEmpty,
      checkinValueError: !!isCheckinValueInvalid,
      invalidFileFormat: false,
      emailError: !handleValidateEmails(),
    })

    return hasErrors
  }

  const getCleanPayload = (): Partial<StockCheckinPayload> => {
    const basePayload = {
      supplierId: String(formData?.supplier),
      totalValue: priceInput,
      grossWeight: formData.grossWeight,
      netWeight: formData.netWeight,
      kind: formData.productType as "raw_materials" | "finished_goods",
      gooxxySolutionIds: solution.solutions,
      originCheckinId:
        subcheckin && originCheckin ? String(originCheckin?.id) : "",
      additionalInformation: formData.additionalInformation,
    }

    return Object.fromEntries(
      Object.entries(basePayload).filter(
        ([, value]) =>
          value !== null &&
          value !== undefined &&
          value !== "" &&
          value !== "null",
      ),
    )
  }

  const updateCheckin = async (payload: Partial<StockCheckinPayload>) => {
    const finalPayload = emails.trim()
      ? {
          ...payload,
          emailSendTo: emails.replace(" ", "").replace(",", ";"),
        }
      : payload

    await updateStockCheckin(props.checkinId as number, finalPayload)
    props.handleFeedback(
      true,
      <Trans
        i18nKey="StockCheckin.alerts.update.success"
        values={{ code: props.checkinId }}
        components={{ b: <b /> }}
      />,
      "success",
    )
  }

  const createCheckin = async (payload: Partial<StockCheckinPayload>) => {
    const response = await createStockCheckin({
      ...payload,
      file: formData.spreadsheet,
    })
    props.handleFeedback(
      true,
      <Trans
        i18nKey="StockCheckin.alerts.created.success"
        values={{ code: response.id }}
        components={{ b: <b /> }}
      />,
      "success",
    )
  }

  const handleCheckinError = (error: AxiosError<AxiosErrorResponseData>) => {
    const messages = error.response?.data?.messages
    setMessageError({
      show: true,
      message: Array.isArray(messages)
        ? messages.join("<br>")
        : messages || t("Global.alerts.internalServerError"),
    })
  }

  const onClose = () => {
    setFormData(getInitialFormData())
    setErrors(getInitialErrors())
    setSolution({})
    setMessageError({ show: false, message: "" })

    setKind("")
    setEmails("")
    setPriceInput(props.checkinValue || "")
    setSendEmail(false)
    setIsFormSubmitted(false)
    setIsLoading(false)
    setShow(false)
  }

  const disableCheckbox =
    formData.supplier === "" || formData.supplier === "undefined"

  return (
    <>
      <CheckinDelete
        isLoading={isLoading}
        checkinId={props.checkinId}
        checkinValue={props.checkinValue}
        handleReloadTable={props.handleReloadTable}
        deleteFeedback={deleteFeedback}
        setDeleteFeedback={setDeleteFeedback}
        handleFeedback={props.handleFeedback}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        setIsLoading={setIsLoading}
      />

      <CheckinModalButton
        addSubcheckin={props.addSubcheckin}
        setDeleteFeedback={setDeleteFeedback}
        testid={props.testid}
        isEdit={props.isEdit}
        setShow={setShow}
      />

      <div
        onKeyDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        onFocus={(e) => e.stopPropagation()}
        onMouseOver={(e) => e.stopPropagation()}
      >
        <Modal show={show} onHide={onClose} className="modal-lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {props.isEdit
                ? t("StockCheckin.actions.identify/change")
                : t("StockCheckin.attributes.registerCheckin")}
            </Modal.Title>
          </Modal.Header>

          <Form>
            <Modal.Body>
              <CustomAlert
                onClose={async () =>
                  setMessageError({ show: false, message: "" })
                }
                variant="danger"
                show={messageError.show}
                dismissible
                data-testid="stock-checkin-error-alert"
              >
                <span
                  dangerouslySetInnerHTML={{ __html: messageError.message }}
                />
              </CustomAlert>

              <CheckinFileInput
                errors={errors}
                isEdit={props.isEdit}
                setErrors={setErrors}
                setFormData={setFormData}
              />
              <CheckinKindInput
                setFormData={setFormData}
                setKind={setKind}
                kind={kind}
              />
              <CheckinSolutionInput
                setSolution={setSolution}
                solution={solution}
                show={show}
              />

              <CheckinSupplierInput
                errors={errors}
                isLoading={isLoading}
                setFormData={setFormData}
                setValue={setValue}
                value={value}
                show={show}
                setIsLoading={setIsLoading}
              />

              <CheckinValueInput
                currency={currency}
                errors={errors}
                isEdit={props.isEdit}
                priceInput={priceInput}
                setPriceInput={setPriceInput}
              />

              <CheckinWeightInputs
                formData={formData}
                setFormData={setFormData}
              />
              <div>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label={t("StockCheckin.attributes.subcheckinCheck")}
                    checked={subcheckin}
                    onChange={(e) => setSubcheckin(e.target.checked)}
                    data-testid="subcheckinCheck"
                  />
                </Form.Group>

                {subcheckin && (
                  <SubcheckinDropdown
                    originCheckin={originCheckin}
                    setOriginCheckin={setOriginCheckin}
                    disabled={!subcheckin}
                    supplierId={String(formData.supplier)}
                  />
                )}

                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("Global.attributes.additionalInformation")}{" "}
                    <span className="text-muted">
                      {t("Global.attributes.optional")}
                    </span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="additionalInformation"
                    value={formData.additionalInformation}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        additionalInformation: e.target.value,
                      })
                    }}
                    placeholder={
                      t(
                        "StockCheckin.attributes.placeholderAdditionalInformation",
                      ) as string
                    }
                    data-testid="additionalInformationRegister"
                  />
                </Form.Group>

                {props.isEdit && (
                  <Form.Group className="form-label">
                    <Form.Check
                      type="checkbox"
                      disabled={disableCheckbox}
                      title={
                        disableCheckbox
                          ? (t(
                              "StockCheckin.attributes.titleCheckbox",
                            ) as string)
                          : ""
                      }
                      id="sendEmail"
                      label={t("StockCheckin.attributes.titleLabel")}
                      checked={sendEmail}
                      onChange={(e) => setSendEmail(e.target.checked)}
                    />
                    {props.sentMailAt && sendEmail && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        {t("StockCheckin.attributes.feedbackSendEmail")}
                      </span>
                    )}
                  </Form.Group>
                )}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Row className="w-100">
                {props.isEdit && (
                  <Col>
                    <Button
                      variant="outline-danger"
                      onClick={() => {
                        onClose()
                        setShowDeleteModal(true)
                      }}
                      data-testid="deleteCheckin"
                    >
                      {t("Global.attributes.delete") as string}
                    </Button>
                  </Col>
                )}
                <Col
                  className={`d-flex justify-content-end gap-3 ${props.isEdit ? "col-4" : "col-12"}`}
                >
                  <Button variant="outline-secondary" onClick={onClose}>
                    {t("Global.actions.cancel")}
                  </Button>
                  <Button
                    type="button"
                    variant={props.isEdit ? "primary" : "success"}
                    onClick={createAndEditStockCheckin}
                    disabled={isLoading}
                    data-testid="checkinRegister"
                  >
                    {isLoading ? (
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                      >
                        <span className="visually-hidden">
                          {t("StockCheckin.attributes.loading")}
                        </span>
                      </div>
                    ) : props.isEdit ? (
                      t("Global.actions.save")
                    ) : (
                      t("Global.actions.register")
                    )}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </>
  )
}
