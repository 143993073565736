import { Trans, useTranslation } from "react-i18next"
import {
  BsCheckCircleFill,
  BsClockFill,
  BsFillDashCircleFill,
} from "react-icons/bs"
import { updateInvoice } from "../../../../../../../services/invoices.service"
import { IVOICES_STATUS_MAP } from "../../../../utils/statusMap"
import { EditStatusModal } from "../../../../../../../components/Modal/EditStatusModal/EditStatusModal"
import { useState } from "react"
import { Form } from "react-bootstrap"
import { Label } from "../../../../../../../components/Form/formComponents/Label"
import { findReasonsInInvoice } from "../../../../utils/findReasons"

const statusMap: Record<
  InvoiceStatus,
  {
    variant: string
    icon: React.ReactNode
  }
> = {
  waiting_for_identification: {
    variant: "secondary",
    icon: <BsClockFill />,
  },
  confirmed: {
    variant: "success",
    icon: <BsCheckCircleFill />,
  },
  canceled: {
    variant: "secondary",
    icon: <BsFillDashCircleFill />,
  },
}

export function InvoicesStatusCell(props: InvoicesStatusCellProps) {
  const { t } = useTranslation()
  const { invoice, setSuccess, setSuccessMessages } = props
  const [status, setStatus] = useState<InvoiceStatus | undefined>(
    invoice.status,
  )
  const [cancellationReason, setCancellationReason] = useState<string>()
  const statusTranslate = t(`Invoice.attributes.status.${invoice.status}`)
  const { returnReason, aditionalInfo } = findReasonsInInvoice(
    invoice.aditionalInfo,
  )

  async function handleCreateClick(status: InvoiceStatus) {
    const data = await updateInvoice({
      invoice: {
        id: invoice.id,
        status,
        cancellationReason,
        returnReason,
        aditionalInfo,
      },
    })
    setSuccess(true)
    setSuccessMessages(
      <Trans
        i18nKey={"Invoice.alerts.update.success"}
        values={{ item: data?.invoiceNumber }}
      >
        ...<strong>...</strong>...
      </Trans>,
    )
  }

  return (
    <EditStatusModal
      title={t("Invoice.attributes.invoiceStatus")}
      renderName={(status) => t(`Invoice.attributes.status.${status}`)}
      status={status}
      setStatus={setStatus}
      statusOptions={IVOICES_STATUS_MAP}
      originalStatus={invoice.status}
      badgeMap={statusMap}
      onSubmit={handleCreateClick}
      badgeName={statusTranslate}
      onShow={() => setSuccess(undefined)}
      additionalFields={
        <>
          {invoice.status !== "canceled" && status === "canceled" && (
            <Form.Group className="mb-3" controlId="cancellation-reason">
              <Label required>
                {t("Invoice.attributes.cancellationReason")}
              </Label>
              <Form.Control
                as="textarea"
                value={cancellationReason}
                onChange={(e) => setCancellationReason(e.target.value)}
                required
              />
            </Form.Group>
          )}
        </>
      }
    />
  )
}
