import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { IVOICES_STATUS_MAP } from "../../../../utils/statusMap"
import { useEffect } from "react"
import { DropdownInput } from "../../../../../../../components/DropdownInput"
import { Label } from "../../../../../../../components/Form/formComponents/Label"
import InvoicesCancellationReasonInput from "../ReasonsFields/InvoiceCancellationReasonField"

export function StatusInvoiceForms({
  invoice,
  setInvoice,
  isEditing = false,
}: InvoiceFormPropsWithEditing) {
  const { t } = useTranslation()

  useEffect(() => {
    !isEditing &&
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        status: "confirmed",
      }))
  }, [isEditing])

  function handleStatusChange(selected: InvoiceStatus | undefined) {
    setInvoice((invoice) => ({
      ...invoice,
      status: selected,
      cancellationReason:
        selected !== "canceled" ? undefined : invoice?.cancellationReason,
    }))
  }

  return (
    <>
      <Form.Group className="mb-3" controlId="status">
        <Label required>{t("Global.attributes.status")}</Label>
        <DropdownInput
          select
          value={invoice?.status}
          options={IVOICES_STATUS_MAP}
          renderName={(item) => t(`Invoice.attributes.status.${item}`)}
          onClick={handleStatusChange}
          required
          deselectable
          id="invoiceStatus"
        />
      </Form.Group>
      <InvoicesCancellationReasonInput
        invoice={invoice}
        setInvoice={setInvoice}
      />
    </>
  )
}
