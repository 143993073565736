import { CustomAlert } from "../Customs/CustomAlert"
import { useEffect, useState } from "react"

export function MultiErrorAlerts(props: MultiErrorAlertsProps) {
  const { items, variant, onChange, reset } = props
  const [show, setShow] = useState<boolean[]>(
    new Array(items.length).fill(true),
  )

  useEffect(() => {
    reset && setShow(new Array(items.length).fill(true))
  }, [reset])

  return (
    <>
      {items.map((item: string, index: number) => {
        if (index > import.meta.env.VITE_MAX_ERRORS_TO_SHOW) return
        return (
          <CustomAlert
            key={` Alert: ${index} `}
            variant={variant || "danger"}
            onClose={() => {
              setShow((prevShow) => {
                const newShow = [...prevShow]
                newShow[index] = false
                return newShow
              }),
                onChange && onChange()
            }}
            show={show[index]}
            dismissible={true}
            data-testid={`multiErrorNumber${index + 1}`}
          >
            {item}
          </CustomAlert>
        )
      })}
    </>
  )
}
