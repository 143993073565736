import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Button, Dropdown, Form, Modal } from "react-bootstrap"
import { ArquiveInvoiceForms } from "./components/ArquiveInvoiceForms"
import { DataInvoiceForms } from "./components/DataInvoiceForms"
import { NumberInvoiceForms } from "./components/NumbersInvoiceForms"
import { DocumentInvoiceForms } from "./components/DocumentoInvoiceForms"
import { StatusInvoiceForms } from "./components/StatusInvoiceForms"
import { LogisticInvoiceForms } from "./components/LogisticInvoiceForms"
import { ComplementaryAttributesInvoiceForms } from "./components/ComplementaryAttributesInvoiceForms"
import { AxiosError, HttpStatusCode } from "axios"
import { KindInvoiceForms } from "./components/KindInvoiceForms"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { InvoiceNumberForms } from "./components/InvoiceNumberForms"
import { InvoiceAmountForms } from "./components/InvoiceAmountForms"
import { ButtonSubmit } from "../../../../../components/Buttons/ButtonSubmit"
import { CustomAlert } from "../../../../../components/Customs/CustomAlert"
import { MultiErrorAlerts } from "../../../../../components/MultiErrorAlerts"
import { updateInvoice } from "../../../../../services/invoices.service"
import { InvoiceIssuerStateRegistration } from "./components/Fields/InvoiceIssuerStateRegistration"
import { InvoiceReceiverStateRegistration } from "./components/Fields/InvoiceReceiverStateRegistration"
import { findReasonsInInvoice } from "../../utils/findReasons"
import BillInput from "../../../../../components/Form/formComponents/BillInput/BillInput"
import InvoiceDeliveryInput from "./components/Fields/InvoiceDeliveryInput"

export function InvoicesFormModalNew({
  setShowModalOpen,
  isEditing,
  showModalOpen,
  showButton,
  handleSuccess,
  invoice,
  setInvoice,
  setShowModalRemove,
}: InvoicesFormModalNewProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { id: repurposalId } = useParams()
  const [typeXml, setTypeXml] = useState(false)
  const [typePdf, setTypePdf] = useState(false)
  const [validated, setValidated] = useState(false)
  const [repurposalNumberValidated, setRepurposalNumberValidated] =
    useState(false)
  const [showType, setShowType] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [stockKind, setStockKind] = useState<string | undefined>()
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const [feedback, setFeedback] = useState({
    requiredFields: false,
    file: false,
    serverError: false,
  })
  const disableMessage = t("Invoice.alerts.warning.warningAutomatic")
  const [show, setShow] = useState(false)
  const [xmlFile, setXmlFile] = useState<File | undefined>()
  const [pdfFile, setPdfFile] = useState<File | undefined>()
  const [bill, setBill] = useState<Partial<SimpleIndexBill>>()

  useEffect(() => {
    if (show) {
      !isEditing &&
        repurposalId &&
        setInvoice((prev) => ({ ...prev, repurposalCode: repurposalId }))
      handleSuccess(false, undefined)
    }
  }, [show])

  useEffect(() => {
    if (showModalOpen) {
      const reasons = findReasonsInInvoice(invoice?.aditionalInfo)
      setInvoice((prev) => ({ ...prev, ...reasons }))
    }
  }, [showModalOpen])

  useEffect(() => {
    isEditing && invoice && setStockKind(stockKind)
  }, [isEditing, invoice])

  function handleNavigate(id: number) {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set("financialId", String(id))

    navigate({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
    })
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() && invoice) {
      setIsLoading(true)
      setErrorMessages([])
      try {
        const data = await updateInvoice({
          invoice: { ...invoice, billId: bill?.id },
          xmlFile,
          pdfFile,
        })
        handleClose()
        handleSuccess(
          true,
          <>
            <Trans
              i18nKey={
                isEditing
                  ? "Invoice.alerts.update.success"
                  : "Invoice.alerts.create.success"
              }
              values={{ item: invoice?.invoiceNumber }}
            >
              ...<strong>...</strong>...
            </Trans>{" "}
            <a className="d-inline" onClick={() => handleNavigate(data.id)}>
              {t("Global.actions.visualize")}
            </a>
          </>,
        )
      } catch (error) {
        if (error instanceof AxiosError) {
          const { response } = error
          response?.status === HttpStatusCode.InternalServerError
            ? setErrorMessages([t("Global.alerts.internalServerError")])
            : setErrorMessages(response?.data.messages)
        }
      } finally {
        setIsLoading(false)
      }
    } else {
      setValidated(true)
    }
  }

  const handleClose = () => {
    setErrorMessages([])
    setValidated(false)
    setTypePdf(false)
    setTypeXml(false)
    setPdfFile(undefined)
    setXmlFile(undefined)
    setRepurposalNumberValidated(false)
    setShowType(false)
    setIsLoading(false)
    setStockKind(undefined)
    setFeedback((prev) => ({
      ...prev,
      requiredFields: false,
      file: false,
      serverError: false,
    }))
    setShow(false)
    setShowModalOpen && setShowModalOpen(false)
    setInvoice(undefined)
  }

  return (
    <>
      {showButton && (
        <Dropdown.Item
          onClick={() => setShow(true)}
          className={"dropdown-item"}
          data-testid="registerManual"
        >
          {t("Global.attributes.registerManual")}
        </Dropdown.Item>
      )}
      <Modal
        size="lg"
        show={isEditing ? showModalOpen : show}
        onHide={handleClose}
        data-testid="registerInvoiceModal"
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditing
                ? t("Invoice.attributes.updateNF")
                : t("Invoice.attributes.registerInvoice")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorMessages.length > 0 && (
              <MultiErrorAlerts items={errorMessages} />
            )}
            <CustomAlert
              onClose={() => setValidated(false)}
              variant="danger"
              show={validated}
              dismissible={true}
              data-testid="alertRequiredFields"
            >
              {t("Global.alerts.emptyFields")}
            </CustomAlert>
            <CustomAlert
              onClose={() =>
                setFeedback((prev) => ({ ...prev, requiredFields: false }))
              }
              variant="danger"
              show={feedback.requiredFields}
              dismissible={true}
              data-testid="alertRequiredFields"
            >
              {t("Global.alerts.emptyFields")}
            </CustomAlert>
            <CustomAlert
              onClose={() => setFeedback((prev) => ({ ...prev, file: false }))}
              variant="danger"
              show={feedback.file}
              dismissible={true}
            >
              {t("Invoice.alerts.error.alertXml&&Pdf")}
            </CustomAlert>
            <ArquiveInvoiceForms
              typeXml={typeXml}
              setTypeXml={setTypeXml}
              typePdf={typePdf}
              setTypePdf={setTypePdf}
              validated={validated}
              pdfFile={pdfFile}
              setPdfFile={setPdfFile}
              xmlFile={xmlFile}
              setXmlFile={setXmlFile}
              showEdit={isEditing}
              invoice={invoice}
              setInvoice={setInvoice}
              disableMessage={disableMessage}
            />
            <InvoiceNumberForms
              setInvoice={setInvoice}
              invoice={invoice}
              disableMessage={disableMessage}
            />
            <DataInvoiceForms
              invoice={invoice}
              setInvoice={setInvoice}
              disableMessage={disableMessage}
            />
            <InvoiceAmountForms
              invoice={invoice}
              setInvoice={setInvoice}
              disableMessage={disableMessage}
              validated={validated}
            />
            <DocumentInvoiceForms
              invoice={invoice}
              setInvoice={setInvoice}
              disableMessage={disableMessage}
            />
            <KindInvoiceForms
              invoice={invoice}
              setInvoice={setInvoice}
              isEditing={isEditing}
            />

            <StatusInvoiceForms
              invoice={invoice}
              setInvoice={setInvoice}
              isEditing={isEditing}
            />
            <NumberInvoiceForms
              repurposalNumberValidated={repurposalNumberValidated}
              setRepurposalNumberValidated={setRepurposalNumberValidated}
              showType={showType}
              setShowType={setShowType}
              stockKind={stockKind}
              setStockKind={setStockKind}
              invoice={invoice}
              setInvoice={setInvoice}
            />
            <BillInput
              repurposalCode={invoice?.repurposalCode}
              value={
                invoice?.billId
                  ? ({
                      ...bill,
                      id: invoice.billId,
                    } as Partial<SimpleIndexBill>)
                  : bill
              }
              onClick={(bill) => setBill(bill)}
              suggestionInfo={String(
                t("Invoice.alerts.warning.billsSuggestion"),
              )}
            />
            <InvoiceDeliveryInput invoice={invoice} setInvoice={setInvoice} />
            <InvoiceIssuerStateRegistration
              invoice={invoice}
              setInvoice={setInvoice}
            />
            <InvoiceReceiverStateRegistration
              invoice={invoice}
              setInvoice={setInvoice}
            />
            <LogisticInvoiceForms
              invoice={invoice}
              setInvoice={setInvoice}
              disableMessage={disableMessage}
            />
            <ComplementaryAttributesInvoiceForms
              invoice={invoice}
              setInvoice={setInvoice}
              isEditing={isEditing}
            />
          </Modal.Body>
          <Modal.Footer>
            {isEditing && setShowModalRemove && (
              <Button
                variant="outline-danger"
                className="me-auto"
                onClick={() => {
                  setShowModalRemove(true)
                  setShow(false)
                  setShowModalOpen && setShowModalOpen(false)
                }}
                data-testid="removeButton"
              >
                {t("Global.actions.remove")}
              </Button>
            )}
            <Button variant="outline-secondary" onClick={handleClose}>
              {t("Global.actions.cancel")}
            </Button>
            <ButtonSubmit
              variant={isEditing ? "primary" : "success"}
              type="submit"
              name={
                isEditing
                  ? (t("Global.actions.save") as string)
                  : (t("Global.actions.register") as string)
              }
              data-testid="submitButton"
              loading={isLoading}
              disabled={isLoading}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
