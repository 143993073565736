import { formatDocument } from "../../../../../../../utils/Format/formatDocument"

export function InvociesCustomerCell({
  customer,
  document,
}: {
  customer: DocumentAndNamesCustomer | undefined
  document: string | undefined
}) {
  return (
    <>
      {customer?.corporateName ?? "-"} <br />
      <small className="text-secondary">{formatDocument(document)}</small>
    </>
  )
}
