import {
  billInvoices,
  bindInvoiceInBill,
  unbindInvoiceInBill,
} from "../../../../services/bills.service"
import { BindedInvoicesTable } from "../../Invoice/components/BindInvoicesTable/BindedInvoicesTable"
import { useTranslation } from "react-i18next"

export function BillInvoicesTable(props: BillBoundsTableProps) {
  const { t } = useTranslation()
  const { bill, collapsed } = props

  return (
    <BindedInvoicesTable
      originId={bill.id}
      repurposalCoreCode={bill.repurposalCore.code}
      collapsed={collapsed}
      getBindedInvoices={billInvoices}
      bindFunction={bindInvoiceInBill}
      unbindFunction={unbindInvoiceInBill}
      queryKey={["bills"]}
      otherFilters={{
        hasBill: false,
      }}
      unbindTitle={t("Invoice.action.unbindInvoiceFromBill")}
      unbindMessageKey="Invoice.alerts.warning.unbindInvoiceFromBill"
    />
  )
}
