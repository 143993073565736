import {
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from "date-fns"
import { t } from "i18next"
import { DateRange } from "react-day-picker"

interface DatePickerFilterPresetsProps {
  handleSelectedDate: (date: Date | undefined | DateRange) => void
  selectedDateRange: DateRange | undefined
}

export default function DatePickerFilterPresets({
  handleSelectedDate,
  selectedDateRange,
}: DatePickerFilterPresetsProps) {
  const today = new Date()
  let isSelected = false

  const presetsOptions: { [key: string]: DateRange } = {
    today: {
      from: startOfDay(today),
      to: endOfToday(),
    },
    thisWeek: {
      from: startOfWeek(today),
      to: endOfWeek(today),
    },
    last30Days: {
      from: startOfDay(subDays(today, 30)),
      to: endOfToday(),
    },
    thisMonth: {
      from: startOfMonth(today),
      to: endOfMonth(today),
    },
    lastMonth: {
      from: startOfMonth(subMonths(today, 1)),
      to: endOfMonth(subMonths(today, 1)),
    },
    thisYear: {
      from: startOfYear(today),
      to: endOfYear(today),
    },
    lastYear: {
      from: startOfYear(subYears(today, 1)),
      to: endOfYear(subYears(today, 1)),
    },
  }

  function dateRangesIsEqual(
    dateRange1: DateRange | undefined,
    dateRange2: DateRange | undefined,
  ) {
    const isEqual =
      dateRange1?.from?.getTime() === dateRange2?.from?.getTime() &&
      dateRange1?.to?.getTime() === dateRange2?.to?.getTime()
    if (isEqual) isSelected = true
    return isEqual
  }

  return (
    <div className="list-group pe-3 border-end rounded-0">
      {Object.entries(presetsOptions).map(([key, dateRange]) => (
        <a
          key={`datePickerPreset_${key}`}
          data-testid={`date-picker-preset-${key}`}
          className={`list-group-item list-group-item-action border-0 ${dateRangesIsEqual(selectedDateRange, dateRange) ? "active" : ""}`}
          onClick={() => handleSelectedDate(dateRange)}
        >
          {t(`Global.dates.${key}`)}
        </a>
      ))}
      <span
        key={`datePickerPreset_custom`}
        data-testid="date-picker-preset-custom"
        className={`list-group-item list-group-item-action border-0 disabled ${!isSelected ? "active" : ""}`}
      >
        {t("Global.attributes.custom")}
      </span>
    </div>
  )
}
