import { useTranslation } from "react-i18next"
import { DocumentInput } from "../../../../../../../components/Form/formComponents/DocumentInput"
import { useCountryConfigs } from "../../../../../../../utils/hooks/useCountryConfigs"
import { capitalizeText } from "../../../../../../../utils/Format"

export function DocumentInvoiceForms({
  disableMessage,
  invoice,
  setInvoice,
}: DisableInvoiceFormsProps) {
  const { t } = useTranslation()
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()

  return (
    <>
      <DocumentInput
        label={capitalizeText(
          t("Invoice.attributes.issuerDocument", {
            document: t([
              `Global.attributes.document.${countryCode}`,
              "Global.attributes.document.document",
            ]),
          }),
        )}
        document={invoice?.supplierDocument}
        setDocument={(document) =>
          setInvoice({ ...invoice, supplierDocument: document })
        }
        required
        disabled={invoice?.automaticRegistration === 1}
        dropdownTitle={
          invoice?.automaticRegistration === 1 ? disableMessage : undefined
        }
        data-testid="issuerDocument"
      />
      <DocumentInput
        label={capitalizeText(
          t("Invoice.attributes.receiverDocument", {
            document: t([
              `Global.attributes.document.${countryCode}`,
              "Global.attributes.document.document",
            ]),
          }),
        )}
        document={invoice?.buyerDocument}
        setDocument={(document) =>
          setInvoice({ ...invoice, buyerDocument: document })
        }
        required
        disabled={invoice?.automaticRegistration === 1}
        dropdownTitle={
          invoice?.automaticRegistration === 1 ? disableMessage : undefined
        }
        data-testid="receiverDocument"
      />
    </>
  )
}
