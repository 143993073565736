import { Col, Form, InputGroup } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { PriceInput } from "../../../../../../../components/PriceInput"
import { Label } from "../../../../../../../components/Form/formComponents/Label"
import { useCountryConfigs } from "../../../../../../../utils/hooks/useCountryConfigs"

export function InvoiceAmountForms(
  props: DisableInvoiceFormsProps & { validated: boolean },
) {
  const { setInvoice, invoice, disableMessage, validated } = props
  const { t } = useTranslation()
  const { currency } = useCountryConfigs()

  return (
    <Form.Group as={Col} className="mb-3" controlId="valueCents">
      <Label required>{t("Invoice.attributes.amountNF")}</Label>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">{currency.code}</InputGroup.Text>
        <InputGroup.Text id="basic-addon1" className="rounded-0">
          {currency.symbol}
        </InputGroup.Text>
        <PriceInput
          disabled={invoice?.automaticRegistration === 1}
          title={invoice?.automaticRegistration === 1 ? disableMessage : ""}
          value={Number(invoice?.valueCents)}
          onChange={(value) =>
            setInvoice({ ...invoice, valueCents: String(value) })
          }
          required={true}
          isInvalid={Boolean(!invoice?.valueCents) && validated}
        />
        <Form.Control.Feedback type="invalid">
          {t("Global.alerts.requiredFieldInput")}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  )
}
