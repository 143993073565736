import { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { ActionButton } from "../../Buttons/ActionButton"
import { capitalizeText } from "../../../utils/Format"
import { setApiErrorMessages } from "../../../utils/setApiErrorMessage"
import { Button, Modal, Spinner } from "react-bootstrap"
import { BsDashLg } from "react-icons/bs"
import { ButtonSubmit } from "../../Buttons/ButtonSubmit"
import { MultiErrorAlerts } from "../../MultiErrorAlerts"

interface UnbindModalProps {
  unbindMessageKey: string
  title: string
  unbindFunction: () => Promise<void>
  "data-testid"?: string
  unbindId: number | string | undefined
  originId: number | string | undefined
}

export default function UnbindModal(props: UnbindModalProps) {
  const { t } = useTranslation()
  const {
    unbindMessageKey,
    title,
    unbindFunction,
    unbindId,
    originId,
    "data-testid": dataTestId,
  } = props
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])

  function handleClose() {
    setShow(false)
  }

  async function handleSubmit() {
    setIsLoading(true)
    setErrorMessages([])
    try {
      await unbindFunction()
      handleClose()
    } catch (error) {
      setApiErrorMessages(error, setErrorMessages)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <ActionButton
        onClick={() => setShow(true)}
        title={capitalizeText(t("Global.actions.unbind"))}
        disabled={isLoading}
        data-testid={dataTestId + "-unbind-button"}
      >
        {isLoading ? (
          <Spinner
            animation="border"
            className="square-size-6"
            variant="primary"
            data-testid={dataTestId + "-spinner"}
          >
            <output aria-live="polite">
              <span className="visually-hidden">Loading...</span>
            </output>
          </Spinner>
        ) : (
          <BsDashLg />
        )}
      </ActionButton>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title data-testid={dataTestId + "-title"}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessages.length > 0 && (
            <MultiErrorAlerts items={errorMessages} />
          )}
          <Trans
            i18nKey={unbindMessageKey}
            values={{ unbind: unbindId, from: originId }}
          >
            <strong />
          </Trans>
        </Modal.Body>
        <Modal.Footer>
          <ButtonSubmit
            variant="primary"
            name={t("Global.actions.yesUnbind")}
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isLoading}
            data-testid={dataTestId + "-submit-button"}
          />
          <Button variant="outline-secondary" onClick={handleClose}>
            {t("Global.actions.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
