import { api } from "./api"
import { GenericAbortSignal } from "axios"

export interface IPaymentsListFetch {
  pageNumber: number
  itemsPerPage?: number
  search?: string
  repurposalBound?: string
  paidAt?: DateFilter
  signal?: GenericAbortSignal
  orderBy?: string
  context?: "simple_index"
  repurposalCode?: string
}

export async function paymentsList(props: IPaymentsListFetch) {
  const { paidAt, signal, ...otherProps } = props
  return await api.get("/billing/payments", {
    params: {
      ...otherProps,
      ...(paidAt
        ? {
            paidAtStartDate: paidAt.initial,
            paidAtFinalDate: paidAt.final,
          }
        : {}),
    },
    signal,
  })
}

export const paymentsTypes = async (kind?: PaymentTypeKinds) => {
  return api("/billing/payment_types", {
    params: {
      kind,
    },
  })
}

export const updatePayment = async ({
  payment,
  sender,
  receipt,
}: CreatePaymentProps) => {
  let data

  if ("value" in payment) {
    const {
      buyer: _buyer,
      supplier: _supplier,
      receiptUrl: _receiptUrl,
      paymentType,
      tags,
      ...otherFields
    } = payment

    data = {
      paymentTypeId: paymentType?.id,
      receipt,
      ...(paymentType?.id ? {} : { sender }),
      ...otherFields,
      tagIds: tags?.map((tag) => tag.id),
    }
  } else {
    const { billId } = payment
    data = {
      billId,
    }
  }

  return await api(`/billing/payments/${payment?.id ?? ""}`, {
    method: payment?.id ? "PUT" : "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  })
}

interface DeletePaymentParams {
  id: number | undefined
}

export const deletePayment = async ({
  id,
}: DeletePaymentParams): Promise<void> => {
  return await api.delete(`/billing/payments/${id}`)
}

export async function billPayments(id: number) {
  return api.get<Array<TableIndexPayment>>(`/billing/bills/${id}/payments`)
}

export const externalPaymentsTypes = async (props: {
  hashedId: string
  accessCode: string
  featureType: string
}) => {
  return await api("/external/billing/payment_types", {
    headers: props,
  })
}

export function showPayment(id?: number) {
  return api.get(`/billing/payments/${id}`)
}

export function paymentTypeDatasList(paymentTypeId?: number) {
  return api.get(`billing/payment_types/${paymentTypeId}/data`)
}

export type EmailDataProps = {
  emailSendTo: string
  sender: string
  id: number
}

export function sendPaymentMail(emailData: EmailDataProps) {
  return api.post("billing/payments/send-email", emailData)
}

export function exportPayments(
  filters: NewFilterValues & { paidAt?: DateFilter },
  columns: string[],
) {
  const { paidAt, ...otherFilters } = filters

  return api("billing/payments/export_xlsx", {
    method: "POST",
    data: {
      filters: {
        ...otherFilters,
        ...(paidAt
          ? {
              paidAtStartDate: paidAt.initial,
              paidAtFinalDate: paidAt.final,
            }
          : {}),
      },
      columns,
    },
    responseType: "blob",
  })
}
