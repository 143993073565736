export function RepurposalInputOptions(
  repurposal: BaseIndexRepurposal,
  t: (key: string) => string,
) {
  const buyerName = repurposal?.buyer?.name ?? repurposal?.buyerDocument ?? "-"
  const supplierName =
    repurposal?.stock?.distributionCenter.supplier.name ?? "-"

  return (
    <>
      <div className="fw-bold">{repurposal?.code}</div>
      <div className=" d-flex gap-1 text-secondary">
        <div className="col-6 text-truncate">
          <strong>{t("Global.attributes.supplier.one") + ": "}</strong>
          <span title={supplierName}>{supplierName}</span>
        </div>
        <div className="col-6 text-truncate">
          <strong>{t("Global.attributes.buyer.one") + ": "}</strong>
          <span title={buyerName}>{buyerName}</span>
        </div>
      </div>
    </>
  )
}
