import { FormControl, FormGroup, FormLabel } from "react-bootstrap"
import { DropdownInput } from "../../../../components/DropdownInput"
import { t } from "i18next"
import { useDebounceCallback } from "usehooks-ts"
import { customersList } from "../../../../services"
import { useEffect, useState } from "react"

interface CheckinSupplierInputProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  setFormData: React.Dispatch<React.SetStateAction<CheckinModalFormData>>
  errors: CheckinModalErrors
  setValue: React.Dispatch<React.SetStateAction<SuppliersList | null>>
  value: SuppliersList | null
  show: boolean
}

export default function CheckinSupplierInput(props: CheckinSupplierInputProps) {
  const {
    setIsLoading,
    isLoading,
    setFormData,
    errors,
    setValue,
    value,
    show,
  } = props
  const [dropdownItems, setDropdownItems] = useState<SuppliersList[]>([])
  const debouncedSearchSupplier = useDebounceCallback(fetchSearchSuppliers, 500)

  async function fetchSearchSuppliers({ search = "" } = {}) {
    setIsLoading(true)
    try {
      const { data } = await customersList({
        search,
        itemsPerPage: 30,
        bond: "supplier",
      })
      setDropdownItems(data.customers)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (show) {
      fetchSearchSuppliers()
    }
  }, [show])

  return (
    <>
      <FormGroup className="mb-3">
        <FormLabel>
          {t("Global.attributes.supplier.one")}
          <span className="text-danger"> *</span>
        </FormLabel>
        <DropdownInput
          id="supplier"
          onChange={(e) => debouncedSearchSupplier({ search: e.target.value })}
          options={dropdownItems}
          isLoading={isLoading}
          renderName={(item) => (item ? item.name : "")}
          renderTitle={(item) => (item ? item.name : "")}
          onClick={(item) => {
            setFormData((prevData) => ({
              ...prevData,
              supplier: item ? item.id : "",
            }))
            setValue(item)
          }}
          value={value}
          select
        />
        <FormControl.Feedback type="invalid">
          {errors.supplierError && t("Global.alerts.requiredField")}
        </FormControl.Feedback>
      </FormGroup>
    </>
  )
}
