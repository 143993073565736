import { useEffect, useRef } from "react"
import { SkeletonContainer } from "./styles"
import { SkeletonProps } from "./skeleton"

export function Skeleton(props: SkeletonProps) {
  const {
    children = "",
    isloading,
    wFull,
    className = "",
    my = 1,
    removeDFlex,
    h,
    w,
    isImage,
  } = props
  const skeletonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const parentElement = skeletonRef.current?.parentElement
    if (parentElement) {
      const { width, height } = parentElement.getBoundingClientRect()
      skeletonRef.current.style.width = `${w || width}px`
      skeletonRef.current.style.height = `${h || height}px`
    }
  }, [])

  return (
    <>
      {isloading ? (
        <SkeletonContainer wFull={wFull} isImage={isImage}>
          <div
            title="Skeleton"
            id="Skeleton"
            ref={skeletonRef}
            className={`invisible text-transparent d-flex my-${my}`}
          >
            {children}
          </div>
        </SkeletonContainer>
      ) : (
        <div
          className={`${removeDFlex ? "" : "d-flex gap-2"} ${className} `}
          style={{
            width: props.wFull ? "100%" : "",
          }}
        >
          {children}
        </div>
      )}
    </>
  )
}
