import {
  getCountryCode,
  getCurrency,
  getIntLocale,
} from "../../hooks/useCountryConfigs/utils"

export function formatMoney(
  amount: number,
  location: Intl.Locale | undefined,
  currency: string | undefined,
  withDecimals: boolean = true,
  isNegative?: boolean,
) {
  location = location ?? getIntLocale(getCountryCode())
  currency = currency ?? getCurrency(getCountryCode())
  isNegative = isNegative ?? true

  const formatMoneyByCountry = amount.toLocaleString(location, {
    style: "currency",
    currency,
    ...(currency === "COP" || !withDecimals
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : {}),
  })

  return amount < 0 && isNegative
    ? `(-) ${formatMoneyByCountry.slice(1)}`
    : formatMoneyByCountry
}
