import { FilterMultiSelect } from "../FilterMultiSelect"
import { customersGroupsList } from "../../../../../../services/customersGroups.service"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useDebounceValue } from "usehooks-ts"
import { useCountryConfigs } from "../../../../../../utils/hooks/useCountryConfigs"

function defaultRenderValue(item: baseData) {
  return item.id ?? ""
}

function defaultRenderName(item: baseData) {
  return item.name ?? ""
}

function getNextPageParam(lastPage: SimpleIndexCustomersGroupQuery) {
  return lastPage.page + 1
}

export function FilterCustomersGroupsSelect(
  props: FilterCustomersGroupsSelectProps,
) {
  const {
    removeScroll,
    removeSearch,
    label,
    kind,
    renderValue = defaultRenderValue,
    renderName = defaultRenderName,
    ...otherConfigs
  } = props
  const [search, setSearch] = useDebounceValue("", 500)
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()

  async function getCustomersGroups({
    pageParam,
  }: {
    pageParam: number
  }): Promise<SimpleIndexCustomersGroupQuery> {
    const { data } = await customersGroupsList({
      search: search.trim(),
      serializer: "simple_index",
      kind,
      page: pageParam,
    })
    return {
      customersGroups: data.customersGroups,
      count: data.count,
      page: pageParam,
    }
  }

  const query = useInfiniteQuery({
    queryKey: ["customersGroups", "simple_index", kind, search, countryCode],
    queryFn: getCustomersGroups,
    initialPageParam: 1,
    getNextPageParam,
    staleTime: 5 * 60 * 1000,
  })

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value)
  }

  async function handleScroll() {
    query.fetchNextPage()
  }

  return (
    <FilterMultiSelect<baseData>
      renderName={renderName}
      renderValue={renderValue}
      disabled={props.disabled}
      options={query.data?.pages.flatMap((page) => page.customersGroups)}
      isLoading={query.isLoading}
      onChange={!removeSearch ? handleSearch : undefined}
      onScroll={!removeScroll ? handleScroll : undefined}
      count={query.data?.pages.slice(-1)[0].count}
      labelName={label}
      filterName={props.filterName}
      {...otherConfigs}
    />
  )
}
