import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { Trans, useTranslation } from "react-i18next"
import { createBill } from "../../../../../services/bills.service"
import { AxiosError, HttpStatusCode } from "axios"
import { ButtonSubmit } from "../../../../../components/Buttons/ButtonSubmit"
import { BILL_CREATE_MODAL_MODE_MAP } from "../../utils/billCreateModalModeMap"
import { MultiErrorAlerts } from "../../../../../components/MultiErrorAlerts"
import { CustomAlert } from "../../../../../components/Customs/CustomAlert"
import { BillDueInput } from "../Fields/BillDueInput"
import { BillAmountInput } from "../Fields/BillAmountInput"
import { BillRepurposalInput } from "../Fields/BillRepurposalInput"
import { BillFileInput } from "../Fields/BillFileInput"
import { BillPaymentTypeDataInput } from "./BillPaymentTypeDataInput"
import { captureException } from "@sentry/react"
import { BillMailInput } from "../Fields/BillMailInput"
import { viewRepurposal } from "../../../../../services/repurposal.service"
import { BillAdditionalInfo } from "../Fields/BillAdditionalInfo"
import { CustomLink } from "../../../../../components/Customs/CustomLink"
import BillKindInput from "../Fields/BillKindInput"
import { BillPaymentTypeInput } from "./BillPaymentTypeInput"
import { createUrlWithBill } from "../../utils/createUrlWithBill"

export function BillCreateModal(props: BillCreateModalProps) {
  const {
    bill,
    setBill,
    show,
    setShow,
    isEditMode,
    setSuccessMessage,
    setSuccess,
    setShowDeleteModal,
    variant = "success",
    repurposalCore,
  } = props
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>()
  const [billCustomerInfo, setBillCustomerInfo] = useState<BillCustomerInfo>()
  const [customerInfoIsLoading, setCustomerInfoIsLoading] = useState<boolean>()
  const [validated, setValidated] = useState<boolean>()
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const [sendMail, setSendMail] = useState<boolean>()
  const mode = BILL_CREATE_MODAL_MODE_MAP[isEditMode ? "update" : "create"]

  function handleClose() {
    setShow(false)
    setIsLoading(false)
    setValidated(false)
    setBill(undefined)
    setErrorMessages([])
    setBillCustomerInfo(undefined)
    setCustomerInfoIsLoading(false)
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (event.currentTarget.checkValidity() && bill) {
      setIsLoading(true)
      setErrorMessages([])
      try {
        const response = await createBill(bill)
        const data = response.data
        handleClose()
        setSuccess(true)
        setSuccessMessage(
          <Trans
            i18nKey={`Bills.alerts.${mode.title}.success`}
            values={{ item: data.id }}
          >
            <strong />
            <CustomLink
              className="d-inline"
              data-testid="visualize-bill"
              href={createUrlWithBill(data.id)}
            />
          </Trans>,
        )
      } catch (error) {
        if (error instanceof AxiosError) {
          const { response } = error
          response?.status === HttpStatusCode.InternalServerError
            ? setErrorMessages([t("Global.alerts.internalServerError")])
            : setErrorMessages(response?.data.messages)
        }
      } finally {
        setIsLoading(false)
      }
    } else {
      setValidated(true)
    }
  }

  async function getCustomerInfo() {
    setCustomerInfoIsLoading(true)
    try {
      const data = await viewRepurposal(
        String(bill?.repurposalCore?.code),
        undefined,
        "contact_and_payment_type",
      )
      setBillCustomerInfo(data)
      setSendMail(Boolean(data?.repurposalCore?.contact?.email))
      setCustomerInfoIsLoading(false)
    } catch (error) {
      console.error(error)
      captureException(error)
      if (error instanceof AxiosError) {
        const { response } = error
        response?.status === HttpStatusCode.InternalServerError
          ? setErrorMessages([t("Global.alerts.internalServerError")])
          : response?.data.messages && setErrorMessages(response?.data.messages)
      }
    } finally {
      setCustomerInfoIsLoading(false)
    }
  }

  function handleOpen() {
    setSuccess(false)
    setSuccessMessage(<></>)
    isEditMode && setSendMail(false)
  }

  useEffect(() => {
    if (show) {
      !isEditMode &&
        repurposalCore &&
        setBill((prev) => ({
          ...prev,
          repurposalCore,
        }))
    }
  }, [show])

  useEffect(() => {
    bill?.repurposalCore && getCustomerInfo()
  }, [bill?.repurposalCore])

  return (
    <>
      {!isEditMode && (
        <Button
          onClick={() => setShow(true)}
          variant={variant}
          data-testid="create-bill-button"
        >
          {t("Bills.actions.create")}
        </Button>
      )}
      <Modal
        show={show}
        onShow={handleOpen}
        onHide={handleClose}
        size="lg"
        data-testid="create-bill-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title data-testid="create-bill-modal-title">
            {t(`Bills.actions.${mode.title}`)}
          </Modal.Title>
        </Modal.Header>
        <Form validated={validated} noValidate onSubmit={handleSubmit}>
          <Modal.Body>
            {errorMessages.length > 0 && (
              <MultiErrorAlerts items={errorMessages} />
            )}
            <CustomAlert
              onClose={() => setValidated(false)}
              variant="danger"
              show={validated}
              dismissible={true}
              data-testid="alertRequiredFields"
            >
              {t("Global.alerts.emptyFields")}
            </CustomAlert>
            <BillDueInput bill={bill} setBill={setBill} />
            <BillAmountInput bill={bill} setBill={setBill} />
            <BillRepurposalInput
              bill={bill}
              setBill={setBill}
              hasDefaultValue={!!repurposalCore}
            />
            <BillKindInput bill={bill} setBill={setBill} />
            {!isEditMode &&
              bill?.repurposalCore?.id &&
              (customerInfoIsLoading || billCustomerInfo?.contact?.email) && (
                <BillMailInput
                  sendMail={sendMail}
                  setSendMail={setSendMail}
                  customerMail={billCustomerInfo?.contact?.email}
                  customerInfoIsLoading={customerInfoIsLoading}
                />
              )}
            <BillPaymentTypeInput
              bill={bill}
              setBill={setBill}
              repurposalPaymentType={billCustomerInfo?.paymentType}
              customerInfoIsLoading={customerInfoIsLoading}
            />
            <BillFileInput bill={bill} setBill={setBill} />
            <BillPaymentTypeDataInput bill={bill} setBill={setBill} />
            <BillAdditionalInfo bill={bill} setBill={setBill} />
          </Modal.Body>
          <Modal.Footer>
            {isEditMode && setShowDeleteModal && (
              <Button
                variant="outline-danger"
                className="me-auto"
                onClick={() => {
                  setShow(false)
                  setShowDeleteModal(true)
                }}
                data-testid="delete-button"
              >
                {t("Global.actions.remove")}
              </Button>
            )}
            <Button variant="outline-secondary" onClick={handleClose}>
              {t("Global.actions.cancel")}
            </Button>
            <ButtonSubmit
              variant={mode.variant}
              type="submit"
              name={t(`Global.actions.${mode.submitName}`)}
              loading={isLoading}
              disabled={isLoading}
              data-testid="submit-button"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
