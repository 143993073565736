import { ReactNode, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import {
  BsCheckCircleFill,
  BsClockFill,
  BsDashCircleFill,
} from "react-icons/bs"
import { capitalizeText } from "../../../../../utils/Format"
import { createBill } from "../../../../../services/bills.service"
import { camelCase } from "lodash"
import { EditStatusModal } from "../../../../../components/Modal/EditStatusModal/EditStatusModal"
import { createUrlWithBill } from "../../utils/createUrlWithBill"
import { CustomLink } from "../../../../../components/Customs/CustomLink"
import { Form } from "react-bootstrap"
import { Label } from "../../../../../components/Form/formComponents/Label"

const BILL_STATUS_BADGES_MAP: Record<
  BillStatus,
  {
    variant: string
    icon: ReactNode
    className?: string
  }
> = {
  waiting_for_payment: {
    variant: "warning",
    className: "text-dark",
    icon: <BsClockFill />,
  },
  canceled: {
    variant: "secondary",
    icon: <BsDashCircleFill />,
  },
  confirmed: {
    variant: "success",
    icon: <BsCheckCircleFill />,
  },
}

const AVAILABLE_STATUS: Array<BillStatus> = [
  "waiting_for_payment",
  "confirmed",
  "canceled",
]

interface BillStatusCellProps {
  bill: Bill
  setSuccess: React.Dispatch<React.SetStateAction<boolean | undefined>>
  setSuccessMessage: React.Dispatch<React.SetStateAction<React.ReactNode>>
}

export function BillStatusCell(props: BillStatusCellProps) {
  const { bill, setSuccess, setSuccessMessage } = props
  const { t } = useTranslation()
  const [status, setStatus] = useState<BillStatus | undefined>(bill.status)
  const [receiptedAt, setReceiptedAt] = useState<string>(bill.receiptedAt || "")
  const baseId = `bill-${bill.id}-status-${bill.status}`

  async function handleSubmit(status: BillStatus) {
    const { data } = await createBill({
      id: bill.id,
      status,
      receiptedAt: status === "confirmed" ? receiptedAt : "",
    })
    setSuccess(true)
    setSuccessMessage(
      <Trans i18nKey={"Bills.alerts.update.success"} values={{ item: data.id }}>
        <strong />
        <CustomLink
          className="d-inline"
          data-testid="visualize-bill"
          href={createUrlWithBill(data.id)}
        />
      </Trans>,
    )
  }

  function handleShow() {
    setSuccess(false)
  }

  function handleClose() {
    setReceiptedAt(bill.receiptedAt || "")
  }

  return (
    <EditStatusModal<BillStatus>
      title={capitalizeText(
        t("Bills.attributes.billItem", {
          item: t("Global.attributes.status").toLowerCase(),
        }),
      )}
      renderName={(status) =>
        t(`Bills.attributes.status.${camelCase(status)}.full`)
      }
      badgeName={t(`Bills.attributes.status.${camelCase(bill.status)}.abbr`)}
      badgeMap={BILL_STATUS_BADGES_MAP}
      statusOptions={AVAILABLE_STATUS}
      onSubmit={handleSubmit}
      originalStatus={bill.status}
      data-testid={baseId}
      status={status}
      setStatus={setStatus}
      onClose={handleClose}
      onShow={handleShow}
      additionalFields={
        status === "confirmed" && (
          <Form.Group className="mb-3" controlId="bil-receipted-at">
            <Label>{t("Bills.attributes.receipt")}</Label>
            <Form.Control
              type="date"
              value={receiptedAt}
              onChange={(event) => setReceiptedAt(event.target.value)}
            />
          </Form.Group>
        )
      }
    />
  )
}
