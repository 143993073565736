import { FormGroup, FormLabel, InputGroup } from "react-bootstrap"
import { WeightInput } from "../../../../utils/Format/WeightInput"
import { t } from "i18next"

interface CheckinWeightInputsProps {
  setFormData: React.Dispatch<React.SetStateAction<CheckinModalFormData>>
  formData: CheckinModalFormData
}

export default function CheckinWeightInputs(props: CheckinWeightInputsProps) {
  const { setFormData, formData } = props

  const handleWeightInputChange = (
    name: string,
    _weight: number | undefined,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: _weight !== undefined ? _weight.toString() : "",
    }))
  }

  return (
    <>
      <FormGroup className="mb-3">
        <FormLabel>{t("Global.attributes.grossWeight")}</FormLabel>
        <InputGroup>
          <WeightInput
            value={Number(formData.grossWeight)}
            name="grossWeight"
            onChange={(value) => handleWeightInputChange("grossWeight", value)}
            datatestid="grossWeight"
          />
          <InputGroup.Text>kg</InputGroup.Text>
        </InputGroup>
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel>{t("Global.attributes.netWeight")}</FormLabel>
        <InputGroup>
          <WeightInput
            name="netWeight"
            value={Number(formData.netWeight)}
            onChange={(value) => handleWeightInputChange("netWeight", value)}
            datatestid="netWeight"
          />
          <InputGroup.Text>kg</InputGroup.Text>
        </InputGroup>
      </FormGroup>
    </>
  )
}
