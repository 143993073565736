interface IDetermineSenderAndReceiver {
  sender: SupplierProps | undefined
  receiver: SupplierProps | undefined
}

export const determineSenderAndReceiver = (
  bill: Partial<SimpleIndexBill>,
): IDetermineSenderAndReceiver => {
  const customers: { supplier?: SupplierProps; buyer?: SupplierProps } = {
    buyer: bill.repurposalCore?.buyer,
  }
  if (customers.buyer?.document && bill.repurposalCore?.buyerDocument) {
    customers.buyer.document = bill.repurposalCore?.buyerDocument
  }

  bill.repurposalCore?.negotiationKind === "supplier_to_gooxxy"
    ? (customers.supplier = bill.repurposalCore?.stock.checkins?.[0]?.supplier)
    : (customers.supplier =
        bill.repurposalCore?.stock.distributionCenter.supplier)

  return bill.kind === "standard"
    ? { sender: customers.supplier, receiver: customers.buyer }
    : { sender: customers.buyer, receiver: customers.supplier }
}
