import React, { ReactNode, useEffect, useState } from "react"

import { useExternalRecolocation } from "../../utils/hooks/useExternalRecolocation"
import { useParams } from "react-router-dom"
import { t } from "i18next"
import {
  CountryCode,
  useCountryConfigs,
} from "../../utils/hooks/useCountryConfigs"
import * as Sentry from "@sentry/browser"
import PrivateComponent from "../PrivateComponent"

interface PrivateProps {
  children: ReactNode
}

export function PrivateIsBuyerRepurposal({
  children,
}: PrivateProps): JSX.Element {
  const { id, country } = useParams()
  const [isActive, setIsActive] = useState(false)
  const {
    countryCode: [, setCountryCode],
  } = useCountryConfigs()
  const [isError, setIsError] = useState(false)
  const [inputValues, setInputValues] = useState(["", "", "", ""])
  const [isDisabled, setIsDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const { externalRecolocation, messageFeedback } = useExternalRecolocation()

  useEffect(() => {
    const countryCode: CountryCode = (country as CountryCode) ?? "BR"
    countryCode && setCountryCode(countryCode)
  }, [country])

  const delay = (amount = 750) =>
    new Promise((resolve) => setTimeout(resolve, amount))

  const verify = async () => {
    const value = inputValues.join("")
    if (value.length !== 4) {
      setIsDisabled(true)
    }
    try {
      setLoading(true)
      const response = await externalRecolocation({
        code: value,
        hash: id as string,
      })

      if (response) {
        setIsActive(true)
      }

      await delay()
    } catch (error) {
      setIsError(true)
      Sentry.captureException(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setLoading(true)
      verify()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <PrivateComponent
      handleSubmit={handleSubmit}
      messageFeedback={messageFeedback}
      inputValues={inputValues}
      setInputValues={setInputValues}
      setIsError={setIsError}
      isActive={isActive}
      isDisabled={isDisabled}
      isError={isError}
      buttonText={t("Repurposal.attributes.accessOpportunities")}
      loading={loading}
    >
      {children}
    </PrivateComponent>
  )
}

export default PrivateIsBuyerRepurposal
