import React from "react"
import { Button, Spinner } from "react-bootstrap"

type ButtonSubmitProps = {
  variant?: "success" | "primary" | "outline-primary"
  name?: React.ReactNode
  disabled?: boolean
  onClick?: () => void
  type?: "button" | "submit" | "reset"
  className?: string
  loading?: boolean
  "data-testid"?: string
  size?: "sm" | "lg"
}

export function ButtonSubmit(props: ButtonSubmitProps) {
  const { className, disabled, loading, name, onClick, type, variant, size } =
    props
  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={onClick}
      type={type}
      variant={variant}
      data-testid={props["data-testid"]}
      size={size}
    >
      {loading ? <Spinner size="sm" /> : name}
    </Button>
  )
}
