import { isFunction } from "lodash"
import React, { Fragment } from "react"

export function CustomTableRow<T>(props: CustomTableRowProps<T>) {
  const { item, index, columns, rowId, rowClassName } = props

  const handleClick = (link?: string) => {
    if (link) {
      window.open(link, "_blank")
    }
  }

  return (
    <>
      {columns.map((column: Column<T>) => {
        const content = column.field(item, index)
        return (
          <Fragment key={`${rowId}-column-${column.name}`}>
            {!column.isNoVisible && (
              <td
                className={`text-${column.direction ?? "start"} ${
                  isFunction(column.tdClassName)
                    ? column.tdClassName(item)
                    : (column.tdClassName ?? "")
                } ${rowClassName ?? ""}`}
                title={`${(column.title && column.title(item)) ?? ""}`}
                data-testid={
                  column["td-data-testid"] &&
                  column["td-data-testid"](item, index)
                }
                onClick={() => {
                  if (!column.link) return
                  handleClick(column.link(item))
                }}
                style={{ cursor: column.link ? "pointer" : "default" }}
              >
                {content ?? "-"}
              </td>
            )}
          </Fragment>
        )
      })}
    </>
  )
}
