import { useTranslation } from "react-i18next"
import { PaymentTypeInput } from "../../../../../components/Form/formComponents/PaymentTypeInput"
import { useState } from "react"

export function BillPaymentTypeInput(
  props: DefaultBillField & {
    repurposalPaymentType?: PaymentType
    customerInfoIsLoading?: boolean
  },
) {
  const { bill, setBill, repurposalPaymentType, customerInfoIsLoading } = props
  const { t } = useTranslation()
  const [showSuggestion, setShowSuggestion] = useState(true)

  function handleAcceptSuggestion() {
    setBill((bill) => ({
      ...bill,
      paymentType: repurposalPaymentType,
    }))
    setShowSuggestion(false)
  }

  return (
    <PaymentTypeInput
      label={t("Receipts.attributes.paymentMethod.full")}
      value={bill?.paymentType}
      onClick={(paymentType) => {
        setBill((bill) => ({
          ...bill,
          paymentType,
        }))
      }}
      required
      data-testid="bill-part-payment-type"
      additionalContent={
        showSuggestion && (
          <small className="text-secondary">
            <strong>{t("BillParts.attributes.paymentTypeSuggestion")}:</strong>{" "}
            {customerInfoIsLoading ? (
              t("Global.actions.loading")
            ) : (
              <>
                {repurposalPaymentType ? (
                  <>
                    <span>{repurposalPaymentType?.name} </span>(
                    <u
                      className="cursor-pointer text-primary"
                      onClick={handleAcceptSuggestion}
                    >
                      {t("BillParts.actions.chooseThisPaymentMethod")}
                    </u>
                    )
                  </>
                ) : (
                  <span>{t("Global.alerts.NotIdentified")}</span>
                )}
              </>
            )}
          </small>
        )
      }
    />
  )
}
