import { useTranslation } from "react-i18next"
import { format, isToday } from "date-fns"
import { useCountryConfigs } from "../../../utils/hooks/useCountryConfigs"

export function AuditField(props: Audit) {
  const { user, createdAt } = props
  const { dateLocale } = useCountryConfigs()
  const { t } = useTranslation()

  const formatedDate = createdAt
    ? format(
        createdAt,
        isToday(createdAt)
          ? `'${t("Global.attributes.today")}' h:mm aaaa`
          : "MMMM d, yyyy h:mm aaaa",
        {
          locale: dateLocale,
        },
      )
        .replace("a.m.", "AM")
        .replace("p.m.", "PM")
    : "-"

  return (
    <div className="d-flex flex-column">
      <span data-testid="auditUserName">{user?.name ?? "-"}</span>
      <span data-testid="auditDate">{formatedDate}</span>
    </div>
  )
}
