import { Skeleton } from "../../../../components/Skeleton"

export function FinancialCopyLine(props: FinancialCopyLine) {
  const { label, value, isLoading } = props
  return (
    <Skeleton isloading={isLoading} my={0} removeDFlex>
      {label && (
        <span
          className="fw-bold"
          data-testid={`${props["data-testid"]}-label`}
        >{`${label}: `}</span>
      )}
      <span data-testid={`${props["data-testid"]}-value`}>{value}</span>
    </Skeleton>
  )
}
