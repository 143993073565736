import { Form } from "react-bootstrap"
import { t } from "i18next"
import { useCountryConfigs } from "../../utils/hooks/useCountryConfigs"

interface PhoneInputProps {
  validatedForm?: boolean
  dataTestId?: string
  phone: string
  setPhone: React.Dispatch<React.SetStateAction<string>>
  disabled?: boolean
  controlId?: string
  showLabel?: boolean
}

export default function PhoneInput({
  validatedForm,
  dataTestId,
  phone,
  setPhone,
  disabled,
  controlId = "phone-input",
  showLabel = true,
}: PhoneInputProps) {
  const { configs } = useCountryConfigs()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let formattedNumber = event.target.value.replace(/\D/g, "")
    const phoneConfig = configs.phoneMask

    if (
      formattedNumber.length > phoneConfig.formattedNumber.phone.length &&
      phoneConfig.formattedNumber.phone.length > 0
    ) {
      return
    }

    formattedNumber = formattedNumber.replace(
      phoneConfig.formattedNumber.phone.regex,
      phoneConfig.formattedNumber.phone.format,
    )

    setPhone(formattedNumber)
  }

  return (
    <Form.Group className="mb-3" controlId={controlId}>
      {showLabel && (
        <Form.Label>
          {t("Global.attributes.phone")} <span className="text-danger"> *</span>
        </Form.Label>
      )}
      <Form.Control
        type="tel"
        value={phone}
        onChange={handleChange}
        placeholder={t("Global.attributes.subTitlePhone")!}
        required={validatedForm}
        isInvalid={
          validatedForm &&
          phone.length < configs.phoneMask.formattedNumber.phone.length
        }
        data-testid={dataTestId}
        disabled={disabled}
      />
      {validatedForm &&
        phone.length < configs.phoneMask.formattedNumber.phone.length && (
          <Form.Text className="text-danger">
            {t("Global.alerts.errorPhoneInput")}
          </Form.Text>
        )}
    </Form.Group>
  )
}
