import { useCountryConfigs } from "../../hooks/useCountryConfigs"

export default function formatPhoneNumber(
  value: string | undefined,
  useWhatsApp?: boolean,
) {
  const { configs } = useCountryConfigs()

  if (!value) return ""

  const formattedPhoneNumber = value.replace(
    configs.phoneMask.formattedNumber.phone.maskFormat.regex,
    configs.phoneMask.formattedNumber.phone.maskFormat.format,
  )

  const phoneNumber = useWhatsApp
    ? formattedPhoneNumber.replace(/\D/g, "")
    : formattedPhoneNumber

  if (useWhatsApp) {
    return (
      <a
        href={`https://wa.me/${phoneNumber}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {formattedPhoneNumber}
      </a>
    )
  }

  return formattedPhoneNumber
}
