import { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../components/Form/formComponents/Label"
import { DropdownInput } from "../../../../../components/DropdownInput"
import formatPhoneNumber from "../../../../../utils/Format/FormatPhoneNumber"
import { ButtonSubmit, Skeleton } from "@components"
import { capitalizeText } from "../../../../../utils/Format"
import { createBill } from "../../../../../services/bills.service"
import { captureException } from "@sentry/react"
import { AxiosError } from "axios"
import { MultiErrorAlerts } from "../../../../../components/MultiErrorAlerts"
import PhoneInput from "../../../../../components/PhoneInput"
import { createCustomerContact } from "@services"
import { useCountryConfigs } from "../../../../../utils/hooks/useCountryConfigs"

export function BillPhoneModal(props: BillPhoneModal) {
  const {
    bill,
    setBill,
    show,
    setShow,
    link,
    repurposalContact,
    repurposalContactIsLoading,
    contacts,
    contactsAreLoading,
    setSuccess,
    setSuccessMessage,
  } = props
  const { t } = useTranslation()
  const { configs } = useCountryConfigs()
  const [contact, setContact] = useState<Contact>()
  const [validated, setValidated] = useState(false)
  const [showSuggestion, setShowSuggestion] = useState(true)
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const [isUpdating, setIsUpdating] = useState(false)
  const [enableNewContact, setEnableNewContact] = useState(false)
  const [phone, setPhone] = useState("")

  function handleClose() {
    setShow(false)
    setBill(undefined)
    setContact(undefined)
    setPhone("")
    setErrorMessages([])
    setValidated(false)
    setIsUpdating(false)
  }

  function handleShow() {
    setSuccess(false)
    setSuccessMessage(false)
    setShowSuggestion(true)
    setEnableNewContact(false)
  }

  function setSuggestion() {
    setContact(repurposalContact)
    setShowSuggestion(false)
  }

  function showError(error: Error) {
    console.error(error)
    captureException(error)
    error instanceof AxiosError &&
      error.response?.data?.messages &&
      setErrorMessages(error.response?.data?.messages)
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    let phoneToShare = contact?.phone

    event.preventDefault()
    if (event.currentTarget.checkValidity() && bill) {
      try {
        setIsUpdating(true)
        await createBill({
          id: bill.id,
          billSentAt: new Date().toISOString(),
        })

        if (enableNewContact) {
          const cleanPhone = (
            configs.phoneMask.phoneCountryCode + phone
          ).replace(/\D/g, "")

          await createCustomerContact(
            bill.repurposalCore.buyer.id,
            cleanPhone,
            "bills",
          )
          phoneToShare = cleanPhone
        }

        const whatappLink = t("BillParts.attributes.whatsappLink", {
          link,
          phone: phoneToShare,
          repurposalCode: bill?.repurposalCore.code,
          accessCode: bill?.externalAccessControl?.accessCode,
        })
        window.open(whatappLink, "_blank")
        setSuccess(true)
        setSuccessMessage(t("Bills.alerts.shareBill.whatsapp"))
        handleClose()
      } catch (error) {
        showError(error as Error)
      } finally {
        setIsUpdating(false)
      }
    } else setValidated(true)
  }

  function showNumber() {
    return repurposalContact ? (
      <>
        {formatPhoneNumber(repurposalContact.phone)}
        {" ("}
        <button
          className="border-0 p-0 bg-white text-decoration-underline d-inline text-primary"
          onClick={setSuggestion}
          type="button"
        >
          {t("BillParts.actions.choseThisContatc")}
        </button>
        {")"}
      </>
    ) : (
      t("Global.alerts.NotIdentified")
    )
  }

  return (
    <Modal show={show} onHide={handleClose} onShow={handleShow} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Bills.actions.shareBillViaWhatsapp")}</Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          {errorMessages.length > 0 && (
            <MultiErrorAlerts items={errorMessages} />
          )}
          <Form.Group controlId="phone-input" className="mb-3">
            <Label required={!enableNewContact}>
              {capitalizeText(t("Global.attributes.customerContactsList"))}
              {" ("}
              {capitalizeText(t("Global.attributes.buyer.one"))}
              {")"}
            </Label>
            <DropdownInput
              onClick={(contact) => setContact(contact)}
              renderName={(contact) =>
                formatPhoneNumber(contact.phone) as string
              }
              options={contacts ?? []}
              isLoading={contactsAreLoading}
              value={contact}
              required={!enableNewContact}
              disabled={enableNewContact}
              select
            />
            {showSuggestion && (
              <small className="text-secondary">
                <strong>
                  {t("BillParts.attributes.paymentTypeSuggestion")}:
                </strong>{" "}
                {repurposalContactIsLoading ? (
                  <Skeleton isloading my={0} wFull />
                ) : (
                  showNumber()
                )}
              </small>
            )}
          </Form.Group>
          <Form.Check
            type="checkbox"
            label={t("Bills.actions.enableNewContact")}
            id="enable-new-contact"
            onChange={() => setEnableNewContact(!enableNewContact)}
          />
          <PhoneInput
            phone={phone}
            setPhone={setPhone}
            disabled={!enableNewContact}
            showLabel={false}
          />
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button variant="outline-secondary" onClick={handleClose}>
            {t("Global.actions.cancel")}
          </Button>
          <ButtonSubmit
            variant="success"
            type="submit"
            name={t("Global.actions.confirm")}
            loading={isUpdating}
            disabled={isUpdating}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
