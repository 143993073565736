import { useInfiniteQuery } from "@tanstack/react-query"
import { DropdownInput } from "../../../DropdownInput"
import { useTranslation } from "react-i18next"
import { useDebounceValue } from "usehooks-ts"
import { useCountryConfigs } from "../../../../utils/hooks/useCountryConfigs"
import { paymentsList } from "@services"
import { CodeAndCustomersOptions } from "../CodeAndCustomersOptions/CodeAndCustomersOptions"

const ITEMS_PER_PAGE = 20

function getNextPageParam(lastPage: SimpleIndexPaymentQuery) {
  return Math.ceil(lastPage.count / ITEMS_PER_PAGE) > lastPage.page
    ? lastPage.page + 1
    : undefined
}

export function PaymentInput(props: PaymentInputProps) {
  const { value, onClick, id, required, disabled, repurposalCoreCode } = props
  const { t } = useTranslation()
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()
  const [search, setSearch] = useDebounceValue("", 500)

  async function findPayments({
    pageParam,
  }: {
    pageParam: number
  }): Promise<SimpleIndexPaymentQuery> {
    const { data } = await paymentsList({
      pageNumber: pageParam,
      itemsPerPage: ITEMS_PER_PAGE,
      search: search,
      context: "simple_index",
      repurposalCode: repurposalCoreCode,
    })
    return {
      payments: data.payments,
      count: data.count,
      page: pageParam,
    }
  }

  const { data, isLoading, fetchNextPage } = useInfiniteQuery({
    queryKey: [
      "paymentss",
      "simple_index",
      countryCode,
      { repurposalCoreCode },
      search,
    ],
    queryFn: findPayments,
    initialPageParam: 1,
    getNextPageParam,
    staleTime: 1 * 60 * 1000,
  })

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value)
  }

  async function handleScroll() {
    fetchNextPage()
  }

  return (
    <DropdownInput<SimpleIndexPayment>
      renderOption={(payment) => (
        <CodeAndCustomersOptions
          code={payment.id}
          firstCustomerLabel={t("Receipts.attributes.payer").toString()}
          firstCustomer={{
            ...payment.buyer,
            document: payment.buyerDocument,
          }}
          secondCustomerLabel={t("Receipts.attributes.favored").toString()}
          secondCustomer={{
            ...payment.supplier,
            document: payment.supplierDocument,
          }}
        />
      )}
      options={data?.pages.flatMap((page) => page.payments) ?? []}
      renderName={(payment) => payment.id.toString()}
      count={data?.pages.slice(-1)[0].count}
      onChange={handleSearch}
      onScroll={handleScroll}
      isLoading={isLoading}
      disabled={disabled}
      required={required}
      onClick={onClick}
      value={value}
      id={id}
      select
    />
  )
}
