import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export function CopyButton(props: React.PropsWithChildren<CopyButtonProps>) {
  const { isLoading, variant = "outline-primary", ...otherProps } = props
  const { t } = useTranslation()

  return (
    <OverlayTrigger
      overlay={
        <Tooltip>
          {isLoading
            ? t("Global.actions.copying")
            : t("Global.attributes.copyData")}
        </Tooltip>
      }
    >
      <Button variant={variant} disabled={isLoading} {...otherProps} />
    </OverlayTrigger>
  )
}
