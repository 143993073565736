import { t } from "i18next"
import {
  getCountryCode,
  getLanguage,
} from "../../../../utils/hooks/useCountryConfigs/utils"

interface Reasons {
  returnReason: string | undefined
  cancellationReason: string | undefined
  aditionalInfo: string | undefined
}

export function findReasonsInInvoice(
  additionalInfo: string | undefined,
): Reasons {
  const language = getLanguage(getCountryCode())
  const returnRegex = new RegExp(
    `${t("Invoice.attributes.returnReason", { lng: language })}: `,
  )
  const cancellationRegex = new RegExp(
    `${t("Invoice.attributes.cancellationReason", { lng: language })}: `,
  )

  let cancellationReason, returnReason, otherInfos

  const values = additionalInfo?.split("\n")

  values?.forEach((value) => {
    if (cancellationRegex.test(value)) {
      cancellationReason = value.replace(cancellationRegex, "")
    } else if (returnRegex.test(value)) {
      returnReason = value.replace(returnRegex, "")
    } else {
      otherInfos = value
    }
  })

  return {
    returnReason,
    cancellationReason,
    aditionalInfo: otherInfos,
  }
}
