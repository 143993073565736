export const BILL_CREATE_MODAL_MODE_MAP: Record<
  "update" | "create",
  {
    title: string
    variant: "primary" | "success"
    submitName: string
  }
> = {
  update: {
    title: "update",
    variant: "primary",
    submitName: "save",
  },
  create: {
    title: "create",
    variant: "success",
    submitName: "register",
  },
}
