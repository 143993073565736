import { BrowserRouter } from "react-router-dom"
import { RecoilRoot } from "recoil"
import i18n from "../i18n/i18n"
import { I18nextProvider } from "react-i18next"
import AuthProvider from "../context/AuthContext"
import BuyerProvider from "../context/ExternalRecolocationContext"
import { ToastContainer } from "react-toastify"

import CatalogProvider from "../context/CatalogContext"
import { CountryConfigsProvider } from "../utils/hooks/useCountryConfigs"
import RepurposalProvider from "../context/RepurposalContext"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import ToastProvider from "./ToastProvider"
import { FeedbackProvider } from "../context/FeedbackContext/FeedbackContext"
import BuyerApprovalProvider from "../context/ExternalBuyerApproval"

interface ProviderProps {
  children: React.ReactNode
}
export default function Provider({ children }: ProviderProps) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 10000,
      },
    },
  })

  return (
    <I18nextProvider i18n={i18n}>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        style={{ width: "350px", padding: "0.25rem 0" }}
      />
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <FeedbackProvider>
              <CatalogProvider>
                <CountryConfigsProvider>
                  <RepurposalProvider>
                    <BuyerProvider>
                      <BuyerApprovalProvider>
                        <AuthProvider>
                          <RecoilRoot>{children}</RecoilRoot>
                        </AuthProvider>
                      </BuyerApprovalProvider>
                    </BuyerProvider>
                  </RepurposalProvider>
                </CountryConfigsProvider>
              </CatalogProvider>
            </FeedbackProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ToastProvider>
    </I18nextProvider>
  )
}
