import { FormGroup } from "react-bootstrap"
import { FilterMultiSelect } from "../../../../components/Filters/NewFilter/components/FilterTypes/FilterMultiSelect"
import { convertSolutions } from "../../../../utils/gooxxySolutionsEnum"
import { t } from "i18next"
import { listSolutions } from "../../../../services"
import { useEffect, useState } from "react"

interface CheckinSolutionInputProps {
  solution: Record<string, string[]>
  setSolution: React.Dispatch<React.SetStateAction<Record<string, string[]>>>
  show: boolean
}

export default function CheckinSolutionInput(props: CheckinSolutionInputProps) {
  const { solution, setSolution, show } = props
  const [solutionOptions, setSolutionOptions] = useState(
    [] as Record<string, string>[],
  )

  async function fetchSolutions() {
    try {
      const solutionOptions = await listSolutions()
      const optionsArray = solutionOptions.data.gooxxySolutions.map(
        (solution: gooxxySolutions) => {
          return {
            id: String(solution.id),
            code: solution.code as string,
          }
        },
      )
      setSolutionOptions(optionsArray as Record<string, string>[])
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (show) {
      fetchSolutions()
    }
  }, [show])

  return (
    <>
      <FormGroup className="mb-3">
        <FilterMultiSelect
          options={solutionOptions}
          filter={solution}
          filterName="solutions"
          labelName={t("StockCheckin.attributes.solutionType")}
          setFilter={setSolution}
          renderName={(item) => convertSolutions(item?.code || "-")}
          renderValue={(item) => item.id}
          select
        />
      </FormGroup>
    </>
  )
}
