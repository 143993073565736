import { useEffect, useState } from "react"
import { Modal, ModalProps } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"

export function FinancialModal(
  props: ModalProps & {
    financialId: number | undefined
    setFinancialId: React.Dispatch<React.SetStateAction<number | undefined>>
    setShow: React.Dispatch<React.SetStateAction<boolean | undefined>>
    showModalInParams?: boolean
  },
) {
  const {
    financialId,
    setFinancialId,
    show,
    setShow,
    onHide,
    onShow,
    showModalInParams = true,
    ...otherProps
  } = props
  const [params, setParams] = useSearchParams()
  const [isShowing, setIsShowing] = useState(false)
  useEffect(() => {
    const idParams = params.get("financialId")
    if (showModalInParams && idParams) {
      setFinancialId(Number(idParams))
      setShow(true)
    }
  }, [params])

  useEffect(() => {
    if (show) {
      onShowProxxy()
    } else if (isShowing) {
      onHideProxxy()
    }
  }, [show, isShowing])

  function onHideProxxy() {
    const newParams = new URLSearchParams(window.location.search)
    newParams.delete("financialId")
    setParams(newParams)
    setIsShowing(false)
  }

  function onShowProxxy() {
    if (showModalInParams && financialId) {
      const newParams = new URLSearchParams(window.location.search)
      newParams.set("financialId", String(financialId))
      setParams(newParams)
      setIsShowing(true)
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      onShow={onShow}
      {...otherProps}
      fullscreen
    />
  )
}
