import { api } from "./api"

export function deliveriesList(props: DeliveriesListProps) {
  const { signal, filters, ...otherProps } = props
  const { estimatedDeliveryAt, ...otherFilters } = filters

  return api.get("logistics/deliveries", {
    signal,
    params: {
      itemsPerPage: 20,
      ...otherProps,
      ...otherFilters,
      startEstimatedDeliveryAt: estimatedDeliveryAt?.initial,
      endEstimatedDeliveryAt: estimatedDeliveryAt?.final,
    },
  })
}

export function editDelivery(delivery: EditableDelivery) {
  const { id, repurposalCore, palletKinds, vehicle, tags, ...otherFields } =
    delivery

  return api(`logistics/deliveries/${id ?? ""}`, {
    method: id ? "PUT" : "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      ...otherFields,
      repurposalCoreId: repurposalCore?.id,
      palletKindIds: palletKinds?.map((pallet) => pallet.id),
      vehicleId: vehicle?.id,
      tagIds: tags?.map((tag) => tag.id),
    },
  })
}

export function showDelivery(
  id?: number,
  context?: "show" | "attachment_files",
) {
  return api.get(`logistics/deliveries/${id}?context=${context ?? "show"}`)
}

export async function listVehicles() {
  return api.get("logistics/vehicles")
}

export async function deleteDelivery(id?: number) {
  return api.delete(`logistics/deliveries/${id}`)
}

export function getDeliveryInvoices(id?: number) {
  return api.get(`logistics/deliveries/${id}/invoices`)
}

export function getDeliveryRepurposalSuggestions(repurposalCoreId?: number) {
  return api.get(`repurposals/cores/${repurposalCoreId}/versions/current`)
}

export function bindDeliveryToInvoice(invoiceId?: number, deliveryId?: number) {
  return api.post(`billing/invoices/${invoiceId}/bind/deliveries/${deliveryId}`)
}

export function unbindDeliveryToInvoice(invoiceId?: number) {
  return api.delete(`billing/invoices/${invoiceId}/bind/deliveries`)
}

export function eportDeliveries(
  filters: NewFilterValues & { estimatedDeliveryAt?: DateFilter },
  columns: string[],
) {
  const { estimatedDeliveryAt, ...otherFilters } = filters

  return api("logistics/deliveries/export_xlsx", {
    method: "POST",
    data: {
      filters: {
        ...otherFilters,
        startEstimatedDeliveryAt: estimatedDeliveryAt?.initial,
        endEstimatedDeliveryAt: estimatedDeliveryAt?.final,
      },
      columns,
    },
    responseType: "blob",
  })
}
