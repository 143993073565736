import React, { ReactNode } from 'react';

interface InvoiceProps {
  item: Invoice;
  children: ReactNode;
}

export function InvoiceStatusDecorations({ item, children }: InvoiceProps) {
  function decorationTextStatus(item: Invoice) {
    return item.status === "waiting_for_identification" ||
      item.status === "confirmed"
      ? "text-decoration-none"
      : item.status === "canceled"
      ? "text-decoration-line-through"
      : "";
  }

  function variantTextStatus(item: Invoice) {
    return item.status === "waiting_for_identification" ||
      item.status === "confirmed"
      ? "none"
      : item.status === "canceled"
      ? "secondary"
      : "";
  }

  const decorationClass = decorationTextStatus(item);
  const variantClass = variantTextStatus(item);

  return (
    <div className={`text-${variantClass} ${decorationClass}`}>
      {children}
    </div>
  );
}
