import { ReactNode, useEffect, useState } from "react"
import { Button, Dropdown, Form, Modal } from "react-bootstrap"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { newInvoiceByXml } from "../../../../../services/invoices.service"
import { eventGa } from "../../../../../utils/eventClickGa"
import { CustomAlert } from "../../../../../components/Customs/CustomAlert"
import { KindInvoiceForms } from "../InvoicesFormModalNew/components/KindInvoiceForms"
import { StatusInvoiceForms } from "../InvoicesFormModalNew/components/StatusInvoiceForms"
import { LogisticInvoiceForms } from "../InvoicesFormModalNew/components/LogisticInvoiceForms"
import { ComplementaryAttributesInvoiceForms } from "../InvoicesFormModalNew/components/ComplementaryAttributesInvoiceForms"
import { setApiErrorMessages } from "../../../../../utils/setApiErrorMessage"
import { MultiErrorAlerts } from "../../../../../components/MultiErrorAlerts"
import InvoiceAutomaticFilesInput from "./InvoiceAutomaticFilesInput"
import InvoiceAutomaticRepurposalCodeInput from "./InvoiceAutomaticRepurposalCodeInput"
import InvoiceAutomaticOrderInput from "./InvoiceAutomaticOrderInput"
import { ButtonSubmit } from "../../../../../components/Buttons/ButtonSubmit"
import BillInput from "../../../../../components/Form/formComponents/BillInput/BillInput"
import InvoiceDeliveryInput from "../InvoicesFormModalNew/components/Fields/InvoiceDeliveryInput"

interface InvoiceAutomaticRegistrationProps {
  handleSuccess: (show: boolean, message: ReactNode) => void
}

export default function InvoiceAutomaticRegistration(
  props: InvoiceAutomaticRegistrationProps,
) {
  const { t } = useTranslation()
  const { handleSuccess } = props
  const { id: repurposalId } = useParams()
  const [show, setShow] = useState(false)
  const [xml, setXml] = useState<File | undefined>()
  const [pdf, setPdf] = useState<File | undefined>()
  const [validated, setValidated] = useState(false)
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const [invoice, setInvoice] = useState<EditableInvoice | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [bill, setBill] = useState<Partial<SimpleIndexBill>>()

  useEffect(() => {
    if (show) {
      setValidated(false)
      handleSuccess(false, "")
      setIsLoading(false)
      if (repurposalId) {
        setInvoice((prev) => ({ ...prev, repurposalCode: repurposalId }))
      }
    }
  }, [show])

  const handleCloseModal = () => {
    setValidated(false)
    setInvoice(undefined)
    setIsLoading(false)
    setXml(undefined)
    setPdf(undefined)
    setErrorMessages([])
    setShow(false)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() && invoice) {
      try {
        setIsLoading(true)
        const response = await newInvoiceByXml({
          xmlFile: xml,
          pdfFile: pdf,
          ...invoice,
          billId: bill?.id,
        })

        handleSuccess(
          true,
          <Trans
            i18nKey={"Invoice.alerts.create.success"}
            values={{ item: response.invoiceNumber }}
          >
            ...<strong>...</strong>...
          </Trans>,
        )
        setShow(false)
        eventGa({
          category: "Invoice",
          action: "register_invoice_automatic_reading",
          label: "Register NF",
        })
        setInvoice(undefined)
        setXml(undefined)
        setPdf(undefined)
      } catch (err) {
        setApiErrorMessages(err, setErrorMessages)
      } finally {
        setIsLoading(false)
      }
      return null
    } else {
      setValidated(true)
    }
  }

  return (
    <>
      <Dropdown.Item
        onClick={() => {
          setShow(true)
        }}
        data-testid="registerAutomated"
      >
        {t("Invoice.attributes.automatedReadingXML")}
      </Dropdown.Item>
      <Modal
        show={show}
        onHide={handleCloseModal}
        onClose={handleCloseModal}
        size="lg"
        data-testid="automaticRegisterModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("Invoice.attributes.automatedReadingXML")}
          </Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <CustomAlert
              onClose={() => setValidated(false)}
              variant="danger"
              show={validated}
              dismissible={true}
              data-testid="alertRequiredFields"
            >
              {t("Global.alerts.emptyFields")}
            </CustomAlert>
            <CustomAlert
              onClose={() => {
                setValidated(false)
              }}
              variant="danger"
              show={validated && xml === undefined}
              dismissible={true}
              data-testid="empity-xml-alert"
            >
              {t("Invoice.alerts.error.alertXmlAutomatic")}
            </CustomAlert>
            {errorMessages.length > 0 && (
              <MultiErrorAlerts items={errorMessages} />
            )}
            <InvoiceAutomaticFilesInput setXml={setXml} setPdf={setPdf} />
            <KindInvoiceForms
              invoice={invoice as Invoice}
              setInvoice={setInvoice}
            />
            <StatusInvoiceForms
              invoice={invoice as Invoice}
              setInvoice={setInvoice}
            />
            <InvoiceAutomaticRepurposalCodeInput
              invoice={invoice}
              setInvoice={setInvoice}
              repurposalId={repurposalId}
            />
            <BillInput
              repurposalCode={invoice?.repurposalCode}
              onClick={(bill) => {
                setBill(bill)
              }}
              value={bill}
              suggestionInfo={String(
                t("Invoice.alerts.warning.billsSuggestion"),
              )}
            />
            <InvoiceDeliveryInput invoice={invoice} setInvoice={setInvoice} />
            <InvoiceAutomaticOrderInput
              invoice={invoice as Invoice}
              setInvoice={setInvoice}
            />
            <LogisticInvoiceForms
              invoice={invoice as Invoice}
              setInvoice={setInvoice}
              baseFields
            />
            <ComplementaryAttributesInvoiceForms
              invoice={invoice as Invoice}
              setInvoice={setInvoice}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              data-testid="cancel-automatic-reading-button"
              onClick={handleCloseModal}
            >
              {t("Global.actions.cancel")}
            </Button>
            <ButtonSubmit
              loading={isLoading}
              disabled={isLoading}
              variant="success"
              type="submit"
              data-testid="invoiceRegisterButton"
              name={t("Global.actions.register")}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
