import { useEffect, useRef, useState } from "react"
import Quill, { QuillOptions } from "quill"
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export function RichText(
  props: QuillOptions & {
    value: string
    onChange: (value: string | undefined) => void
    id?: string
    required?: boolean
    "data-testid"?: string
  },
) {
  const { t } = useTranslation()
  const { value, onChange, id, required, ...otherProps } = props
  const [validContent, setValidContent] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const divElement = useRef<HTMLDivElement>(null)
  const quillRef = useRef<Quill | null>(null)

  function valuesAreEquals(
    current: string | undefined,
    value: string | undefined,
  ) {
    if (quillRef.current) {
      const currentOps = JSON.stringify(
        quillRef.current.clipboard.convert({ html: current }).ops,
      )
      const valueOps = JSON.stringify(
        quillRef.current.clipboard.convert({ html: value }).ops,
      )
      return currentOps === valueOps
    }
  }

  function handleChange() {
    const content = quillRef.current?.getSemanticHTML()
    if (!valuesAreEquals(value, content)) {
      setValidContent(quillRef.current?.getText().trim() !== "")
      onChange(content)
      setIsInvalid(false)
    }
  }

  function handleInvalid() {
    setIsInvalid(true)
  }

  useEffect(() => {
    if (divElement.current && !quillRef.current) {
      quillRef.current = new Quill(divElement.current, otherProps)
      quillRef.current.update("user")
    }
    return () => {
      if (quillRef.current) {
        const toolbar = document.querySelector(".ql-toolbar")
        toolbar?.parentNode?.removeChild(toolbar)
        quillRef.current.off("text-change")
        quillRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    quillRef.current?.on("text-change", handleChange)
  }, [onChange])

  useEffect(() => {
    if (quillRef.current) {
      const currentContent = quillRef.current.root.innerHTML
      if (!valuesAreEquals(currentContent, value)) {
        quillRef.current.root.innerHTML = value
      }
    }
  }, [value])

  useEffect(() => {
    const labelElement = document.querySelector(`label[for=${id}]`)
    if (labelElement && divElement.current) {
      labelElement.addEventListener("click", () => {
        if (quillRef.current) {
          quillRef.current.focus()
        }
      })
    }
  }, [id])

  useEffect(() => {
    if (quillRef.current) {
      const toolbar = quillRef.current.getModule("toolbar") as Quill
      if (toolbar) {
        const toolbarContainer = toolbar.container as HTMLDivElement
        if (isInvalid) {
          toolbarContainer.classList.add("ql-toolbar-danger")
          divElement.current?.classList.add("border-danger")
        } else {
          toolbarContainer.classList.remove("ql-toolbar-danger")
          divElement.current?.classList.remove("border-danger")
        }
      }
    }
  }, [isInvalid])

  return (
    <>
      <input
        className=" d-none"
        required={required && !validContent}
        onInvalid={handleInvalid}
        data-testid={props["data-testid"] + "-input"}
      />
      <div
        id={id}
        className="border-bottom border-end border-start rounded-bottom-1"
        ref={divElement}
        data-testid={props["data-testid"]}
      />
      {isInvalid && (
        <Form.Control.Feedback type="invalid">
          {t("Global.alerts.requiredFieldInput")}
        </Form.Control.Feedback>
      )}
    </>
  )
}
