import { getCountryCode } from "../../../../../utils/hooks/useCountryConfigs/utils"
import { countryConfigurations } from "../../../../../configs/countryConfig"

const PAYMENT_DATA_LABEL_MAP: { [key: string]: { label: string } } = {
  "Boleto bancário": {
    label: "BillParts.attributes.billetBarcode",
  },
  PIX: {
    label: "BillParts.attributes.QRCode",
  },
}

export const BANK_FIELDS_MAP = [
  "Global.attributes.bank",
  "Global.attributes.agency",
  "Global.attributes.checkingAccount",
  [
    `Global.attributes.document.${getCountryCode()}`,
    "Global.attributes.document.document",
  ],
  "Receipts.attributes.favored",
]

const PAYMENT_TYPES_WITHOUT_PAYMENT_TYPE_DATA = ["Tino"]

interface TypeConfigsReturn {
  isFixed: boolean
  wasMapped: boolean
  label: string
  withoutPaymentTypeData: boolean
}

export function billPaymentTypeDataConfigs(
  paymentType: PaymentType | undefined,
  repurposalKind: RepurposalNegotiationKind | undefined,
  billKind: BillsKind | undefined,
): TypeConfigsReturn {
  if (!paymentType)
    return {
      isFixed: false,
      wasMapped: false,
      label: "BillParts.attributes.bankData",
      withoutPaymentTypeData: false,
    }

  const wasMapped = paymentType.name in PAYMENT_DATA_LABEL_MAP
  const isSupplierToGooxxy = repurposalKind === "supplier_to_gooxxy"
  const isRefund = billKind === "refund"
  const isFixed =
    isSupplierToGooxxy === isRefund &&
    !!paymentType.id &&
    paymentType.id in
      countryConfigurations[getCountryCode()].bills.paymentTypesWithFixedData
  const label = wasMapped
    ? PAYMENT_DATA_LABEL_MAP[paymentType.name].label
    : "BillParts.attributes.bankData"
  const withoutPaymentTypeData =
    PAYMENT_TYPES_WITHOUT_PAYMENT_TYPE_DATA.includes(paymentType.name)

  return {
    isFixed,
    wasMapped,
    label,
    withoutPaymentTypeData,
  }
}
