import React, { useEffect, useState } from "react"
import { Label } from "../../../../../components/Form/formComponents/Label"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"

interface InvoiceAutomaticFilesInputProps {
  setPdf: React.Dispatch<React.SetStateAction<File | undefined>>
  setXml: React.Dispatch<React.SetStateAction<File | undefined>>
}

export default function InvoiceAutomaticFilesInput(
  props: InvoiceAutomaticFilesInputProps,
) {
  const { t } = useTranslation()
  const { setPdf, setXml } = props
  const [invalidPdf, setInvalidPdf] = useState(false)
  const [invalidXml, setInvalidXml] = useState(false)

  const handlePdf = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file || file.type === "application/pdf" || file.type === undefined) {
      setPdf(file)
      setInvalidPdf(false)
      event.currentTarget.setCustomValidity("")
    } else {
      setInvalidPdf(true)
      setPdf(undefined)
      event.currentTarget.setCustomValidity("invalid")
    }
  }

  const handleXml = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file || file.type === "text/xml" || file.type === "application/xml") {
      setInvalidXml(false)
      setXml(file)
      event.currentTarget.setCustomValidity("")
    } else {
      setInvalidXml(true)
      setXml(undefined)
      event.currentTarget.setCustomValidity("invalid")
    }
  }

  useEffect(() => {
    return () => {
      setInvalidPdf(false)
      setInvalidXml(false)
    }
  }, [])

  return (
    <>
      <Form.Group className="mb-3" controlId="xml">
        <Label required>{t("Invoice.attributes.xml")}</Label>
        <Form.Control
          type="file"
          required
          accept=".xml"
          onChange={handleXml}
          data-testid="xmlInput"
        />
        <Form.Control.Feedback
          className="float-start me-1 w-auto"
          type="invalid"
        >
          {t("Global.alerts.requiredField")}
        </Form.Control.Feedback>
        {invalidXml && (
          <small className="text-danger d-block mt-1">
            {t("Global.alerts.xmlFormat")}
          </small>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="pdf">
        <Label className="w-100">
          {t("Invoice.attributes.pdf")}{" "}
          <span className="text-muted">{t("Global.attributes.optional")}</span>
        </Label>
        <Form.Control type="file" accept=".pdf" onChange={handlePdf} />
        {invalidPdf && (
          <small className="text-danger">{t("Global.alerts.pdfFormat")}</small>
        )}
      </Form.Group>
    </>
  )
}
