import { useTranslation } from "react-i18next"
import { AdditionalInfoAccordion } from "../../../../../../../components/Form/formComponents/AdditionalInfoAccordion"
import { Form } from "react-bootstrap"
import { Label } from "../../../../../../../components/Form/formComponents/Label"
import { useEffect } from "react"
import { format } from "date-fns"
import InvoiceTagInput from "../Fields/InvoiceTagInput"

export function ComplementaryAttributesInvoiceForms({
  invoice,
  setInvoice,
  isEditing = false,
}: InvoiceFormPropsWithEditing) {
  const { t } = useTranslation()

  useEffect(() => {
    !isEditing &&
      !invoice?.monthGoalOn &&
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        monthGoalOn: format(new Date(), "yyyy-MM-dd"),
      }))
  }, [isEditing])

  return (
    <AdditionalInfoAccordion
      additionalInfo={invoice?.aditionalInfo}
      setAdditionalInfo={(info) =>
        setInvoice({ ...invoice, aditionalInfo: info.replace(/\n/g, " ") })
      }
      entityName={t("Invoice.invoice.one")}
    >
      <Form.Group className="mb-3" controlId="monthGoal">
        <Label required>{t("Invoice.attributes.monthGoal")}</Label>
        <Form.Control
          type={"date"}
          value={invoice?.monthGoalOn}
          pattern="\d*"
          onChange={(e) => {
            setInvoice({ ...invoice, monthGoalOn: e.target.value })
          }}
          required
        />
      </Form.Group>
      <InvoiceTagInput invoice={invoice} setInvoice={setInvoice} />
    </AdditionalInfoAccordion>
  )
}
