import { Col, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../../../components/Form/formComponents/Label"

export function InvoiceNumberForms(props: DisableInvoiceFormsProps) {
  const { t } = useTranslation()
  const { invoice, setInvoice, disableMessage } = props

  return (
    <Form.Group as={Col} className="mb-3" controlId="invoiceNumber">
      <Label required>{t("Invoice.attributes.invoiceNumber")}</Label>
      <Form.Control
        type="text"
        required
        disabled={invoice?.automaticRegistration === 1}
        title={invoice?.automaticRegistration === 1 ? disableMessage : ""}
        value={invoice?.invoiceNumber}
        onChange={(e) => {
          setInvoice({ ...invoice, invoiceNumber: e.target.value })
        }}
      />
      <Form.Control.Feedback type="invalid">
        {t("Global.alerts.requiredFieldInput")}
      </Form.Control.Feedback>
    </Form.Group>
  )
}
