import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { deleteBill, getBill } from "../../../../../services/bills.service"
import { AxiosError } from "axios"
import { Button, Modal } from "react-bootstrap"
import { MultiErrorAlerts } from "../../../../../components/MultiErrorAlerts"
import { BillViewAttributes } from "../BillsViewAttributes"
import { BillCreateModal } from "../BillCreateModal/BillCreateModal"
import { CustomDeleteModal } from "../../../../../components/Customs/CustomDeleteModal"
import { capitalizeText } from "../../../../../utils/Format"
import { FinancialModal } from "../../../components/FinancialModalView"

export function BillViewModal(props: BillViewModalProps) {
  const {
    show,
    setShow,
    billId,
    setBillId,
    success,
    setSuccess,
    setSuccessMessage,
    showModalInParams,
  } = props
  const { t } = useTranslation()
  const [bill, setBill] = useState<EditableBill>()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const [showEditModal, setShowEditModal] = useState<boolean>()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  async function findBill() {
    setIsLoading(true)
    setErrorMessages([])
    try {
      const { data } = (await getBill(billId)) as { data: Bill }
      setBill({
        ...data,
        ...(data.kind === "refund" && {
          amount: `${Number(data.amount) * -1}`,
        }),
      })
    } catch (error) {
      error instanceof AxiosError &&
        setErrorMessages(error.response?.data.messages)
    } finally {
      setIsLoading(false)
    }
  }

  function handleClose() {
    setShow(false)
    setBillId(undefined)
    setBill(undefined)
    setIsLoading(true)
    setErrorMessages([])
  }

  function handleOpen() {
    setSuccess(false)
    setSuccessMessage(<></>)
  }

  function handleEditClick() {
    setShow(false)
    setShowEditModal(true)
  }

  useEffect(() => {
    show && billId && findBill()
  }, [billId, show])

  useEffect(() => {
    success && setShow(false)
  }, [success])

  useEffect(() => {
    !showDeleteModal && setBill(undefined)
  }, [])

  return (
    <>
      <FinancialModal
        show={show}
        setShow={setShow}
        financialId={billId}
        setFinancialId={setBillId}
        showModalInParams={showModalInParams}
        onShow={handleOpen}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Bills.actions.view")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessages.length > 0 && (
            <MultiErrorAlerts items={errorMessages} />
          )}
          <BillViewAttributes bill={bill as Bill} isLoading={isLoading} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            data-testid="edit-bill-button"
            onClick={handleEditClick}
            className="me-auto"
          >
            {t("Global.actions.edit")}
          </Button>
          <Button
            variant="outline-primary"
            data-testid="cancel-view-bill-button"
            onClick={handleClose}
          >
            {t("Global.actions.close")}
          </Button>
        </Modal.Footer>
      </FinancialModal>
      <BillCreateModal
        bill={bill}
        setBill={setBill}
        show={showEditModal}
        setShow={setShowEditModal}
        setSuccess={setSuccess}
        setSuccessMessage={setSuccessMessage}
        setShowDeleteModal={setShowDeleteModal}
        isEditMode
      />
      <CustomDeleteModal
        title={String(t("Bills.actions.delete"))}
        deleteFunction={async () => await deleteBill(billId)}
        item={String(billId)}
        itemType={capitalizeText(t("Bills.bill.one"))}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        setSuccess={setSuccess}
        successMessagei18nKey="Bills.alerts.remove.success"
        setSuccessMessages={setSuccessMessage}
        data-testid={"delete-bill"}
      />
    </>
  )
}
