import { getOriginCheckinsList } from "../../../../services"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { intlFormatDateTime } from "../../../../utils/timezoneHelpers"
import { FormatMoney } from "../../../../components/Formats/FormatMoney"
import { DropdownInput } from "../../../../components/DropdownInput"

export default function SubcheckinDropdown(props: SubcheckinDropdownProps) {
  const { setOriginCheckin, disabled, supplierId, originCheckin } = props
  const [dropdownItems, setDropdownItems] = useState<StockCheckin[]>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [searchText, setSearchText] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [value, setValue] = useState<StockCheckin | undefined>(
    originCheckin ? originCheckin : undefined,
  )
  const [count, setCount] = useState<number>(0)

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPageNumber(1)
    setSearchText(event.target.value)
    return fetchSearch({ search: event.target.value })
  }

  async function fetchSearch({ search = "" } = {}) {
    let count = 0
    setIsLoading(true)
    try {
      const { data } = await getOriginCheckinsList({
        id: supplierId ? supplierId : "",
        search,
      })
      setDropdownItems(data.checkins)
      count = data.count
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
    setCount(count)
  }

  async function handleScroll() {
    const newPageNumber = pageNumber + 1
    let count = 0
    try {
      const { data } = await getOriginCheckinsList({
        id: supplierId ? supplierId : "",
        search: searchText,
        pageNumber: newPageNumber,
        itemsPerPage: 20,
      })
      count = data.count
      const newList = [...dropdownItems, ...data.checkins]
      setDropdownItems(newList)
      setPageNumber(newPageNumber)
    } catch (error) {
      console.error(error)
    }
    setCount(count)
  }

  function renderName(item: StockCheckin) {
    const formattedDate = intlFormatDateTime(item.createdAt)
    const formattedAmount = FormatMoney({
      amount: Number(item?.totalValue),
      isNotJSX: true,
    })
    return `${formattedDate} - ${formattedAmount} - ${item.supplierName}`
  }

  useEffect(() => {
    fetchSearch()
  }, [supplierId])

  useEffect(() => {
    fetchSearch()
  }, [])

  return (
    <div className="mb-3">
      <DropdownInput
        onChange={handleSearchChange}
        options={dropdownItems}
        isLoading={isLoading}
        onScroll={handleScroll}
        count={count}
        renderName={(item) => (item ? renderName(item) : "")}
        renderTitle={(item) => (item ? renderName(item) : "")}
        onClick={(item) => {
          setOriginCheckin(item ? item : null)
          setValue(item ? item : undefined)
        }}
        value={value}
        disabled={disabled ? disabled : false}
        id="subcheckin"
        select
      />
      <div className="mt-2">
        <span className="text-muted">
          {" "}
          <em>{t("StockCheckin.attributes.subcheckinDropdownSubtitle")} </em>
        </span>
      </div>
    </div>
  )
}
