import { Locale } from "date-fns"
import * as locales from "date-fns/locale"

export const getBrowserLanguage = (): {
  locale: string
  dateFnsLocale: Locale
} => {
  const fallbackLocale = "pt-BR"

  const browserLanguage = navigator.language || fallbackLocale

  return {
    locale: browserLanguage,
    dateFnsLocale:
      locales[browserLanguage.replace("-", "") as keyof typeof locales],
  }
}
