import { t } from "i18next"
import { api } from "./api"
import {
  getCountryCode,
  getLanguage,
} from "../utils/hooks/useCountryConfigs/utils"

type ExportInvoicesFilter = {
  checkinItemsKind?: string
  emmitedAt?: DateFilter
  competency?: DateFilter
  gooxxySupplier?: string
  repurposalBound?: string
  weightPresent?: string
  statusInvoice?: string
  supplierId?: Array<number>
  buyerId?: Array<number>
  countryCode?: string
} & InvoicesSearchOptions

type ExportInvoicesProps = {
  filters: ExportInvoicesFilter
  columns: string[]
}

export const exportInvoices = async (props: ExportInvoicesProps) => {
  const { filters, columns } = props
  const { emmitedAt, competency, ...otherFilters } = filters
  return await api({
    url: "/billing/invoices/export.xlsx",
    method: "POST",
    data: {
      columns,
      filters: {
        ...(emmitedAt
          ? {
              emmitedAtStartDate: emmitedAt.initial,
              emmitedAtFinalDate: emmitedAt.final,
            }
          : {}),
        ...(competency
          ? {
              competencyStartDate: competency.initial,
              competencyFinalDate: competency.final,
            }
          : {}),
        ...otherFilters,
      },
    },
    responseType: "blob",
  })
}

type UpdateInvoiceProps = {
  xmlFile?: File
  pdfFile?: File
  invoice: EditableInvoice
}

export const updateInvoice = async (props: UpdateInvoiceProps) => {
  const { invoice, ...otherProps } = props
  const {
    xml: _xml,
    pdf: _pdf,
    supplier: _supplier,
    buyer: _buyer,
    repurposalCore: _repurposalCore,
    auditCreate: _auditCreate,
    auditLastUpdate: _auditLastUpdate,
    originRegionName: _originRegionName,
    destinationRegionName: _destinationRegionName,
    id,
    netWeight,
    grossWeight,
    invoiceNumber,
    invoiceOrder,
    tags,
    ...otherFields
  } = invoice

  const language = getLanguage(getCountryCode())

  const additionalInfo = invoice.aditionalInfo ? `${invoice.aditionalInfo}` : ""
  const returnReason = invoice.returnReason
    ? `${t("Invoice.attributes.returnReason", { lng: language })}: ${invoice.returnReason}\n`
    : ""

  const cancellationReason = invoice.cancellationReason
    ? `${t("Invoice.attributes.cancellationReason", { lng: language })}: ${invoice.cancellationReason}\n`
    : ""

  const { data } = await api(`/billing/invoices/${id ?? ""}`, {
    method: id ? "PUT" : "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      ...otherFields,
      ...otherProps,
      netWeight: String(netWeight).replace(",", "."),
      grossWeight: String(grossWeight).replace(",", "."),
      numInvoice: invoiceNumber,
      numOrder: invoiceOrder,
      aditionalInfo:
        `${returnReason}${cancellationReason}${additionalInfo}` || "",
      tagIds: tags?.map((tag) => tag.id),
    },
  })
  return data
}

type RemoveInvoiceProps = {
  invoiceId?: number
}

export const removeInvoice = async (props: RemoveInvoiceProps) => {
  const { invoiceId } = props

  try {
    return await api.delete(`/billing/invoices/${invoiceId}`)
  } catch {
    return new Response(null, { status: 500 })
  }
}

type INewInvoiceRequestData = {
  xmlFile: File | undefined
  pdfFile: File | undefined
  invoiceOrder?: string
  aditionalInfo?: string
  monthGoalOn?: string
  repurposalCode?: string
  checkList?: string
  tmsNumber?: string
  status?: string
  kind?: invoicesKind
  returnReason?: string
  cancellationReason?: string
  tags?: Tag[]
  billId?: number
}

export async function newInvoiceByXml(props: INewInvoiceRequestData) {
  const {
    invoiceOrder,
    aditionalInfo,
    returnReason,
    cancellationReason,
    tags,
    ...otherProps
  } = props

  const language = getLanguage(getCountryCode())

  const additionalInfo = aditionalInfo ? `${aditionalInfo}` : ""
  const returnReasonText = returnReason
    ? `${t("Invoice.attributes.returnReason", { lng: language })}: ${returnReason}\n`
    : ""

  const cancellationReasonText = cancellationReason
    ? `${t("Invoice.attributes.cancellationReason", { lng: language })}: ${cancellationReason}\n`
    : ""

  const response = await api(`/billing/invoices/automatic-xml`, {
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      ...otherProps,
      numOrder: invoiceOrder,
      aditionalInfo: `${returnReasonText}${cancellationReasonText}${additionalInfo}`,
      tagIds: tags?.map((tag) => tag.id),
    },
  })
  return response.data
}

type InvoiceListProps = {
  gooxxySupplier?: string
  supplierId?: string[]
  buyerId?: string[]
  search?: string
  hasDelivery?: boolean
  repurposalCode?: string
  pageNumber: string
  itemsPerPage: string
  competency?: DateFilter
  checkinItemsKind?: string
  urlFilter?: string
  statusInvoice?: string
  repurposalBound?: string
  weightPresent?: string
  emmitedAt?: DateFilter
  gogreenGroupsIds?: number[]
  signal?: AbortSignal
  context?: "simple_index"
  orderBy?: string
}

export const invoiceList = async (props: InvoiceListProps) => {
  const { signal, emmitedAt, competency, gogreenGroupsIds, ...otherProps } =
    props

  const response = await api.get("/billing/invoices", {
    signal,
    params: {
      ...(emmitedAt
        ? {
            emmitedAtStartDate: emmitedAt.initial,
            emmitedAtFinalDate: emmitedAt.final,
          }
        : {}),
      ...(competency
        ? {
            competencyStartDate: competency.initial,
            competencyFinalDate: competency.final,
          }
        : {}),
      customersGroupIds: gogreenGroupsIds,
      ...otherProps,
    },
  })

  return response.data
}

type GetRepurposalByIdProps = { repurposalId?: string }

export const getRepurposalById = async (props: GetRepurposalByIdProps) => {
  const { repurposalId } = props
  const { data } = await api.get(`/repurposals/${repurposalId}`)
  return data
}

export async function getInvoice(id: number) {
  const { data } = await api(`/billing/invoices/${id}`)
  return data
}
