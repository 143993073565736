import InvoiceAutomaticFilesInput from "./InvoiceAutomaticFilesInput"
import InvoiceAutomaticOrderInput from "./InvoiceAutomaticOrderInput"
import InvoiceAutomaticRegistration from "./InvoiceAutomaticRegistration"
import InvoiceAutomaticRepurposalCodeInput from "./InvoiceAutomaticRepurposalCodeInput"

export default Object.assign(InvoiceAutomaticRegistration, {
  RepurposalCodeInput: InvoiceAutomaticRepurposalCodeInput,
  OrderInput: InvoiceAutomaticOrderInput,
  FilesInput: InvoiceAutomaticFilesInput,
})
