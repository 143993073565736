import { FormatNumber } from "../FormatNumber"

export function FormatWeight(props: FormatNumberProps) {
  const { quantity, options: customOptions } = props
  const options: Intl.NumberFormatOptions = {
    ...customOptions,
    maximumFractionDigits: 3,
  }
  return (
    <>
      <FormatNumber quantity={quantity} options={options} /> kg
    </>
  )
}
