import { Form } from "react-bootstrap"
import { useId } from "react"
import { useInfiniteQuery } from "@tanstack/react-query"
import { DropdownInput } from "../../../DropdownInput"
import { getRepurposalCores } from "../../../../services/repurposal.service"
import { RepurposalInputOptions } from "./components/RepurposalInputOption"
import { useTranslation } from "react-i18next"
import { Label } from "../Label"
import { useDebounceValue } from "usehooks-ts"
import { useCountryConfigs } from "../../../../utils/hooks/useCountryConfigs"

function getNextPageParam<T extends BaseIndexRepurposal>(
  lastPage: SimpleIndexRepurposalQuery<T>,
) {
  return lastPage.page + 1
}

export function RepurposalInput<
  T extends BaseIndexRepurposal = BaseIndexRepurposal,
>(props: RepurposalInputProps<T>) {
  const {
    label,
    labelComplement,
    value,
    onClick,
    id,
    required,
    disabled,
    additionalContent,
    negotiationKind,
    context = "core_simple_index",
    customDisplay,
  } = props
  const { t } = useTranslation()
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()
  const controlId = useId()
  const [search, setSearch] = useDebounceValue("", 500)

  async function findRepurposalCores({
    pageParam,
  }: {
    pageParam: number
  }): Promise<SimpleIndexRepurposalQuery<T>> {
    const codeAndDocuments = search.replace(/[^0-9a-zA-Z]/g, "")
    const { data } = await getRepurposalCores({
      codeAndDocuments,
      context,
      pageNumber: pageParam,
      negotiationKind,
    })
    return {
      repurposalCores: data.repurposalCores,
      count: data.count,
      page: pageParam,
    }
  }

  const { data, isLoading, fetchNextPage } = useInfiniteQuery({
    queryKey: [
      "repurposal-core",
      "simple_index",
      search,
      negotiationKind,
      context,
      countryCode,
    ],
    queryFn: findRepurposalCores,
    initialPageParam: 1,
    getNextPageParam,
    staleTime: 1 * 60 * 1000,
  })

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value)
  }

  async function handleScroll() {
    fetchNextPage()
  }

  return (
    <Form.Group className="mb-3" controlId={controlId}>
      <Label required={required} complement={labelComplement}>
        {label}
      </Label>
      <DropdownInput<T>
        renderOption={(repurposal) => RepurposalInputOptions(repurposal, t)}
        options={data?.pages.flatMap((page) => page.repurposalCores) ?? []}
        renderName={(repurposal) => repurposal?.code ?? ""}
        count={data?.pages.slice(-1)[0].count}
        customDisplay={customDisplay}
        onChange={handleSearch}
        onScroll={handleScroll}
        isLoading={isLoading}
        disabled={disabled}
        required={required}
        onClick={onClick}
        value={value as T}
        id={id}
        select
      />
      {additionalContent}
    </Form.Group>
  )
}
