import { useTranslation } from "react-i18next"
import { CustomLink } from "../../Customs/CustomLink"
import { ActionButton } from "../ActionButton"
import { BsEyeFill } from "react-icons/bs"
import { getUrlWithId } from "../../../utils/getUrlWithId/getUrlWithId"

export function VisualizeButton(props: VisualizeButtonProps) {
  const { t } = useTranslation()
  const {
    setShow,
    setId,
    id,
    showModalInParams,
    "data-testid": testid,
    idOption,
  } = props

  return (
    <>
      {showModalInParams ? (
        <CustomLink
          as={ActionButton}
          href={getUrlWithId(id, idOption)}
          title={String(t("Global.actions.visualize"))}
          data-testid={testid}
        >
          <BsEyeFill />
        </CustomLink>
      ) : (
        <ActionButton
          onClick={() => {
            setId(id)
            setShow?.(true)
          }}
          title={String(t("Global.actions.visualize"))}
          data-testid={testid}
        >
          <BsEyeFill />
        </ActionButton>
      )}
    </>
  )
}
