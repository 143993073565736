import { useState } from "react"
import { useTranslation } from "react-i18next"
import { BillInvoicesTable } from "./BillInvoicesTable"
import { BillReceiptsTable } from "./BillReceiptsTable"
import { Nav } from "react-bootstrap"

export function BillAccordionContent(bill: Bill, collapsed: boolean) {
  const { t } = useTranslation()
  const [tab, setTab] = useState<BillAccordionOptions>("invoices")

  const tabs: Record<
    BillAccordionOptions,
    {
      content: React.ReactNode
      label: string
    }
  > = {
    invoices: {
      content: <BillInvoicesTable bill={bill} collapsed={collapsed} />,
      label: t("Deliveries.actions.bindInvoice"),
    },
    receipts: {
      content: <BillReceiptsTable bill={bill} collapsed={collapsed} />,
      label: t("Bills.actions.linkReceipt"),
    },
  }

  const { content } = tabs[tab]

  return (
    <>
      <Nav className="border-bottom mb-4">
        {Object.entries(tabs).map(([key, { label }]) => (
          <Nav.Item key={key}>
            <Nav.Link
              active={tab === key}
              onClick={() => setTab(key as BillAccordionOptions)}
              className={`text-${tab === key ? "secondary" : "primary"}`}
            >
              {label}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      {content}
    </>
  )
}
