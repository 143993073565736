import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../../components/Form/formComponents/Label"

const PAYMENT_TYPES_WITH_DATA = ["Boleto bancário"]

export function BillFileInput(props: DefaultBillField) {
  const { bill, setBill } = props
  const { t } = useTranslation()
  const [required, setRequired] = useState(true)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setBill((bill) => ({ ...bill, file: event.target.files?.[0] }))
  }

  useEffect(() => {
    bill?.fileUrl && setRequired(false)
  }, [])

  return (
    <>
      {bill?.paymentType &&
        PAYMENT_TYPES_WITH_DATA.includes(bill.paymentType.name) && (
          <Form.Group className="mb-3" controlId="bill-part-file-input">
            <Label required>{t("BillParts.attributes.billetAttachment")}</Label>
            <Form.Control
              type="file"
              accept=".pdf,.png,.jpg,jpeg"
              onChange={handleChange}
              required={required}
            />
            <Form.Control.Feedback type="invalid">
              {t("Global.alerts.requiredFieldInput")}
            </Form.Control.Feedback>
          </Form.Group>
        )}
    </>
  )
}
