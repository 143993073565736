import { Form, InputGroup } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { PriceInput } from "../../../../../../components/PriceInput"
import { Label } from "../../../../../../components/Form/formComponents/Label"
import { useCountryConfigs } from "../../../../../../utils/hooks/useCountryConfigs"

export function BillAmountInput(props: DefaultBillField) {
  const { bill, setBill } = props
  const { t } = useTranslation()
  const { currency } = useCountryConfigs()

  return (
    <Form.Group className="mb-3" controlId="bill-amount-input">
      <Label required>{t("Global.attributes.value")}</Label>
      <InputGroup>
        <InputGroup.Text id="basic-addon1">{currency.code}</InputGroup.Text>
        <InputGroup.Text id="basic-addon1" className="rounded-0">
          {currency.code}
        </InputGroup.Text>
        <PriceInput
          value={Number(bill?.amount)}
          onChange={(value) =>
            setBill((bill) => ({ ...bill, amount: String(value ?? "") }))
          }
          required
        />
        <Form.Control.Feedback type="invalid">
          {t("Global.alerts.requiredFieldInput")}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  )
}
