import { useEffect, useState } from "react"
import { FilterSelect } from "../FilterTypes/FilterSelect"
import { FilterMultiSelect } from "../FilterTypes/FilterMultiSelect"
import DatePickerFilter from "../../../DatePickerFilter"
import { FilterCustomersSelect } from "../FilterTypes/FilterCustomersSelect"
import { FilterUsersSelect } from "../FilterTypes/FilterUsersSelect"
import { FilterCustomersGroupsSelect } from "../FilterTypes/FilterCustomersGroupsSelect"
import { FilterSupplierResponsibleSelect } from "../FilterTypes/FilterSupplierResponsible"
import { useFilters } from "../../../../../utils/hooks/useFilters"
import { FilterRegionsSelect } from "../FilterTypes/FilterRegionsSelect"
import { FilterStocksGroupingsSelect } from "../FilterTypes/FilterStockGroupingSelect"
import { FilterTagsSelect } from "../FilterTypes/FilterTags"

export function FiltersFactor<T extends Record<string, string | number>>(
  props: FiltersFactorProps<T>,
) {
  const { config, filter, setFilter, reset, setReset } = props
  const { type } = config
  const { show } = useFilters()
  const [hasOpened, setHasOpened] = useState(false)
  const range = type === "date" ? config.range : undefined

  useEffect(() => {
    show && !hasOpened && setHasOpened(true)
  }, [show, hasOpened])

  if (!hasOpened) return null

  switch (type) {
    case "select":
      return (
        <FilterSelect<T>
          labelName={config.label}
          filter={filter as Record<string, string>}
          setFilter={
            setFilter as React.Dispatch<
              React.SetStateAction<Record<string, string | number>>
            >
          }
          disabled={config.disabled}
          {...config}
        />
      )
    case "multiSelect":
      return (
        <FilterMultiSelect<T>
          labelName={config.label}
          filter={filter as Record<string, string[]>}
          setFilter={
            setFilter as React.Dispatch<
              React.SetStateAction<Record<string, string[]>>
            >
          }
          disabled={config.disabled}
          {...config}
        />
      )
    case "date":
      return (
        <DatePickerFilter
          {...config}
          type={range ? "range" : "single-date"}
          filter={filter as Record<string, DateFilter>}
          setFilter={
            setFilter as React.Dispatch<
              React.SetStateAction<Record<string, DateFilter>>
            >
          }
          filterName={config.filterName}
          reset={reset}
          setReset={setReset}
          prevState={config.prevState}
        />
      )
    case "customersSelect":
      return (
        <FilterCustomersSelect
          filter={filter as Record<string, string[]>}
          setFilter={
            setFilter as React.Dispatch<
              React.SetStateAction<Record<string, string[]>>
            >
          }
          {...config}
        />
      )

    case "stocksGroupingsSelect":
      return (
        <FilterStocksGroupingsSelect
          filter={filter as Record<string, string[]>}
          setFilter={
            setFilter as React.Dispatch<
              React.SetStateAction<Record<string, string[]>>
            >
          }
          suppliersDocuments={config.suppliersDocuments}
          {...config}
        />
      )
    case "usersSelect":
      return (
        <FilterUsersSelect
          disabled={config.disabled}
          filter={filter as Record<string, string[]>}
          setFilter={
            setFilter as React.Dispatch<
              React.SetStateAction<Record<string, string[]>>
            >
          }
          {...config}
        />
      )
    case "customersGroupsSelect":
      return (
        <FilterCustomersGroupsSelect
          filter={filter as Record<string, string[]>}
          setFilter={
            setFilter as React.Dispatch<
              React.SetStateAction<Record<string, string[]>>
            >
          }
          {...config}
        />
      )
    case "supplierResponsibleSelect":
      return (
        <FilterSupplierResponsibleSelect
          filter={filter as Record<string, string[]>}
          setFilter={
            setFilter as React.Dispatch<
              React.SetStateAction<Record<string, string[]>>
            >
          }
          disabled={config.disabled}
          {...config}
        />
      )
    case "regionsSelect":
      return (
        <FilterRegionsSelect
          filter={filter as Record<string, string[]>}
          setFilter={
            setFilter as React.Dispatch<
              React.SetStateAction<Record<string, string[]>>
            >
          }
          disabled={config.disabled}
          {...config}
        />
      )
    case "tagsSelect":
      return (
        <FilterTagsSelect
          filter={filter as Record<string, string[]>}
          setFilter={
            setFilter as React.Dispatch<
              React.SetStateAction<Record<string, string[]>>
            >
          }
          disabled={config.disabled}
          {...config}
        />
      )
    default:
      return null
  }
}
