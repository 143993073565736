import { billingByRepurposal } from "../../../../services/repurposal.service"

function round(value: number) {
  return Math.round(value * 100) / 100
}

export function invoiceReduce(sum: number, item: Invoice) {
  return round(
    item.status === "confirmed" ? sum + Number(item.valueCents) : sum,
  )
}

export function paymentReduce(sum: number, item: Payment) {
  return round(sum + Number(item.value))
}

export function billReduce(sum: number, item: Bill) {
  return round(item.status !== "canceled" ? sum + Number(item.amount) : sum)
}

export function confirmedBillReduce(sum: number, item: Bill) {
  return round(item.status === "confirmed" ? sum + Number(item.amount) : sum)
}

export async function getBilling<T>(
  kind: "invoices" | "payments" | "bills",
  repurposalId: string | undefined,
  reduceFunction: (sum: number, item: T) => number,
  confirmedReduce?: (sum: number, item: T) => number,
): Promise<BillingQueryResponse<T> | undefined> {
  if (!repurposalId) throw Error("Id can't be undefined")
  const { data } = await billingByRepurposal(repurposalId, kind)
  return {
    items: data,
    count: data.length,
    totalValue: data.reduce(reduceFunction, 0),
    confirmedValue: confirmedReduce
      ? data.reduce(confirmedReduce, 0)
      : undefined,
  }
}
