import { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { t } from "i18next"
import { getChangesHistory } from "@services"
import { CustomTable } from "../../../../components/Customs/CustomTable"
import { intlFormatDateTime } from "../../../../utils/timezoneHelpers"
import camelCase from "lodash/camelCase"

const columns: Array<Column<TimelineData>> = [
  {
    name: `${t("Global.attributes.dateAndHour")}`,
    field: (item) => intlFormatDateTime(item.dateTime),
  },
  {
    name: `${t("Global.attributes.history")}`,
    field: (item) => item.history,
  },
]

export function TimelineTable({
  id,
  status,
  repurposalVersion,
}: {
  id: number
  status: string | undefined
  repurposalVersion: string | undefined
}) {
  const queryClient = useQueryClient()

  const queryKey = ["history", "repurposals", id, repurposalVersion]

  const { data: historyData, isFetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await getChangesHistory(id)
      return response.data.changesHistory.map((item: Change) => {
        const userName = item.userName ?? t("Global.attributes.externalBuyer")

        let historyMessage = t("Repurposal.attributes.timeLine.default", {
          action: item.action,
          subject: item.subject,
          user: userName,
        })

        if (item.action === "create") {
          if (
            item.changes?.includes("created") &&
            item.subject === "repurposal_core"
          ) {
            historyMessage = t("Repurposal.attributes.timeLine.created", {
              user: userName,
            })
          }

          if (item.subject === "repurposal_version") {
            const statusLabel = t(
              `Repurposal.attributes.status.${camelCase(item.changes[0])}.full`,
            )
            historyMessage = t(
              "Repurposal.attributes.timeLine.versionCreated",
              {
                status: statusLabel,
                user: userName,
              },
            )
          }

          if (item.subject === "buyer_approval_link") {
            historyMessage = t(
              "Repurposal.attributes.timeLine.approvalLinkSent",
              {
                user: userName,
              },
            )
          }
        }

        if (item.action === "send" && item.subject === "mail") {
          const statusEmailLabel = t(
            `Repurposal.attributes.statusEmail.${camelCase(item.changes[0])}.full`,
          )
          historyMessage = t("Repurposal.attributes.timeLine.emailSent", {
            status: statusEmailLabel,
          })
        }

        if (
          item.action === "update" &&
          item.changes.includes("buyer_approved_at")
        ) {
          historyMessage = t(
            "Repurposal.attributes.timeLine.buyerApprovedLinkReassignment",
            {
              user: userName,
            },
          )
        }

        if (item.action === "update" && item.changes.length === 2) {
          const [oldStatus, newStatus] = item.changes
          const oldStatusLabel = t(
            `Repurposal.attributes.status.${camelCase(oldStatus)}.full`,
          )
          const newStatusLabel = t(
            `Repurposal.attributes.status.${camelCase(newStatus)}.full`,
          )
          historyMessage = t("Repurposal.attributes.timeLine.statusUpdated", {
            oldStatus: oldStatusLabel,
            newStatus: newStatusLabel,
            user: userName,
          })
        }

        return {
          dateTime: item.createdAt,
          history: historyMessage,
        }
      }) as TimelineData[]
    },
    enabled: !!id,
    staleTime: Infinity,
  })

  const [previousStatus, setPreviousStatus] = useState(status)
  const [previousVersion, setPreviousVersion] = useState(repurposalVersion)

  useEffect(() => {
    if (
      (status && status !== previousStatus) ||
      (repurposalVersion && repurposalVersion !== previousVersion)
    ) {
      queryClient.invalidateQueries({ queryKey })
      setPreviousStatus(status)
      setPreviousVersion(repurposalVersion)
    }
  }, [status, repurposalVersion, queryClient, previousStatus, previousVersion])

  return (
    <CustomTable<TimelineData>
      items={historyData || []}
      columns={columns}
      isLoading={isFetching}
      totalItems={historyData?.length || 0}
      itemKey={(item) => item.dateTime}
      tbodyWhite={true}
      isPaginate={false}
      variant="light"
    />
  )
}
