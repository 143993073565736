import { TFunction } from "i18next"
import { camelCase } from "lodash"

export function generateBillFullStatus(
  t: TFunction,
  bill: Bill,
): {
  status: BillStatus
  fullTranslation: string
} {
  const { status } = bill
  return {
    status,
    fullTranslation: t(`Bills.attributes.status.${camelCase(status)}.full`),
  }
}
