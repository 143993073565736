import ReactGA from "react-ga4"

type Props = {
  category: string
  action: string
  label?: string
  value?: number
  nonInteraction?: boolean
  transport?: "beacon" | "xhr" | "image"
}
export const eventGa = ({
  action,
  category,
  label,
  nonInteraction,
  transport,
  value,
}: Props) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
    transport,
  })
}
